import React, {FC} from "react";
import {Button, Modal, ProgressBar} from "react-bootstrap";
import LoaderInside from "../messages/LoaderInside";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_LOADING } from "../../lang";

interface Props {
    messageType: string
    onConfirm: () => void
    loaderPercentage: number
}
const SubscriptionMessages: FC<Props> = ({ messageType, onConfirm, loaderPercentage}) => {

    return (
        <>
            <Modal.Body>
                {messageType === "waiting" && (
                    <div style={{"height":"300px", "width": "100%", "padding": "2rem 1rem"}}>
                        <h4><FormattedMessage {...LANG_COMMON_LOADING}/></h4>
                        <p><FormattedMessage 
                                id="subscription.processing_msg"
                                defaultMessage="Sua assinatura está sendo processada. Este processo pode demorar alguns minutos."
                            /></p>
                        <div className="my-3">
                            <ProgressBar animated now={loaderPercentage} />
                        </div>
                    </div>
                )}
                {messageType === "done" && (
                    <div style={{"height":"300px", "width": "100%", "padding": "2rem 1rem"}}>
                        <h4><FormattedMessage 
                                id="subscription.thank_you"
                                defaultMessage="Obrigado"
                            />!</h4>
                        <p><FormattedMessage 
                                id="subscription.success_msg"
                                defaultMessage="Sua assinatura foi processada com sucesso."
                            /></p>
                    </div>
                )}
                {messageType === "pending" && (
                    <div style={{"height":"300px", "width": "100%", "padding": "2rem 1rem"}}>
                        <h4>OPS!</h4>
                        <p><FormattedMessage 
                                id="subscription.pending_msg"
                                defaultMessage="Esse processo está demorando mais do que o usual, por favor, verifique seu e-mail."
                            /></p>
                    </div>
                )}
                {messageType === "error" && (
                    <div style={{"height":"300px", "width": "100%", "padding": "2rem 1rem"}} className="text-danger">
                        <h4>OPS!</h4>
                        <p><FormattedMessage 
                                id="subscription.error_msg"
                                defaultMessage="Tivemos um problema ao processar sua assinatura, por favor entre em contato com o suporte."
                            /></p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm} disabled={messageType === "waiting"} className="btn-custom bg-custom-gradient">
                    OK
                </Button>
            </Modal.Footer>
        </>
    );
}

export default SubscriptionMessages;
import React, { FC } from 'react'
import { AuthContext } from '../providers/AuthContextProvider';
import { FormattedMessage, MessageDescriptor, defineMessage } from 'react-intl';
import { LANG_COMMON_DEFAULT } from '../lang';

const donorMale = defineMessage({
    defaultMessage: "DOADOR",
    description: "DOADOR - Masculino usado em várias partes",
    id: "common.term.donor-male"
});
const donorFemale = defineMessage({
    defaultMessage: "DOADORA",
    description: "DOADORA - Feminino usado em várias partes",
    id: "common.term.donor-female"
});
const donorsMale = defineMessage({
    defaultMessage: "DOADORES",
    description: "DOADORES - Masculino usado em várias partes",
    id: "common.term.donors-male"
});
const donorsFemale = defineMessage({
    defaultMessage: "DOADORAS",
    description: "DOADORAS - Feminino usado em várias partes",
    id: "common.term.donors-female"
});

const receptorMale = defineMessage({
    defaultMessage: "RECEPTOR",
    description: "RECEPTOR - Masculino usado em várias partes",
    id: "common.term.receptor-male"
});
const receptorFemale = defineMessage({
    defaultMessage: "RECEPTORA",
    description: "RECEPTORA - Feminino usado em várias partes",
    id: "common.term.receptor-female"
});
const receptorsMale = defineMessage({
    defaultMessage: "RECEPTORES",
    description: "RECEPTORES - Masculino usado em várias partes",
    id: "common.term.receptors-male"
});
const receptorsFemale = defineMessage({
    defaultMessage: "RECEPTORAS",
    description: "RECEPTORAS - Feminino usado em várias partes",
    id: "common.term.receptors-female"
});

export const translateBusinessTypeTerms = (term: string, businessType: string | undefined): MessageDescriptor => {
    switch (term) {
        case "newDonor":
        case "donor":
            return (businessType === "O") ? donorFemale : donorMale;
        case "newReceptor":
        case "receptor":
            return (businessType === "O") ? receptorFemale : receptorMale;
        case "receptors":
            return (businessType === "O") ? receptorsFemale : receptorsMale;
        case "donors":
            return (businessType === "O") ? donorsFemale : donorsMale;
    }

    return LANG_COMMON_DEFAULT;
}

interface Props {
    term: string
}
const TranslateTerms: FC<Props> = (
    {
        term
    }) => {
    

    return (
        <AuthContext.Consumer>
            {({ user }) => (
                <>
                    <FormattedMessage {...translateBusinessTypeTerms(term, user?.business?.type)} />
                </>
            )}
        </AuthContext.Consumer>
    )
}

export default TranslateTerms;
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import BgDashboard from "./images/bg-dashboard.png";
import "./styles/dashboard.css";
import { useAPI } from "../../hooks/useAPI";
import PairingsChart from "./components/PairingsChart";
import Loader from "../../components/messages/Loader";
import BusinessSelect from "../../components/form/BusinessSelect";
import { useAuth } from "../../hooks/useAuth";
import TermsModal from "../../components/TermsModal";
import { AuthContext } from "../../providers/AuthContextProvider";
import TranslateTerms from "../../components/TranslateTerms";
import Map from "../../components/Map";
import { PersonModel } from "../../models/PersonModel";
import { PersonType } from "../../types/PersonType";
import { PairingType } from "../../types/PairingType";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CLINIC, LANG_COMMON_DASHBOARD, LANG_COMMON_FROM_DATE, LANG_COMMON_OPTIONS, LANG_COMMON_PAIRINGS, LANG_COMMON_SEARCH, LANG_COMMON_TO_DATE } from "../../lang";

const DashboardPage = () => {
    const { api } = useAPI();
    const [showTerms, setShowTerms] = useState(false);
    const [stats, setStats] = useState<undefined | any>(undefined);
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [loadingPersons, setLoadingPersons] = useState(true);
    const [searchFromBusiness, setSearchFromBusiness] = useState<number | undefined>(undefined);
    const { hasAbility } = useAuth();
    const [persons, setPersons] = useState<Array<any>>([]);
    const [coordinates, setCoordinates] = useState<Array<any>>([]);

    const loadStats = (options: any) => {
        setLoading(true);
        api().get(`/api/stats?${new URLSearchParams({ ...options, cache: (new Date()).getTime(), dateFrom: fromDate, dateTo: toDate }).toString()}`)
            .then((resp) => {
                setStats(resp.data);
                setLoading(false);
            });
    }

    const loadPersons = () => {
        setLoadingPersons(true);
        PersonModel().list("", undefined, { "with_pairings": true, "decrypt": 'N' }, 1, 9999).then((personList) => {
            if (typeof personList !== "boolean") {
                setPersons(personList.data);
                let tempCoordinates: any = [];
                personList.data.filter((person: PersonType) => person.type === "D" && person.lat !== null).map((person: PersonType) => {
                    tempCoordinates.push({ "lat": person.lat, "lng": person.lng, "color": person.color, title: `${person.medical_record_number} - ${person.name}`, id: person.id, isPerson: true });
                    person?.pairings?.map((pairing: PairingType) => {
                        tempCoordinates.push({ "lat": pairing?.receiver_person?.lat, "lng": pairing?.receiver_person?.lng, "color": person.color, id: pairing.id, isPerson: false, "title": `${pairing?.id} - ${person.name} / ${pairing?.receiver_person?.name}` });
                    });
                });
                setCoordinates(tempCoordinates);
                setLoadingPersons(false);
            }
        });
    }

    useEffect(() => {
        loadStats({});
        loadPersons();
    }, []);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {!loading && stats !== undefined && (
                <div className="bg-pattern" style={{ 'paddingBottom': '2rem' }}>
                    <Container>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>
                                    <FormattedMessage {...LANG_COMMON_DASHBOARD} />
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{ offset: 0, span: 8 }}>
                                <div className="bg-dashboard">
                                    <img src={BgDashboard} alt="" />
                                </div>
                                <div className="mt-5 chart">
                                    {stats !== undefined && (<PairingsChart stats={stats} />)}
                                </div>
                                {!loadingPersons && (
                                    <div className="mt-5">
                                        <Map coordinates={coordinates} />
                                    </div>
                                )}
                            </Col>
                            <Col lg={{ span: 4 }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>
                                            <FormattedMessage
                                                {...LANG_COMMON_OPTIONS}
                                            />
                                        </Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            loadStats({});
                                        }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            <FormattedMessage
                                                                {...LANG_COMMON_FROM_DATE}
                                                            />
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            onChange={(e) => setFromDate(e.target.value)}
                                                            value={fromDate}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            <FormattedMessage
                                                                {...LANG_COMMON_TO_DATE}
                                                            />
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            disabled={fromDate === ""}
                                                            min={fromDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                            value={toDate}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    {hasAbility('BUSINESS_LIST') && (
                                                        <>
                                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                                <FormattedMessage {...LANG_COMMON_CLINIC} />
                                                            </label>
                                                            <BusinessSelect
                                                                businessId={searchFromBusiness}
                                                                onChange={(business_id) => {
                                                                    setSearchFromBusiness(business_id);
                                                                    loadStats({ business_id: (business_id === undefined) ? "" : business_id });
                                                                }}
                                                            />

                                                            <br />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2">
                                                <div className="row">
                                                    <div className="col-md-8 offset-2">
                                                        <Button variant="primary" size={'lg'}
                                                            type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">
                                                            <FormattedMessage {...LANG_COMMON_SEARCH} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <div className="my-4">
                                    <Card>
                                        <CardHeader>
                                            <Card.Title>
                                                <FormattedMessage
                                                    id="dashboard.header.data"
                                                    defaultMessage="DADOS"
                                                    description="Dashboard - Cabeçalho do quadro de informações com números de doadores, etc..."
                                                />
                                            </Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row dashboard-stats">
                                                <div className="col-md-6 stats-donors">
                                                    <AuthContext.Consumer>
                                                        {({ user }) => (
                                                            <h4><TranslateTerms term="donors" /></h4>
                                                        )}
                                                    </AuthContext.Consumer>
                                                    <h6>{stats.donors_count}</h6>
                                                </div>
                                                <div className="col-md-6 stats-receivers">
                                                    <h4><TranslateTerms term="receptors" /></h4>
                                                    <h6>{stats.receivers_count}</h6>
                                                </div>
                                                <div className="col-md-6 stats-pairings">
                                                    <h4>
                                                        <FormattedMessage {...LANG_COMMON_PAIRINGS} />
                                                    </h4>
                                                    <h6>{stats.pairings_count}</h6>
                                                </div>
                                                <div className="col-md-6 stats-born-alive">
                                                    <h4>
                                                        <FormattedMessage
                                                            id="dashboard.data.born_alive"
                                                            defaultMessage="Nascidos vivos"
                                                        />
                                                    </h4>
                                                    <h6>{stats.borns_alive_count}</h6>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="my-4">
                                    <Card>
                                        <Card.Body>
                                            <h4 className="body-title">
                                                <FormattedMessage
                                                    id="dashboard.header.about"
                                                    defaultMessage="SOBRE A ISIS"
                                                />
                                            </h4>
                                            <p>
                                                <small style={{ "lineHeight": "1" }}>
                                                    <FormattedMessage
                                                        id="dashboard.about.about_isis"
                                                        defaultMessage="A ISIS, utiliza tecnologia de análise, comparação e pesquisa facial, onde seu percentual correspondente a nota final podem variar de acordo com tamanho do banco de dados aplicado. Comparações fenotípicas também são realizadas, pautada na cor da pele e dos olhos, cor e tipo natural do cabelo, além da estatura, onde é recomendado que o preenchimento dos dados das doadoras sejam pautados na Tabela de Fitzpatrick, Escala de Martin-Schultz, Classificação Walker e estatura em unidade de medida SI - metro(m)."
                                                    />
                                                    <br />
                                                    <br />
                                                    <FormattedMessage
                                                        id="dashboard.about.contact"
                                                        defaultMessage="Entre em contato através do e-mail"
                                                    /> <a href="mailto:contato@isispro.com.br">contato@isispro.com.br</a>.
                                                    <br />
                                                    <br />
                                                    <AuthContext.Consumer>
                                                        {({ user }) => (
                                                            <>
                                                                {user !== undefined && (
                                                                    <TermsModal
                                                                        user={user}
                                                                        show={showTerms}
                                                                        readOnly={true}
                                                                        onConfirm={() => {
                                                                            setShowTerms(false);
                                                                        }}
                                                                        onHide={() => {
                                                                            setShowTerms(false);
                                                                        }}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </AuthContext.Consumer>
                                                    <a href="" onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowTerms(true);
                                                    }}>
                                                        <FormattedMessage
                                                            id="dashboard.about.terms"
                                                            defaultMessage="Termos de uso e Política de privacidade."
                                                        /></a>
                                                </small>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default DashboardPage;
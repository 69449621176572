import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_CONFIRM, LANG_COMMON_HEADER_CONFIRM } from "../../lang";

interface Props {
    message: string
    show: boolean
    onConfirm: () => void
    onHide: () => void
}
const Confirm: FC<Props> = ({ message, show, onConfirm, onHide }) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
            className="modal-default"
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    <FormattedMessage {...LANG_COMMON_HEADER_CONFIRM} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>{message}</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                    <FormattedMessage {...LANG_COMMON_CANCEL} />
                </Button>
                <Button variant="primary" onClick={onConfirm} className="btn-custom bg-custom-gradient">
                    <FormattedMessage {...LANG_COMMON_CONFIRM} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Confirm;
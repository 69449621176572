import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { UserModel } from "../../../models/UserModel";
import { UserType } from "../../../types/UserType";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../../hooks/useAuth";
import { AuthContext } from "../../../providers/AuthContextProvider";
import { RodType } from "../../../types/RodType";
import { RodModel } from "../../../models/RodModel";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { BornAliveType } from "../../../types/PairingType";
import { PairingModel } from "../../../models/PairingModel";
import { useIntl } from "react-intl";
import { LANG_COMMON_BLANK, LANG_COMMON_BORN_ALIVE, LANG_COMMON_CANCEL, LANG_COMMON_FEMALE, LANG_COMMON_GENDER, LANG_COMMON_MALE, LANG_COMMON_NEW, LANG_COMMON_SAVE, LANG_ERROR, LANG_PERSON_BIRTH_DATE, LANG_WARNING_FILL_ALL } from "../../../lang";

interface Props {
    show: boolean
    onHide: () => void
    onSave: () => void
    pairingId: number
}
const AddBornAliveModal: FC<Props> = ({ show, onHide, onSave, pairingId }) => {
    const intl = useIntl();
    const schema = yup.object().shape({
        id: yup.number().nullable(),
        pairing_id: yup.number().nullable(),
        birth_date: yup.string().required(),
        gender: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const initialBornAlive: BornAliveType = {
        id: -1,
        pairing_id: pairingId,
        birth_date: "",
        gender: ""
    };

    const onSubmit = async (values: BornAliveType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setHasError(false);
        PairingModel().createBornAlive(pairingId, values).then(
            (resp) => {
                setLoading(false);
                if (typeof resp !== "boolean") {
                    onSave();
                } else {
                    setHasError(true);
                }
            });
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialBornAlive}
            >
                {({
                    setFieldValue,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                {intl.formatMessage(LANG_COMMON_NEW)} {" "} {intl.formatMessage(LANG_COMMON_BORN_ALIVE)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}>{intl.formatMessage(LANG_WARNING_FILL_ALL)}</Alert>)}
                            {hasError && (<Alert variant={'danger'}>{intl.formatMessage(LANG_ERROR)}</Alert>)}
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_COMMON_GENDER)}</Form.Label>
                                        <select
                                            value={values.gender}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setFieldValue("gender", e.target.value);
                                            }}
                                            className={clsx("form-control form-select", {
                                                "is-invalid": touched.gender && errors.gender,
                                                "is-valid": !errors.gender && formWasSubmitted
                                            })}
                                        >
                                            <option value="">{intl.formatMessage(LANG_COMMON_BLANK)}</option>
                                            <option value="F">{intl.formatMessage(LANG_COMMON_FEMALE)}</option>
                                            <option value="M">{intl.formatMessage(LANG_COMMON_MALE)}</option>
                                        </select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_PERSON_BIRTH_DATE)}</Form.Label>
                                        <InputMask
                                            name={"birth_date"}
                                            type="text"
                                            mask="99/99/9999"
                                            value={values.birth_date}
                                            onChange={(e) => {
                                                if (e.target.value !== null) {
                                                    setFieldValue("birth_date", e.target.value);
                                                }
                                            }}
                                            className={clsx("form-control", {
                                                "is-invalid": touched.birth_date && errors.birth_date,
                                                "is-valid": !errors.birth_date && formWasSubmitted
                                            })}
                                        />
                                    </Form.Group>
                                </div>

                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                {intl.formatMessage(LANG_COMMON_CANCEL)}
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /></> : intl.formatMessage(LANG_COMMON_SAVE)}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AddBornAliveModal;
import React, {FC, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import SubscriptionPlans from "./SubscriptionPlans";
import {PlanType} from "../../types/PlanType";
import SubscriptionBusinessForm from "./SubscriptionBusinessForm";
import SubscriptionCreditCard from "./SubscriptionCreditCard";
import SubscriptionMessages from "./SubscriptionMessages";
import {BusinessType} from "../../types/BusinessType";
import {useAPI} from "../../hooks/useAPI";
import axios from "axios";
import {useConfig} from "../../hooks/useConfig";
import {UserType} from "../../types/UserType";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_SUBSCRIPTION } from "../../lang";

interface Props {
    show: boolean
    onConfirm: () => void
    onHide: () => void
    businessId: number
}
const SubscriptionModal: FC<Props> = ({ show, onConfirm, onHide, businessId}) => {
    const intl = useIntl();
    const [messageType, setMessageType] = useState("waiting");
    const [step, setStep] = useState(0);
    const [plan, setPlan] = useState<undefined | PlanType>(undefined);
    const [paymentOptionId, setPaymentOptionId] = useState("");
    const [business, setBusiness] = useState<undefined | BusinessType>(undefined);
    const [intervalPercentage, setIntervalPercentage] = useState(0);
    const {api} = useAPI();
    const {getConfig} = useConfig();

    const createSubscription = (plan: PlanType, business: BusinessType, card: any) => {
        let expiry = card.expiry.split("/");
        let expiry_month = expiry[0];
        let expiry_year = expiry[1];
        axios.post(`https://api.pagar.me/core/v5/tokens?appId=${getConfig("PAGARME_APPID")}`, {
            card: {
                "number": card.number,
                "holder_name": card.name,
                "exp_month": expiry_month,
                "exp_year": expiry_year,
                "cvv": card.cvc,
                "billing_address": {
                    "line_1": business.address?.street,
                    "line_2": business.address?.complement,
                    "zip_code": business.address?.zipcode.replace("-", ""),
                    "city": business.address?.city.name,
                    "state": business.address?.city.state.short_name,
                    "country": "BR"
                }
            }
        }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then((card_token) => {
            api().post("/api/subscription", {
                plan_id: plan.id,
                payment_option_id: paymentOptionId,
                ...business,
                card: card_token.data,
                document_type: card.document_type,
                document: card.document,
                card_holder: card.name
            }).then((resp) => {
                if(resp.data.success === true) {
                    checkSubscription();
                }
            })
            .catch((error) => {
                setMessageType("error");
            })
        }).catch((error) => {
            alert(intl.formatMessage({
                id: 'subscription.card_error',
                defaultMessage: "OPS! Verifique os dados do cartão e tente novamente, por favor."
            }))
        });
    }

    const checkSubscription = () => {
        let intervalCount = 1;
        const interval = setInterval(() => {
            if(intervalCount <= 10) {
                setIntervalPercentage(intervalCount / 10 * 100);
                intervalCount++;
                api().get("/api/user/self")
                    .then((resp) => {
                        if(resp.data.has_active_subscription) {
                            clearInterval(interval);
                            setMessageType("done");
                        }
                    });
            } else {
                clearInterval(interval);
                setMessageType("pending");
            }
        }, 10 * 1000);
    }

    return (
        <Modal
            size="xl"
            show={show}
            onHide={() => {}}
            aria-labelledby="example-modal-sizes-title-sm"
            className="modal-default"
        >
            <Modal.Header closeButton={false} closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    <FormattedMessage {...LANG_COMMON_SUBSCRIPTION} />
                </Modal.Title>
            </Modal.Header>
            {/******************************
             * PLANS
             ******************************/}
            {step === 0 && (
                <SubscriptionPlans onChoose={(plan, paymentOptionId) => {
                    setPlan(plan);
                    setPaymentOptionId(paymentOptionId);
                    setStep(1);
                }} />
            )}
            {/******************************
             * BUSINESS & ADDRESS
             ******************************/}
            {step === 1 && (
                <SubscriptionBusinessForm
                    businessId={businessId}
                    onBack={() => setStep(step -1)}
                    onValidate={(isValid, business) => {
                        if(isValid) {
                            setStep(step + 1);
                            setBusiness(business);
                        }
                    }}
                />
            )}
            {/******************************
             * CREDIT CARD INFO
             ******************************/}
            {step === 2 && (
                <SubscriptionCreditCard
                    onBack={() => setStep(step -1)}
                    onValidate={(isValid, card) => {
                        if(isValid) {
                            setStep(step + 1);
                            if(plan !== undefined && business !== undefined) {
                                createSubscription(plan, business, card);
                            }
                        }
                    }}
                />
            )}
            {/******************************
             * GATEWAY REQUEST
             ******************************/}
            {step === 3 && (
                <SubscriptionMessages
                    messageType={messageType}
                    onConfirm={onConfirm}
                    loaderPercentage={intervalPercentage}
                />
            )}
        </Modal>
    );
}

export default SubscriptionModal;
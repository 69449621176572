import React, {useEffect, useState} from "react";
import {useAPI} from "../../hooks/useAPI";

const CityListHavingPersons = ({...props}) => {
    const {api} = useAPI();
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(-1);

    const loadCities = () => {
        setIsLoading(true);
        api().get(`/api/city/having-persons`).then((resp) => {
            setIsLoading(false);
            setCities(resp.data);
        }, (err) => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadCities();
    }, []);

    return (
        <div>
            {(!isLoading) ?
                <select
                    {...props}
                    disabled={false}
                    onChange={(e: any) => {
                        setCity(e.target.value);
                        props.onChange(e.target.value);
                    }}>
                    <option value="">{props.defaultOption ? props.defaultOption : "..."}</option>
                    {cities.map((city: any) => {
                        return (<option value={city.id} key={city.id}>{city.name}</option>);
                    })}
                </select>
                :
                <select disabled={true} {...props}>
                    <option value="">Carregando...</option>
                </select>
            }
        </div>
    );
};

export default CityListHavingPersons;
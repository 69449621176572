export function UserReducer (state: any, action: any) {
    switch (action.type) {
        default:
            return state;
    }
}

export function UserListReducer (state: any, action: any) {
    switch (action.type) {
        case "addUser":
            state.users.push(action.user);
            return {...state, users: state.users};
        case "updateUser":
            for(let i in state.users){
                if(state.users[i].id === action.user.id) {
                    state.users[i] = action.user;
                }
            }
            return {...state, users: state.users};
        case "removeUser":
            for(let i in state.users){
                if(state.users[i].id === action.user_id) {
                    state.users.splice(i, 1);
                }
            }
            return {...state, users: state.users};
        case "setUsers":
            return {...state, users: action.users};
        default:
            return state;
    }
}
import React, {FC, useEffect, useState} from "react";
import {useAPI} from "../../hooks/useAPI";
import {PlanType} from "../../types/PlanType";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";
import * as yup from "yup";
import {useFormik} from "formik";
import {BusinessModel} from "../../models/BusinessModel";
import {toast} from "react-toastify";
import AddressForm from "../form/AddressForm";
import {BusinessType} from "../../types/BusinessType";
import Loader from "../messages/Loader";
import LoaderInside from "../messages/LoaderInside";
import ClipLoader from "react-spinners/ClipLoader";
import { useIntl } from "react-intl";
import { LANG_COMMON_BACK, LANG_COMMON_BUSINESS_ID, LANG_COMMON_BUSINESS_NAME, LANG_COMMON_NEXT, LANG_COMMON_PHONE } from "../../lang";

interface Props {
    onValidate: (isValid: boolean, business: BusinessType) => void
    businessId: number
    onBack: () => void
}
const SubscriptionBusinessForm: FC<Props> = ({ onValidate, businessId, onBack}) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [plans, setPlans] = useState<undefined | Array<PlanType>>(undefined);
    const {api} = useAPI();
    const [business, setBusiness] = useState<BusinessType>();

    const loadBusiness = (businessId: number) => {
        setLoading(true);
        BusinessModel().get(businessId).then((resp) => {
            if(typeof resp !== "boolean") {
                setBusiness(resp);
                formik.setValues({...resp, "entity_phone": (resp.entity_phone === undefined) ? "" : resp.entity_phone});
            }
            setLoading(false);
        });
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        entity_name: yup.string().required(),
        entity_doc: yup.string().required(),
        type: yup.string().required(),
        entity_phone: yup.string().required(),
        id: yup.number().nullable(),
        status: yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            entity_name: "",
            entity_doc: "",
            type: "O",
            entity_phone: "",
            id: -1,
            status: "A"
        },
        validationSchema: schema,
        onSubmit: (values) => {},
        validateOnMount: false
    });

    useEffect(() => {
        loadBusiness(businessId);
    }, []);

    return (
        <>
            <Modal.Body>
                {!loading && !hasError && (
                    <>
                        <div className="mx-3">
                            <Row>
                                <Col lg={8}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_COMMON_BUSINESS_NAME)}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="entity_name"
                                            placeholder={intl.formatMessage(LANG_COMMON_BUSINESS_NAME)}
                                            value={formik.values.entity_name}
                                            onChange={formik.handleChange}
                                            isValid={formik.touched.entity_name && !formik.errors.entity_name}
                                            isInvalid={!!formik.errors.entity_name && hasSubmitted}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={5}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_COMMON_BUSINESS_ID)}</Form.Label>
                                        <InputMask
                                            name={"entity_doc"}
                                            type="text"
                                            mask="99.999.999/9999-99"
                                            value={formik.values.entity_doc}
                                            onChange={(e) => {
                                                if(e.target.value !== null) {
                                                    formik.setFieldValue("entity_doc", e.target.value);
                                                }
                                            }}
                                            className={clsx("form-control", {
                                                "is-invalid": formik.touched.entity_doc && formik.errors.entity_doc,
                                                "is-valid": !formik.errors.entity_doc && hasSubmitted
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_COMMON_PHONE)}</Form.Label>
                                        <InputMask
                                            name={"entity_phone"}
                                            type="tel"
                                            mask="(99) 99999-9999"
                                            value={formik.values.entity_phone}
                                            onChange={(e) => {
                                                if(e.target.value !== null) {
                                                    formik.setFieldValue("entity_phone", e.target.value);
                                                }
                                            }}
                                            className={clsx("form-control", {
                                                "is-invalid": formik.errors.entity_phone && hasSubmitted,
                                                "is-valid": !formik.errors.entity_phone && hasSubmitted
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <div className="mx-1">
                                    <hr/>
                                </div>
                            </Row>
                            <AddressForm
                                address={business?.address}
                                hasSubmitted={hasSubmitted}
                                onSubmit={(data) => {
                                    formik.validateForm(formik.values).then((errors) => {
                                        onValidate(Object.keys(errors).length === 0, {...formik.values, address: data});
                                    });
                                }}
                                onValidate={(data) => {}} />
                        </div>
                    </>
                )}
                {loading && (
                    <div style={{"height":"300px", "width": "100%"}}>
                        <LoaderInside />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onBack} className="btn-custom btn-custom-light">
                    {intl.formatMessage(LANG_COMMON_BACK)}
                </Button>
                <Button variant="primary" onClick={() => {
                    formik.validateForm(formik.values).then((errors) => {
                        setHasSubmitted(true);
                        setTimeout(() => {
                            setHasSubmitted(false);
                        }, 500);
                    });
                }} className="btn-custom bg-custom-gradient">
                    {intl.formatMessage(LANG_COMMON_NEXT)}
                </Button>
            </Modal.Footer>
        </>
    );
}

export default SubscriptionBusinessForm;
import React, { FC } from 'react';
import RoutesWrapper from "./routes/RoutesWrapper";
import { AuthContext, AuthContextProvider } from "./providers/AuthContextProvider";
import './App.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";
import { IntlProvider } from 'react-intl';
import { OptionsContextProvider } from './providers/OptionsContextProvider';

interface Props {
    locale: string | undefined
    messages?: any
}

const App: FC<Props> = ({ locale, messages }) => {
    return (
        <IntlProvider locale={(locale === undefined) ? 'pt' : locale} defaultLocale="pt" messages={messages}>
            <OptionsContextProvider>
                <AuthContextProvider>
                    <RoutesWrapper basepath={(locale === undefined) ? '/' : `/${locale}`} />
                    <AuthContext.Consumer>
                        {({ loading, isAuth }) => (
                            <>
                                {!loading && (
                                    <>
                                        <ToastContainer
                                            position="bottom-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="colored"
                                        />
                                    </>
                                )}
                                {loading && (
                                    <>
                                        <div style={{
                                            "fontSize": "1rem",
                                            "position": "fixed",
                                            "top": "45%",
                                            "width": "100%"
                                        }} className={"text-center"}>
                                            <ClipLoader size={"1rem"} /> Carregando...
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </AuthContext.Consumer>
                </AuthContextProvider>
            </OptionsContextProvider>
        </IntlProvider>
    );
}

export default App;

import React, {FC, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useConfig} from "../hooks/useConfig";
import ClipLoader from "react-spinners/ClipLoader";
import {UserModel} from "../models/UserModel";
import {UserType} from "../types/UserType";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

interface Props {
    show: boolean
    onConfirm: () => void
    onHide: () => void
    user: UserType
    readOnly?: boolean
}
const TermsModal: FC<Props> = ({ show, onConfirm, onHide, user, readOnly=undefined}) => {
    const {getConfig} = useConfig();
    const url = `${getConfig('SITE_URL')}/assets/terms.pdf?v=2024-03-13`;
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState<any>(null);

    // @ts-ignore
    function onDocumentLoadSuccess({ numPages}) {
        setNumPages(numPages);
    }

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
            className="modal-default"
        >
            <Modal.Header closeButton={(readOnly === true)} closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    Termos de uso e Política de Privacidade
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {readOnly === undefined && (<h6>Nossos termos de uso e política de privacidade foram atualizados.</h6>)}
                <div style={{"width": "100%", "height": "600px", "overflowY": "scroll"}}>
                    <Document
                        onLoadSuccess={onDocumentLoadSuccess}
                        file={{
                            url: url,
                            loading: "Carregando..."
                        }}>
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    height={1200}
                                    loading={""}
                                />
                            ),
                        )}
                    </Document>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {readOnly === undefined && (
                    <>
                        <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                            Recusar
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setLoading(true);
                            UserModel().updateTermsAgreed(user.id, true).then((resp) => {
                                onConfirm();
                            });
                        }} disabled={loading} className="btn-custom bg-custom-gradient">
                            {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /></> : 'Aceitar'}
                        </Button>
                    </>
                )}
                {readOnly === true && (
                    <>
                        <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                            Fechar
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default TermsModal;
import React, {useContext, useRef, useState} from "react";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import useOutsideClick from "../../hooks/useOutsideClick";
import {Link, useResolvedPath, useMatch, LinkProps, useNavigate} from "react-router-dom";
import clsx from 'clsx';
import {useAuth} from "../../hooks/useAuth";
import {AuthContext} from "../../providers/AuthContextProvider";
import LogoHeader from "./logo-header.png";
import {Form} from "react-bootstrap";
import {useAPI} from "../../hooks/useAPI";
import AsyncSelect from 'react-select/async';
import { PersonType } from "../../types/PersonType";
import { PairingType } from "../../types/PairingType";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_HELLO, LANG_COMMON_LOADING, LANG_COMMON_LOGOUT, LANG_COMMON_NO_RESULTS } from "../../lang";

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const {deleteToken, hasAbility} = useAuth();
    const {doLogout} = useAPI();
    const {setIsAuth, user} = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const {api} = useAPI();

    useOutsideClick(ref, () => {
        setShowDropdown(false);
    });

    const promiseOptions = (inputValue: string) => {
        return new Promise<Array<any>>((resolve) => {
            api().get(`/api/business/search?search=${inputValue}`).then((resp) => {
                let options: Array<any> = [];
                resp.data.persons.map((person: PersonType) => {
                    options.push({"label": `${person.type === "R" ? "RECEPTOR(A)" : "DOADOR(A)"} - ${person.name}`, "value": `/admin/person/${person.id}/view`})
                });
                resp.data.pairings.map((pairing: PairingType) => {
                    options.push({"label": `PAREAMENTO - ${pairing.receiver_person?.name} - ${pairing.donor_person?.name}`, "value": `/admin/pairing/${pairing.id}/view`})
                });
                resolve(options);
            })
          });
    }

    return (
        <header>
            <div className="container-fluid p-0">
                <div className="d-grid align-items-center justify-content-center justify-content-lg-start header-content">
                    <Link to="/admin/dashboard" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                        <img className="bi me-2" width="132" height="36" role="img" aria-label="Bootstrap" src={LogoHeader} />
                    </Link>
                    <div className="search-container">
                        <i className="bi bi-search" />
                        <AsyncSelect
                            placeholder={<FormattedMessage
                                id="header.search"
                                defaultMessage="Pesquisar pelo n° do prontuário..."
                            />}
                            className={clsx("react-select-container", {})}
                            classNamePrefix={'react-select'}
                            onChange={(option) => {
                                navigate(option.value, {replace: true});
                            }}
                            loadOptions={promiseOptions}
                            loadingMessage={(inputValue) => <FormattedMessage {...LANG_COMMON_LOADING} />}
                            noOptionsMessage={(inputValue) => <FormattedMessage {...LANG_COMMON_NO_RESULTS} />}
                        />



                        {/*<form action="/admin/receivers" onSubmit={(e) => {
                            e.preventDefault();
                            window.location.href = `/admin/receivers/${search}`;
                        }}>
                            <i className="bi bi-search" />
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Buscar"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                            <input type="submit" style={{"display": "none"}}/>
                        </form>*/}
                    </div>
                    <div className="user-name text-right"><FormattedMessage {...LANG_COMMON_HELLO}/>, {user?.name}</div>
                    <div className="dropdown text-end">
                        <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle"
                          id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"
                           onClick={(e) => setShowDropdown(!showDropdown)}
                           ref={ref}
                        >
                            <img src="/media/avatars/blank.png" alt="mdo" width="58" height="58" style={{"border": "2px solid #fff"}}
                                 className="rounded-circle" />
                        </a>
                        <DropdownMenu show={showDropdown} align={"end"}>
                            {/*<DropdownItem><a className="dropdown-item" href="#">New project...</a></DropdownItem>
                            <DropdownItem><a className="dropdown-item" href="#">New project...</a></DropdownItem>
                            <DropdownItem><hr className="dropdown-divider" /></DropdownItem>*/}
                            <DropdownItem><a className="dropdown-item" href="" onClick={(e) => {
                                e.preventDefault();
                                doLogout();
                                deleteToken();
                                setIsAuth(false);
                                navigate("/auth/login");
                            }}><FormattedMessage {...LANG_COMMON_LOGOUT}/></a></DropdownItem>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
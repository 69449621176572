import React, {FC, useEffect, useState} from "react";
import Confirm from "./messages/Confirm";
import ClipLoader from "react-spinners/ClipLoader";
import {Button} from "react-bootstrap";
import "./styles/ImageUploader.css";
import clsx from "clsx";
import Error from "./messages/Error";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_TRY_AGAIN } from "../lang";

interface Props {
    url: string | null | undefined
    onChange: (file: any, uploadUrl: string) => void
    onRemove: (removeUrl: string) => void
    uploadUrl: string
    removeUrl: string
    width?: number
    height?:number
    loading: boolean
    edit?: boolean
    hasUploadError?: boolean
    uploadErrorMessage?: string
    hasDownloadError?: boolean
    download?: (url: string | null | undefined) => void
    photoLabel?: string
    photoSubLabel?: string
    noGradient?:boolean
    stared?: boolean
    showCheckbox?: boolean
    onSelect?: (selected: boolean) => void
}

const ImageUploader: FC<Props> = (
    {
        hasUploadError=false,
        uploadErrorMessage="",
        hasDownloadError=false,
        edit=true,
        loading,
        url,
        onChange,
        onRemove,
        uploadUrl,
        removeUrl,
        height=125,
        width=125,
        download=undefined,
        photoLabel="",
        photoSubLabel=undefined,
        noGradient=false,
        stared=false,
        showCheckbox=false,
        onSelect=(selected) => {}
    }) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>("/media/avatars/blank.png");
    const [file, setFile] = useState<any>(undefined);
    const [showUploarErrorMessage, setShowUploadErrorMessage] = useState(false);
    const [selected, setSelected] = useState(true);

    useEffect(() => {
        if (url !== null && url !== undefined) {
            setImageUrl(url);
        } else {
            setImageUrl("/media/avatars/blank.png");
        }
    }, [url]);

    useEffect(() => {
        setShowUploadErrorMessage(hasUploadError && !loading);
    }, [hasUploadError, loading]);

    const EditButton = (
        <>
            {edit && (
                <label
                    style={{
                        "position": "absolute",
                        "top": "3px",
                        "right": "4px"
                    }}
                >
                    <i className="icon icon-edit">
                        <svg version="1.1" id="Camada_1"
                             xmlns="http://www.w3.org/2000/svg"
                             x="0px" y="0px"
                             viewBox="0 0 15.76 15.8" style={{"fill": "#fff"}}>
                            <g>
                                <path className="st0" d="M14.29,6.03c-0.2,0-0.39,0.08-0.53,0.22c-0.14,0.14-0.22,0.33-0.22,0.53v6.77c0,0.2-0.08,0.39-0.22,0.53
                                                                            s-0.33,0.22-0.53,0.22H2.26c-0.2,0-0.39-0.08-0.53-0.22S1.5,13.74,1.5,13.54V3.02c0-0.2,0.08-0.39,0.22-0.53s0.33-0.22,0.53-0.22
                                                                            h6.77c0.27,0,0.52-0.14,0.65-0.38c0.13-0.23,0.13-0.52,0-0.75C9.54,0.91,9.29,0.76,9.02,0.76H2.26c-0.6,0-1.17,0.24-1.6,0.66
                                                                            C0.24,1.85,0,2.42,0,3.02v10.53c0,0.6,0.24,1.17,0.66,1.6c0.42,0.42,1,0.66,1.6,0.66h10.53c0.6,0,1.17-0.24,1.6-0.66
                                                                            c0.42-0.42,0.66-1,0.66-1.6V6.78c0-0.2-0.08-0.39-0.22-0.53C14.68,6.11,14.49,6.03,14.29,6.03z M15.42,1.67l-1.29-1.29
                                                                            c-0.28-0.28-0.68-0.42-1.08-0.37c-0.36,0.05-0.7,0.21-0.95,0.47L6.77,5.84C6.62,5.99,6.49,6.16,6.41,6.36L5.41,8.61
                                                                            C5.3,8.86,5.26,9.13,5.3,9.4c0.04,0.27,0.17,0.52,0.35,0.71c0.24,0.24,0.57,0.38,0.91,0.38c0.21,0,0.42-0.04,0.61-0.13l2.26-0.99
                                                                            c0.19-0.08,0.37-0.2,0.52-0.35l5.34-5.34c0.28-0.25,0.45-0.6,0.47-0.98C15.78,2.33,15.66,1.96,15.42,1.67L15.42,1.67z M14.24,2.65
                                                                            L8.89,7.99C8.88,8,8.86,8.02,8.84,8.03L6.95,8.85L7.81,6.9l5.34-5.34l1.14,1.08L14.24,2.65z"/>
                            </g>
                        </svg>
                    </i>
                    <input style={{"display": "none"}} type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={(e: any) => {
                        setFile(e.target.files[0]);
                        onChange(e.target.files[0], uploadUrl);
                    }}/>
                </label>
            )}
        </>
    )

    return (
        <>
            <div className={clsx("photo-container", {
                "no-gradient": noGradient
            })}>
                {stared === true && (<div className="stared">
                    <i className="bi bi-star-fill" />
                </div>)}
                {showCheckbox && (
                    <div className="checkbox-container">
                        <input type="checkbox" checked={selected} onChange={(e) => {
                            setSelected(e.target.checked);
                            onSelect(e.target.checked);
                        }}/>
                    </div>
                )}
                {loading && (
                    <>
                        <div className="photo-content">
                            <div style={{
                                "textAlign": "center",
                                "marginTop": "35px"
                            }}><ClipLoader size={"1.5rem"} color="#ffffff"/></div>
                        </div>
                        <div className="photo-label">{photoLabel}</div>
                        {photoSubLabel !== undefined && (<div className="photo-sub-label">{photoSubLabel}</div>)}
                    </>
                )}
                {!loading && hasUploadError && (
                    <>
                        <Error message={uploadErrorMessage} show={showUploarErrorMessage} onHide={() => setShowUploadErrorMessage(false)} />
                        {EditButton}
                        <div className="photo-content">
                            <div className={"text-center button-container"}>
                                <Button variant={"danger"} onClick={() => {
                                    onChange(file, uploadUrl);
                                }}><i className="bi bi-upload"/> <FormattedMessage {...LANG_COMMON_TRY_AGAIN}/></Button>
                            </div>
                        </div>
                        <div className="photo-label">{photoLabel}</div>
                        {photoSubLabel !== undefined && (<div className="photo-sub-label">{photoSubLabel}</div>)}
                    </>
                )}
                {!loading && hasDownloadError && (
                    <>
                        {EditButton}
                        <div className="photo-content">
                            <div className={"text-center button-container"}>
                                <Button variant={"danger"} onClick={() => {
                                    if(download !== undefined){
                                        download(url);
                                    }
                                }}><i className="bi bi-download"/> <FormattedMessage {...LANG_COMMON_TRY_AGAIN}/></Button>
                            </div>
                        </div>
                        <div className="photo-label">{photoLabel}</div>
                        {photoSubLabel !== undefined && (<div className="photo-sub-label">{photoSubLabel}</div>)}
                    </>
                )}
                {!loading && !hasDownloadError && !hasUploadError && (
                    <>
                    <div style={{
                            "backgroundImage": `url(${imageUrl})`,
                        }} className="photo-content"/>
                        {edit && (
                            <>
                                {EditButton}
                            </>
                        )}
                        <div className="photo-label">{photoLabel}</div>
                        {photoSubLabel !== undefined && (<div className="photo-sub-label">{photoSubLabel}</div>)}
                    </>
                )}
            </div>
        </>
    );
};

export default ImageUploader;
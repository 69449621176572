import React, {createContext, FC, useEffect, useReducer} from "react";
import {useAPI} from "../hooks/useAPI";
import download from "downloadjs";

// Reducer
function ImageUploadProviderReducer(state: any, action: any) {
    switch (action.type){
        case 'updateUrl':
            return {...state, url: action.url};
        case 'setLoading':
            return {...state, loading: action.loading};
        case 'setHasUploadError':
            return {...state, hasUploadError: action.hasUploadError};
        case 'setUploadErrorMessage':
            return {...state, uploadErrorMessage: action.uploadErrorMessage};
        case 'setHasDownloadError':
            return {...state, hasDownloadError: action.hasDownloadError};
        default:
            throw new Error();
    }
}

// Default data
interface FileUploadProviderInterface {
    url: string | null | undefined
    onChange: (file: any, uploadUrl: string) => void
    onRemove: (removeUrl: string) => void
    loading: boolean
    hasUploadError: boolean
    uploadErrorMessage: string
    hasDownloadError: boolean
    upload: (file: any, uploadUrl: string) => void
    download: (url: string | null | undefined) => void
}
const defaultData = {
    url: null,
    onChange: (file: any, uploadUrl: string) => {},
    onRemove: (removeUrl: string) => {},
    loading: false,
    hasUploadError: false,
    uploadErrorMessage: "",
    hasDownloadError: false,
    upload: (file: any, uploadUrl: string) => {},
    download: (url: string | null | undefined) => {}
}

// Context
const FileUploadProviderContext = createContext<FileUploadProviderInterface>(defaultData);

interface Props {
    url: string | undefined
    onChange?: (url: string | null) => void
    onDone?: () => void
}
const FileUploadProvider: FC<Props> = ({children, url, onChange=undefined, onDone=undefined}) => {
    const {api} = useAPI();
    const updateFile = (file: any, uploadUrl: string) => {
        dispatch({type: 'setLoading', loading: true});
        dispatch({type: 'setHasUploadError', hasUploadError: false});
        api().upload(uploadUrl, file).then((resp) => {
            if(resp.data.success === true) {
                dispatch({type: 'updateUrl', url: resp.data.url});
                dispatch({type: 'setLoading', loading: false});
                if(onDone !== undefined) {
                    onDone();
                }
            } else {
                dispatch({type: 'setLoading', loading: false});
                dispatch({type: 'setHasUploadError', hasUploadError: true});
                dispatch({type: 'setUploadErrorMessage', uploadErrorMessage: resp.data.msg});
            }
        }, (err) => {
            dispatch({type: 'setLoading', loading: false});
            dispatch({type: 'setHasUploadError', hasUploadError: true});
            dispatch({type: 'setUploadErrorMessage', uploadErrorMessage: "Houve um erro desconhecido. Tente novamente, por favor!"});
        });
    }

    const downloadFile = (url: string | null | undefined) => {
        if(url !== null && url !== undefined) {
            dispatch({type: 'setLoading', loading: true});
            dispatch({type: 'setHasDownloadError', hasDownloadError: false});
            api().downloadPDF(url).then(response => {
                const content = response.headers['content-type'];
                const name = response.headers['content-type'].split("__FILENAME__")[1] || "arquivo";                
                download(response.data, name, content);
                dispatch({type: 'setLoading', loading: false});
            })
                .catch(error => {
                    dispatch({type: 'setLoading', loading: false});
                    dispatch({type: 'setHasDownloadError', hasDownloadError: true});
                });
        } else {
            dispatch({type: 'updateUrl', url: url});
        }
    }

    const removeFile = (removeUrl: string) => {
        dispatch({type: 'setLoading', loading: true});
        api().del(removeUrl).then((resp) => {
            dispatch({type: 'updateUrl', url: null});
            dispatch({type: 'setLoading', loading: false});
            if(onChange !== undefined) {
                onChange(null);
            }
        }, (err) => {
            dispatch({type: 'setLoading', loading: false});
        });
    }

    const [fileData, dispatch] = useReducer(ImageUploadProviderReducer, {
        ...defaultData,
        url: url,
        onChange: updateFile,
        onRemove: removeFile,
        download: downloadFile,
        upload: updateFile
    });

    return (<FileUploadProviderContext.Provider value={fileData}>{children}</FileUploadProviderContext.Provider>);
}

export {FileUploadProvider, FileUploadProviderContext};
import {PairedPersonType, PairingResponse, PersonPaginate, PersonType} from "../types/PersonType";
import {useAPI} from "../hooks/useAPI";
import {OptionType, ResponseErrorType} from "../types/CommonType";

export function PersonModel() {
    const {api} = useAPI();

    const create = async (person: PersonType) => {
        return new Promise<ResponseErrorType | PersonType>((resolve) => {
            api()
                .post("/api/person", person)
                .then((resp) => {
                    resolve(resp.data.person);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const update = async (person_id: number, person: PersonType) => {
        return new Promise<boolean | PersonType>((resolve) => {
            api()
                .put(`/api/person/${person_id}`, person)
                .then((resp) => {
                    resolve(resp.data.person);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (type: string ,business_id: number | undefined = undefined, options: any | undefined = undefined, pageNumber: number=1, perPage: number=10) => {
        return new Promise<boolean | PersonPaginate>((resolve) => {
            api()
                .get(`/api/person?business_id=${(business_id !== undefined) ? business_id : ""}&count=${perPage}&type=${type}&page=${pageNumber}&${new URLSearchParams(options).toString()}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (person_id: number | string) => {
        return new Promise<PersonType | boolean>((resolve) => {
            api()
                .get(`/api/person/${person_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const getPairingUrl = (person_id: number | string, options: any) => {
        let filteredOptions: any = {};
        for(let i in options) {
            console.log(i, options[i])
            if(options[i] !== null) filteredOptions[i] = options[i];
        }
        return `/api/person/${person_id}/pairing?${new URLSearchParams({...filteredOptions, cache: (new Date()).getTime()}).toString()}`;
    }

    const pairing = async (person_id: number | string, options: any) => {
        return new Promise<PairingResponse | boolean>((resolve) => {
            api()
                .get(getPairingUrl(person_id, options))
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (person_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/person/${person_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const getBloodTypes = () : Array<OptionType> => {
        return ["A", "B", "O", "AB"].map((item) => {
            return {label: item, value: item};
        });
    }    

    const getRaceTypes = () : Array<OptionType> => {
        return [
            {label: "Branco", value: "B"},
            {label: "Negro", value: "N"},
            {label: "Pardo", value: "P"},
            {label: "Asiático", value: "A"},
            {label: "Indígena", value: "I"},
        ];
    }

    const getSkinColors = () : Array<OptionType> => {
        return [
            {label: "Tipo I (Branca-pálida)", value: "1"},
            {label: "Tipo II (Branca)", value: "2"},
            {label: "Tipo III (Branca - Bronzeia)", value: "3"},
            {label: "Tipo IV (Castanha-clara)", value: "4"},
            {label: "Tipo V (Parda)", value: "5"},
            {label: "Tipo VI (Negra)", value: "6"},
        ];
    }

    const getEyesColors = () : Array<OptionType> => {
        return [
            {label: "Azul", value: "A"},
            {label: "Azul-esverdeado", value: "AE"},
            {label: "Cinza", value: "CI"},
            {label: "Verde", value: "V"},
            {label: "Castanho esverdeado", value: "CEV"},
            {label: "Mel", value: "MEL"},
            {label: "Castanho claro", value: "CC"},
            {label: "Castanho escuro", value: "CE"},
        ];
    }

    const getHairColors = () : Array<OptionType> => {
        return [
            {label: "Loiro", value: "LOI"},
            {label: "Ruivo", value: "RUI"},
            {label: "Castanho claro", value: "CAC"},
            {label: "Castanho escuro", value: "CAE"},
            {label: "Preto", value: "PRE"},
        ];
    }

    const getHairTypes = () : Array<OptionType> => {
        return [
            {label: "Liso", value: "LIS"},
            {label: "Ondulado", value: "OND"},
            {label: "Cacheado", value: "CAC"},
            {label: "Crespo", value: "CRE"},
        ];
    }

    const getHeightTypes = () : Array<OptionType> => {
        return [
            {label: "<= 1,49m", value: "149"},
            {label: "1,50m - 1,54m", value: "154"},
            {label: "1,55m - 1,59m", value: "159"},
            {label: "1,60m - 1,64m", value: "164"},
            {label: "1,65m - 1,69m", value: "169"},
            {label: "1,70m - 1,74m", value: "174"},
            {label: "1,75m - 1,79m", value: "179"},
            {label: ">= 1,80m", value: "180"},
        ];
    }

    const getBMITypes = () : Array<OptionType> => {
        return [
            {label: "<18,5", value: "185"},
            {label: "18,5 - 24,9", value: "249"},
            {label: "25 - 29,9", value: "299"},
            {label: "30 - 34,9", value: "349"},
            {label: "35 - 39,9", value: "399"},
            {label: ">40", value: "400"},
        ];
    }

    const getSchooling = () : Array<OptionType> => {
        return [
            {label: "Superior Completo", value: "1"},
            {label: "Superior Incompleto", value: "2"},
            {label: "Ensino Médio Completo", value: "3"},
            {label: "Ensino Médio Incompleto", value: "4"},
            {label: "Ensino Fundamental Completo", value: "5"},
            {label: "Ensino Fundamental Incompleto", value: "6"},
        ];
    }

    const getOption = (needle: string | undefined | null, options: Array<OptionType> | undefined) : OptionType | undefined => {
        if(needle === undefined || needle === null || options === undefined) return undefined;

        for(let i in options) {
            if(options[i].value === needle.toString())
                return options[i];
        }

        return undefined;
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        getPairingUrl,
        pairing,
        getBloodTypes,
        getRaceTypes,
        getSkinColors,
        getEyesColors,
        getHairColors,
        getHairTypes,
        getHeightTypes,
        getBMITypes,
        getSchooling,
        getOption
    };
 }
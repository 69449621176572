import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import PersonForm from "./components/PersonForm";
import { PersonModel } from "../../models/PersonModel";
import { PersonFile, PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import PersonsSelect from "../../components/PersonsSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import TempLayout from "../../temp/04_receptoras_isis-4.png";
import RodTable from "./components/RodTable";
import StateList from "../../components/form/StateList";
import clsx from "clsx";
import CityList from "../../components/form/CityList";
import TranslateTerms from "../../components/TranslateTerms";
import Error from "../../components/messages/Error";
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { useIntl } from "react-intl";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { LANG_COMMON_BACK, LANG_COMMON_CHOOSE, LANG_COMMON_DATA, LANG_COMMON_FILES, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_OTHER_DATA, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_SCHOOLING } from "../../lang";

const PersonEditPage = () => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [person, setPerson] = useState<PersonType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showErrorValidation, setShowErrorValidation] = useState(false);
    const [files, setFiles] = useState<PersonFile[] | undefined>([]);

    const navigate = useNavigate();
    const { id } = useParams();
    const loadPerson = () => {
        setLoading(true);
        if (id !== undefined) {
            PersonModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    formik.setFieldValue("schooling", resp.schooling);
                    formik.setFieldValue("has_children", resp.has_children);
                    formik.setFieldValue("hobbies", resp.hobbies);
                    formik.setFieldValue("occupation", resp.occupation);
                    setPerson(resp);
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const loadFiles = () => {
        setLoading(true);
        if (id !== undefined) {
            PersonModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const schema = yup.object().shape({
        schooling: yup.string().nullable(),
        has_children: yup.boolean().nullable(),
        hobbies: yup.string().nullable(),
        occupation: yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            schooling: "",
            has_children: undefined,
            hobbies: "",
            occupation: ""
        },
        validationSchema: schema,
        onSubmit: () => { },
        validateOnMount: false
    });

    useEffect(() => {
        loadPerson();
    }, []);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            <Error
                show={showErrorValidation}
                onHide={() => {
                    setShowErrorValidation(false);
                }}
                message={`Preencha corretamente os campos marcados em vermelho e tente novamente.`} />
            {person && (
                <div className="bg-pattern">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        <Row>
                            <Breadcrumb>
                                {person.type === "R" && (
                                    <>
                                        <Breadcrumb.Item><Link to={'/admin/receivers'}><TranslateTerms term="receptor" /></Link></Breadcrumb.Item>
                                    </>
                                )}
                                {person.type === "D" && (
                                    <>
                                        <Breadcrumb.Item><Link to={'/admin/donors'}><TranslateTerms term="donor" /></Link></Breadcrumb.Item>
                                    </>
                                )}
                                <Breadcrumb.Item active>{person.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{ offset: 0 }}>
                                <Card>
                                    <CardHeader className={'d-flex'}>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_DATA)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5 className="person-name">{person.name}</h5>
                                                </div>
                                            </div>
                                            {person && (
                                                <PersonForm
                                                    onUpdate={(person) => setPerson({ ...person, ...formik.values })}
                                                    person={person}
                                                    hasSubmitted={hasSubmitted}
                                                    onValidationError={() => {
                                                        setShowErrorValidation(true);
                                                    }
                                                    }
                                                    onSubmit={(person) => {

                                                        formik.validateForm(formik.values).then((errors) => {
                                                            if (Object.keys(errors).length === 0) {
                                                                setShowErrorValidation(false);
                                                                setSaving(true);
                                                                PersonModel().update(person.id, { ...person, ...formik.values }).then((resp) => {
                                                                    if (typeof resp !== "boolean") {
                                                                        toast.success("Registro atualizado com sucesso!");
                                                                        navigate(person.type === "R" ? "/admin/receivers" : "/admin/donors");
                                                                    } else {
                                                                        toast.error("Houve um erro, tente novamente!");
                                                                        setHasSubmitted(false);
                                                                        setSaving(false);
                                                                    }
                                                                });
                                                            }
                                                        })
                                                    }} />)}
                                        </Form>
                                    </Card.Body>
                                </Card>
                                {person.type === "D" && (
                                    <div className="my-4">
                                        {id !== undefined && person !== undefined && (<RodTable personId={parseInt(id)} person={person} />)}
                                    </div>
                                )}
                                <Card className="my-4">
                                    <CardHeader className={'d-flex'}>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_OTHER_DATA)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_SCHOOLING)}</Form.Label>
                                                    <CustomSelect
                                                        name="schooling"
                                                        options={options.personOptions?.getSchooling()}
                                                        onChange={(option: OptionType) => formik.setFieldValue("schooling", option.value)}
                                                        value={formik.values.schooling}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                                                        isSearchable={false}
                                                        isTouched={formik.touched.schooling}
                                                        isValid={formik.touched.schooling && !formik.errors.schooling}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_HAS_CHILDREN)}</Form.Label>
                                                    <Row>
                                                        <Col lg={3}>
                                                            <Form.Check
                                                                type="radio"
                                                                label={`${intl.formatMessage(LANG_COMMON_YES)}`}
                                                                onClick={(e) => {
                                                                    formik.setFieldValue("has_children", true)
                                                                }}
                                                                checked={formik.values.has_children}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                type="radio"
                                                                label={`${intl.formatMessage(LANG_COMMON_NO)}`}
                                                                onClick={(e) => {
                                                                    formik.setFieldValue("has_children", false)
                                                                }}
                                                                checked={!formik.values.has_children}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_OCCUPATION)}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="occupation"
                                                        placeholder={`${intl.formatMessage(LANG_PERSON_OCCUPATION)}`}
                                                        value={formik.values.occupation}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.occupation && !formik.errors.occupation}
                                                        isInvalid={!!formik.errors.occupation && hasSubmitted}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{ span: 6 }}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_HOBBIES)}</Form.Label>
                                                    <textarea name="hobbies" rows={5} className="form-control" onChange={formik.handleChange} value={formik.values.hobbies} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={{ span: 3 }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_FILES)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {files?.map((personFile) => (
                                                    <>
                                                        <FileUploadProvider url={`/api/person/${id}/file/${personFile.file_id}`} onChange={loadFiles} key={personFile.file_id}>
                                                            <FileUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                                    <FileUploader
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={false}
                                                                        uploadErrorMessage={uploadErrorMessage}
                                                                        download={download}
                                                                        loading={loading}
                                                                        url={url}
                                                                        edit={false}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={``}
                                                                        removeUrl={`/api/person/${id}/file/${personFile.file_id}`}
                                                                        remove={true}
                                                                        name={personFile.file.name}
                                                                    />
                                                                )}
                                                            </FileUploadProviderContext.Consumer>
                                                        </FileUploadProvider>
                                                        <hr />
                                                    </>
                                                ))}
                                            </div>
                                            <div className="col-sm-12">
                                                <FileUploadProvider url={undefined} onChange={() => {}} onDone={loadFiles}>
                                                    <FileUploadProviderContext.Consumer>
                                                        {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                            <FileUploader
                                                                hasUploadError={hasUploadError}
                                                                hasDownloadError={false}
                                                                uploadErrorMessage={uploadErrorMessage}
                                                                download={download}
                                                                loading={loading}
                                                                url={url}
                                                                onChange={onChange}
                                                                onRemove={onRemove}
                                                                uploadUrl={`/api/person/${person.id}/file`}
                                                                removeUrl={""} />
                                                        )}
                                                    </FileUploadProviderContext.Consumer>
                                                </FileUploadProvider>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="my-4">
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {person.type === "R" && (
                                                    <Link to="/admin/receivers">
                                                        <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                    </Link>
                                                )}
                                                {person.type === "D" && (
                                                    <Link to="/admin/donors">
                                                        <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                    </Link>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <Button className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100" variant="primary" size={'lg'} onClick={() => {
                                                    setHasSubmitted(true);
                                                    setTimeout(() => {
                                                        setHasSubmitted(false);
                                                    }, 500);
                                                }} disabled={saving}>{saving ? <ClipLoader color={'#ffffff'} loading={saving} size={'12px'} /> : intl.formatMessage(LANG_COMMON_SAVE)}</Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default PersonEditPage;
import React, {FC} from 'react'
import {useAuth} from "../../../hooks/useAuth";
import {ProfileType} from "../../../types/UserType";
import CustomSelect from "../../../components/form/CustomSelect";

interface Props {
    isValid?: boolean
    isTouched?: boolean
    value?: number
    allowed: Array<number>
    onChange: (value: any) => void
    name: string
    disabled: boolean
    disabledOptions?: Array<number>
}
const ProfilesSelect: FC<Props> = (
    {
        value=undefined,
        isValid=false,
        isTouched=false,
        onChange,
        name,
        allowed,
        disabled=false,
        disabledOptions=[]
    }) => {
    const {getAllowedProfiles} = useAuth();
    const profiles = getAllowedProfiles(allowed);
    const options = profiles.map((item: ProfileType) => {
        return {label: item.name, value: item.id, isDisabled: disabledOptions?.includes(item.id)};
    });

    return (

        <CustomSelect
            disabled={disabled}
            name={name}
            options={options}
            onChange={onChange}
            value={value}
            placeholder={"Selecione..."}
            isSearchable={false}
            isTouched={isTouched}
            isValid={isValid}
        />
    )
}

export default ProfilesSelect;
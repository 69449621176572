import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import DashboardPage from "../modules/dashboard/DashboardPage";
import PairingPage from "../modules/pairing/PairingPage";
import UserPage from "../modules/user/UserPage";
import BusinessPage from "../modules/business/BusinessPage";
import PersonPage from "../modules/person/PersonPage";
import PairingAddPage from "../modules/pairing/PairingAddPage";
import PersonEditPage from "../modules/person/PersonEditPage";
import BusinessEditPage from "../modules/business/BusinessEditPage";
import BusinessAddPage from "../modules/business/BusinessAddPage";
import PersonViewPage from "../modules/person/PersonViewPage";
import { useIdleTimer } from 'react-idle-timer';
import Confirm from "../components/messages/Confirm";
import {AuthContext} from "../providers/AuthContextProvider";
import {useAuth} from "../hooks/useAuth";
import {useAPI} from "../hooks/useAPI";
import PairingViewPage from "../modules/pairing/PairingViewPage";
import SubscriptionPage from "../modules/subscription/SubscriptionPage";
import DashboardSubscriptionPage from "../modules/dashboard/DashboardSubscriptionPage";
import ErrorBoundary from "../components/ErrorBoundary";

const PrivateRoutes = () => {
    const {setIsAuth} = useContext(AuthContext);
    const {deleteToken} = useAuth();
    const {doLogout} = useAPI();
    const navigate = useNavigate();
    const [showIdleModal, setShowIdleModal] = useState(false);

    const onPrompt = () => {
        setShowIdleModal(true);
    }

    const onIdle = () => {
        setShowIdleModal(false);
        doLogout();
        deleteToken();
        setIsAuth(false);
        navigate("/auth/login");
    }

    const {
        start,
        reset
    } = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: 1000 * 60 * 10,
        promptTimeout: 1000 * 60,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        element: document,
        startOnMount: true,
        crossTab: true,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    });

    useEffect(start, []);

    return (
        <>
            <Confirm
                message={"Você ainda está aí? Você será desconectado em 60 segundos caso não confirme."}
                show={showIdleModal}
                onConfirm={() => {
                    setShowIdleModal(false);
                    reset();
                }}
                onHide={() => {
                    setShowIdleModal(false);
                    reset();
                }} />
            <ErrorBoundary>
                <Routes>
                    <Route path={'/admin/dashboard'} element={<DashboardPage />} />
                    <Route path={'/admin/subscriptions'} element={<DashboardSubscriptionPage />} />
                    <Route path={'/'} element={<DashboardPage />} />
                    <Route path={'/admin/pairing'} element={<PairingPage />} />
                    <Route path={'/admin/pairing/:id/add'} element={<PairingAddPage />} />
                    <Route path={'/admin/pairing/:id/view'} element={<PairingViewPage />} />
                    <Route path={'/admin/users'} element={<UserPage />} />
                    <Route path={'/admin/businesses'} element={<BusinessPage />} />
                    <Route path={'/admin/businesses/:id/edit'} element={<BusinessEditPage />} />
                    <Route path={'/admin/businesses/add'} element={<BusinessAddPage />} />
                    <Route path={'/admin/receivers'} element={<PersonPage type="R" />} />
                    <Route path={'/admin/receivers/:searchParam'} element={<PersonPage type="R" />} />
                    <Route path={'/admin/donors'} element={<PersonPage type="D" />} />
                    <Route path={'/admin/person/:id/edit'} element={<PersonEditPage />} />
                    <Route path={'/admin/person/:id/view'} element={<PersonViewPage />} />
                    <Route path={'/admin/subscription'} element={<SubscriptionPage />} />
                </Routes>
            </ErrorBoundary>
        </>
    );
}

export default PrivateRoutes;
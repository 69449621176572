import React, {FC, useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import clsx from "clsx";
import StateList from "./StateList";
import CityList from "./CityList";
import InputMask from "react-input-mask";
import {useAPI} from "../../hooks/useAPI";
import {AddressType} from "../../types/AddressType";
import {Form} from "react-bootstrap";
import { useIntl } from "react-intl";
import { LANG_CITY, LANG_COMMON_COMPLEMENT, LANG_COMMON_DISTRICT, LANG_COMMON_IS_REQUIRED, LANG_COMMON_NUMBER, LANG_COMMON_STREET, LANG_COMMON_ZIPCODE, LANG_STATE } from "../../lang";

interface Props {
    address?: AddressType
    hasSubmitted: boolean
    onSubmit: (data: AddressType) => void
    onValidate: (status: boolean) => void
}

const AddressForm: FC<Props> = ({address, onSubmit, hasSubmitted, onValidate}) => {
    const intl = useIntl();


    const AddressUpdateSchema = Yup.object().shape({
        zipcode: Yup.string()
            .required(`${intl.formatMessage(LANG_COMMON_ZIPCODE)} ${intl.formatMessage(LANG_COMMON_IS_REQUIRED)}`),
        street: Yup.string()
            .required(`${intl.formatMessage(LANG_COMMON_STREET)} ${intl.formatMessage(LANG_COMMON_IS_REQUIRED)}`),
        number: Yup.string()
            .required(`${intl.formatMessage(LANG_COMMON_NUMBER)} ${intl.formatMessage(LANG_COMMON_IS_REQUIRED)}`),
        district: Yup.string()
            .required(`${intl.formatMessage(LANG_COMMON_DISTRICT)} ${intl.formatMessage(LANG_COMMON_IS_REQUIRED)}`),
        city_id: Yup.string()
            .required(`${intl.formatMessage(LANG_CITY)} ${intl.formatMessage(LANG_COMMON_IS_REQUIRED)}`),
        state_id: Yup.string(),
        city: Yup.object()
    });

    const {api} = useAPI();
    let initialValues = {
        ...address
    }
    initialValues.state_id = initialValues.city?.state_id;

    const formik = useFormik({
        initialValues,
        validationSchema: AddressUpdateSchema,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values: any, {setStatus, setSubmitting}) => {
            onSubmit(values);
        },
    });

    useEffect(() => {
        onValidate(formik.isValid);
    }, [formik.isValid]);

    useEffect(() => {
        if(hasSubmitted){
            formik.validateForm();
            if(formik.isValid){
                onValidate(true);
                onSubmit(formik.values);
            } else {
                let errors = formik.errors;
                let touched = {};
                for(let i in errors) {
                    // @ts-ignore
                    touched[i] = true;
                }
                formik.setTouched(touched);

            }
        }

    }, [hasSubmitted]);

    // Load address by zipcode
    useEffect(() => {
        if(formik.values.zipcode === initialValues.zipcode){return;}
        if(formik.values.zipcode.length >= 8) {
            let zipcode = formik.values.zipcode.replace("-", "");
            if(zipcode.indexOf("_") === -1) {
                api().get(`/api/street/${zipcode}`).then((resp) => {
                    let street = resp.data;
                    formik.setFieldValue('street', street.name);
                    formik.setFieldValue('district', street.district.name);
                    formik.setFieldValue('city_id', street.city_id);
                    formik.setFieldValue('state_id', street.city.state_id);
                    formik.setFieldValue('city', street.city);
                });
            }
        }
    }, [formik.values.zipcode]);

    return (
    <>
        <form onSubmit={formik.handleSubmit}>
            <button type="submit" className={"d-none"} />
            <div className="row mb-3">
                <div className="col-lg-4">
                    <Form.Label>{intl.formatMessage(LANG_COMMON_ZIPCODE)}</Form.Label>
                    <InputMask
                        mask={'99999-999'}
                        type="text"
                        placeholder={intl.formatMessage(LANG_COMMON_ZIPCODE)}
                        {...formik.getFieldProps('zipcode')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.zipcode && formik.errors.zipcode},
                            {
                                'is-valid': formik.touched.zipcode && !formik.errors.zipcode,
                            }
                        )}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-12 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_COMMON_STREET)}</Form.Label>
                    <input
                        type="text"
                        placeholder={intl.formatMessage(LANG_COMMON_STREET)}
                        {...formik.getFieldProps('street')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.street && formik.errors.street},
                            {
                                'is-valid': formik.touched.street && !formik.errors.street,
                            }
                        )}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                    <Form.Label>{intl.formatMessage(LANG_COMMON_NUMBER)}</Form.Label>
                    <input
                        type="text"
                        placeholder={intl.formatMessage(LANG_COMMON_NUMBER)}
                        {...formik.getFieldProps('number')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.number && formik.errors.number},
                            {
                                'is-valid': formik.touched.number && !formik.errors.number,
                            }
                        )}
                    />
                </div>
                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                    <Form.Label>{intl.formatMessage(LANG_COMMON_COMPLEMENT)}</Form.Label>
                    <input
                        type="text"
                        placeholder={intl.formatMessage(LANG_COMMON_COMPLEMENT)}
                        {...formik.getFieldProps('complement')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.complement && formik.errors.complement},
                            {
                                'is-valid': formik.touched.complement && !formik.errors.complement,
                            }
                        )}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-12 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_COMMON_DISTRICT)}</Form.Label>
                    <input
                        type="text"
                        {...formik.getFieldProps('district')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.district && formik.errors.district},
                            {
                                'is-valid': formik.touched.district && !formik.errors.district,
                            }
                        )}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-6 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_STATE)}</Form.Label>
                    <StateList
                        {...formik.getFieldProps('state_id')}
                        onChange={(state_id: string) => {
                            formik.setFieldValue('state_id', state_id);
                        }}
                        className={clsx(
                            'form-select form-select-solid form-select-lg form-control',
                            {'is-invalid': formik.touched.state_id && formik.errors.state_id},
                            {
                                'is-valid': formik.touched.state_id && !formik.errors.state_id,
                            }
                        )}
                    />
                </div>
                <div className="col-lg-6 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_CITY)}</Form.Label>
                    <CityList
                        state_id={formik.values.state_id}
                        {...formik.getFieldProps('city_id')}
                        onChange={(city_id: string) => {
                            formik.setFieldValue('city_id', city_id);
                        }}
                        className={clsx(
                            'form-select form-select-solid form-select-lg form-control',
                            {'is-invalid': formik.touched.city_id && formik.errors.city_id},
                            {
                                'is-valid': formik.touched.city_id && !formik.errors.city_id,
                            }
                        )}
                    />
                </div>
            </div>
        </form>
    </>);
}

export default AddressForm;
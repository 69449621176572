export function AuthReducer(state: any, action: any) {
    switch (action.type) {
        case 'setIsAuth':
            return {...state, isAuth: action.isAuth};
        case 'setTermsAgreed':
            return {...state, termsAgreed: action.termsAgreed};
        case 'setUser':
            return {...state, user: action.user};
        case 'setLoading':
            return {...state, loading: action.loading};
        default:
            return state;
    }
}
import React, { FC, useEffect, useReducer, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import PersonsSelect from "../../components/PersonsSelect";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Confirm from "../../components/messages/Confirm";
import PersonAddModal from "./components/PersonAddModal";
import ClipLoader from "react-spinners/ClipLoader";
import { UserType } from "../../types/UserType";
import BusinessSelect from "../../components/form/BusinessSelect";
import { useAuth } from "../../hooks/useAuth";
import { UserListReducer } from "../../reducers/UserReducer";
import { UserModel } from "../../models/UserModel";
import { toast } from "react-toastify";
import { PersonListReducer } from "../../reducers/PersonReducer";
import { PersonModel } from "../../models/PersonModel";
import { PersonType } from "../../types/PersonType";
import CustomSelect from "../../components/form/CustomSelect";
import { OptionType } from "../../types/CommonType";
import PairingFilters from "../pairing/components/PairingFilters";

import IconDelete from "../../assets/icons/delete.svg";
import IconEdit from "../../assets/icons/edit.svg";
import IconPairing from "../../assets/icons/pairing.svg";
import Error from "../../components/messages/Error";
import TranslateTerms from "../../components/TranslateTerms";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_ALL, LANG_COMMON_AVAILABLE, LANG_COMMON_CHOOSE, LANG_COMMON_CLINIC, LANG_COMMON_DELETE_CONFIRM, LANG_COMMON_DELETE_ERROR, LANG_COMMON_DELETE_SUCCESS, LANG_COMMON_FIND, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEXT, LANG_COMMON_NO_RESULTS, LANG_COMMON_OPTIONS, LANG_COMMON_PREVIOUS, LANG_COMMON_SEARCH, LANG_ROD_AVAILABILITY } from "../../lang";

interface Props {
    type: string
}

const PersonPage: FC<Props> = ({ type }) => {
    const { searchParam } = useParams();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showDraftError, setShowDraftError] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [personToDelete, setPersonToDelete] = useState<undefined | PersonType>(undefined);
    const [searchFromBusiness, setSearchFromBusiness] = useState<number | undefined>(undefined);
    const [showPairingModal, setShowPairingModal] = useState(false);
    const [personToPair, setPersonToPair] = useState(-1);
    const [search, setSearch] = useState<string>((searchParam !== undefined) ? searchParam : "");
    const [filterHasRod, setFilterHasRod] = useState(false);
    const [filterNotPaired, setFilterNotPaired] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLastPage, setIsLastPage] = useState(true);
    const { hasAbility } = useAuth();
    const [data, dispatch] = useReducer(PersonListReducer, {
        persons: []
    });

    const navigate = useNavigate();
    const intl = useIntl();

    const loadPersons = (business_id: number | undefined = undefined, options: any | undefined = undefined) => {
        setLoading(true);
        PersonModel().list(type, business_id, options, pageNumber).then((personList) => {
            if (typeof personList !== 'boolean') {
                dispatch({ type: 'setPersons', persons: personList.data });
                setIsLastPage(personList.last_page === pageNumber);
                setLoading(false);
            }
        });
    }
    useEffect(() => {
        loadPersons(searchFromBusiness, { search: search, hasRod: filterHasRod, notPaired: filterNotPaired });
    }, []);

    useEffect(() => {
        loadPersons(searchFromBusiness, { search: search, hasRod: filterHasRod, notPaired: filterNotPaired });
    }, [pageNumber]);

    useEffect(() => {
        let searchQuery = search;
        if (type !== "R") {
            setSearch("");
            searchQuery = "";
        }
        setTimeout(() => {
            loadPersons(searchFromBusiness, { search: searchQuery, hasRod: filterHasRod, notPaired: filterNotPaired });
        }, 100);
    }, [type]);

    return (
        <>
            {showDraftError && (
                <Error message={intl.formatMessage({
                    id: "person.error.draft",
                    defaultMessage: "Por favor, complete o cadastro para continuar!",
                    description: "Mensagem de erro ao tentar parear com cadastro incompleto."
                })} show={showDraftError} onHide={() => setShowDraftError(false)} />
            )}
            <Confirm
                message={intl.formatMessage(LANG_COMMON_DELETE_CONFIRM)}
                show={showConfirm}
                onConfirm={async () => {
                    setShowConfirm(false);
                    const id = toast.loading(intl.formatMessage(LANG_COMMON_LOADING));
                    let result = false;
                    if (personToDelete !== undefined) {
                        result = await PersonModel().remove(personToDelete?.id);
                    } else {
                        return;
                    }

                    toast.dismiss(id);
                    if (result) {
                        toast.success(intl.formatMessage(LANG_COMMON_DELETE_SUCCESS));
                        dispatch({ type: 'removePerson', person_id: personToDelete?.id });
                    } else {
                        toast.error(`${intl.formatMessage(LANG_COMMON_DELETE_ERROR)}: ${personToDelete?.name}!`, {
                            hideProgressBar: true,
                            autoClose: false
                        });
                    }
                }}
                onHide={() => setShowConfirm(false)}
            />
            <PersonAddModal
                show={showAddModal}
                onHide={() => setShowAddModal(false)}
                onSave={(person) => {
                    navigate(`/admin/person/${person.id}/edit`);
                }}
                type={type} />
            <PairingFilters show={showPairingModal} onHide={() => setShowPairingModal(false)} onSave={(filters) => {
                navigate(`/admin/pairing/${personToPair}/add?${new URLSearchParams(filters).toString()}`);
            }} />
            <div className="bg-pattern">
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item active>{type === "R" ? <TranslateTerms term="receptors" /> : <TranslateTerms term="donors" />}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 0 }}>
                            <Card>
                                <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                    <div className="card-title h5 w-100">
                                        <div className="row">
                                            <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                {type === "R" ? <TranslateTerms term="receptors" /> : <TranslateTerms term="donors" />} {" "}
                                            </div>
                                            <div className="text-end col-md-6">
                                                <a
                                                    href="#"
                                                    className="btn-plus"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowAddModal(true)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <div className="card-body" style={{ 'padding': '0' }}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage {...LANG_COMMON_MEDICAL_RECORD_NUMBER} /></th>
                                                <th><FormattedMessage {...LANG_COMMON_NAME} /></th>
                                                {hasAbility("pairing-details") && (<th>Data Cadastro</th>)}
                                                <th><FormattedMessage {...LANG_COMMON_OPTIONS} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading && (
                                                <tr>
                                                    <td colSpan={4} className={'text-center'}>
                                                        <ClipLoader color={'#0d6efd'} loading={loading} size={'1rem'} />{" "}<FormattedMessage {...LANG_COMMON_LOADING} />
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && data.persons.length === 0 && (
                                                <tr>
                                                    <td colSpan={hasAbility("pairing-details") ? 5 : 4} className={"text-center"}>
                                                        <FormattedMessage {...LANG_COMMON_NO_RESULTS} />
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && (
                                                <>
                                                    {data.persons.map((item: PersonType) => (
                                                        <tr key={item.id}>
                                                            <td>{item.medical_record_number}</td>
                                                            <td>{item.name}</td>
                                                            {hasAbility("pairing-details") && (<td>{moment(item.created_at).format('DD/MM/YYYY')}</td>)}
                                                            <td>
                                                                <Link to={`/admin/person/${item.id}/view`}>
                                                                    <i className="bi bi-search icon" style={{ "color": "#25325b" }} />
                                                                </Link>
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (!item.is_draft) {
                                                                        setPersonToPair(item.id);
                                                                        setShowPairingModal(true);
                                                                    } else {
                                                                        setShowDraftError(true);
                                                                    }
                                                                }}><i className="icon icon-pairing">
                                                                        <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px" y="0px"
                                                                            viewBox="0 0 20.19 19.64">
                                                                            <g>
                                                                                <path className="st0" d="M17.13,5.35L15.08,7.4l-0.03,0.04c-0.03,0.03-0.04,0.04-0.06,0.08c-0.34,0.48-0.05,1.29,0.58,1.4
                                        c0.28,0.05,0.57-0.05,0.78-0.25l3.58-3.58c0.17-0.18,0.27-0.43,0.26-0.68c-0.02-0.22-0.11-0.42-0.26-0.58l-3.58-3.58
                                        c-0.03-0.03-0.04-0.04-0.07-0.07c-0.39-0.31-1.04-0.21-1.32,0.22c-0.21,0.34-0.16,0.81,0.13,1.11l2.05,2.05H0.89
                                        C0.55,3.6,0.23,3.76,0.08,4.07c-0.18,0.36-0.07,0.84,0.25,1.09c0.16,0.13,0.36,0.17,0.56,0.2L17.13,5.35L17.13,5.35z M3.05,16.09
                                        h16.24c0.33-0.04,0.66-0.2,0.81-0.51c0.08-0.16,0.07-0.21,0.09-0.39c-0.01-0.07-0.02-0.13-0.02-0.2c-0.04-0.13-0.09-0.25-0.17-0.36
                                        c-0.13-0.16-0.31-0.27-0.5-0.31c-0.06-0.02-0.13-0.02-0.2-0.02H3.05l2.05-2.05c0,0,0.27-0.35,0.26-0.68c0-0.04,0-0.06-0.01-0.1
                                        c-0.08-0.49-0.64-0.85-1.13-0.71c-0.1,0.03-0.19,0.07-0.27,0.13c-0.04,0.03-0.08,0.06-0.12,0.1l-3.58,3.58
                                        c-0.03,0.03-0.04,0.04-0.07,0.08c-0.25,0.31-0.25,0.8,0,1.12c0.03,0.03,0.04,0.04,0.07,0.08l3.58,3.58
                                        c0.03,0.03,0.04,0.04,0.08,0.07c0.47,0.38,1.33,0.12,1.44-0.55c0.05-0.28-0.05-0.57-0.25-0.78L3.05,16.09L3.05,16.09z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </i></a>
                                                                {" "}
                                                                <Link to={`/admin/person/${item.id}/edit`}>
                                                                    <i className="icon icon-edit">
                                                                        <svg version="1.1" id="Camada_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px" y="0px"
                                                                            viewBox="0 0 15.76 15.8">
                                                                            <g>
                                                                                <path className="st0" d="M14.29,6.03c-0.2,0-0.39,0.08-0.53,0.22c-0.14,0.14-0.22,0.33-0.22,0.53v6.77c0,0.2-0.08,0.39-0.22,0.53
                                                                            s-0.33,0.22-0.53,0.22H2.26c-0.2,0-0.39-0.08-0.53-0.22S1.5,13.74,1.5,13.54V3.02c0-0.2,0.08-0.39,0.22-0.53s0.33-0.22,0.53-0.22
                                                                            h6.77c0.27,0,0.52-0.14,0.65-0.38c0.13-0.23,0.13-0.52,0-0.75C9.54,0.91,9.29,0.76,9.02,0.76H2.26c-0.6,0-1.17,0.24-1.6,0.66
                                                                            C0.24,1.85,0,2.42,0,3.02v10.53c0,0.6,0.24,1.17,0.66,1.6c0.42,0.42,1,0.66,1.6,0.66h10.53c0.6,0,1.17-0.24,1.6-0.66
                                                                            c0.42-0.42,0.66-1,0.66-1.6V6.78c0-0.2-0.08-0.39-0.22-0.53C14.68,6.11,14.49,6.03,14.29,6.03z M15.42,1.67l-1.29-1.29
                                                                            c-0.28-0.28-0.68-0.42-1.08-0.37c-0.36,0.05-0.7,0.21-0.95,0.47L6.77,5.84C6.62,5.99,6.49,6.16,6.41,6.36L5.41,8.61
                                                                            C5.3,8.86,5.26,9.13,5.3,9.4c0.04,0.27,0.17,0.52,0.35,0.71c0.24,0.24,0.57,0.38,0.91,0.38c0.21,0,0.42-0.04,0.61-0.13l2.26-0.99
                                                                            c0.19-0.08,0.37-0.2,0.52-0.35l5.34-5.34c0.28-0.25,0.45-0.6,0.47-0.98C15.78,2.33,15.66,1.96,15.42,1.67L15.42,1.67z M14.24,2.65
                                                                            L8.89,7.99C8.88,8,8.86,8.02,8.84,8.03L6.95,8.85L7.81,6.9l5.34-5.34l1.14,1.08L14.24,2.65z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </Link>
                                                                {" "}
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowConfirm(true);
                                                                    setPersonToDelete(item);
                                                                }}><i className="icon icon-delete">
                                                                        <svg version="1.1" id="Camada_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px" y="0px"
                                                                            viewBox="0 0 13.73 17.23">
                                                                            <g>
                                                                                <path className="st2" d="M11.74,3.1l-0.46,12.49c-0.01,0.21-0.2,0.4-0.41,0.4H2.87c-0.21,0-0.4-0.18-0.41-0.4L2,3.1
                                                                                    c-0.01-0.34-0.3-0.61-0.65-0.6c-0.34,0.01-0.61,0.3-0.6,0.65l0.46,12.49c0.03,0.88,0.77,1.6,1.66,1.6h7.99
                                                                                    c0.89,0,1.63-0.71,1.66-1.6l0.46-12.49C13,2.8,12.73,2.51,12.38,2.5C12.04,2.48,11.75,2.75,11.74,3.1L11.74,3.1z"/>
                                                                                <path className="st2" d="M6.37,5.62v8.49c0,0.28,0.22,0.5,0.5,0.5s0.5-0.22,0.5-0.5V5.62c0-0.28-0.22-0.5-0.5-0.5S6.37,5.34,6.37,5.62
                                                                                    L6.37,5.62z"/>
                                                                                <path className="st2" d="M3.62,5.63l0.25,8.49c0.01,0.28,0.24,0.49,0.51,0.48c0.28-0.01,0.49-0.24,0.48-0.51L4.62,5.6
                                                                                    C4.61,5.33,4.38,5.11,4.11,5.12C3.83,5.13,3.61,5.36,3.62,5.63L3.62,5.63z"/>
                                                                                <path className="st2" d="M9.11,5.6l-0.25,8.49c-0.01,0.28,0.21,0.51,0.48,0.51c0.28,0.01,0.51-0.21,0.51-0.48l0.25-8.49
                                                                                    c0.01-0.28-0.21-0.51-0.48-0.51C9.35,5.11,9.12,5.33,9.11,5.6L9.11,5.6z"/>
                                                                                <path className="st2" d="M0.62,3.37h12.49c0.34,0,0.62-0.28,0.62-0.62c0-0.34-0.28-0.62-0.62-0.62H0.62C0.28,2.12,0,2.4,0,2.75
                                                                                    C0,3.09,0.28,3.37,0.62,3.37z"/>
                                                                                <path className="st2" d="M4.97,2.54l0.26-0.91c0.06-0.19,0.31-0.39,0.51-0.39h2.25c0.2,0,0.46,0.19,0.51,0.39l0.26,0.91l1.2-0.34
                                                                                    L9.7,1.29C9.5,0.56,8.75,0,7.99,0H5.74c-0.76,0-1.5,0.56-1.71,1.29L3.77,2.2L4.97,2.54z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </i></a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row className="mt-2">
                                    <Col lg={{ span: 6 }}>
                                        <button
                                            type="button"
                                            className="btn-custom btn-custom-light btn-custom-small text-uppercase btn btn-outline-light btn-lg"
                                            disabled={pageNumber === 1}
                                            onClick={() => setPageNumber(pageNumber - 1)}
                                        >&laquo; <FormattedMessage {...LANG_COMMON_PREVIOUS} /></button>
                                    </Col>
                                    <Col lg={{ span: 6 }} className="d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn-custom btn-custom-light btn-custom-small text-uppercase btn btn-outline-light btn-lg"
                                            disabled={isLastPage}
                                            onClick={() => setPageNumber(pageNumber + 1)}
                                        ><FormattedMessage {...LANG_COMMON_NEXT} /> &raquo;</button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            <Card>
                                <CardHeader>
                                    <Card.Title><FormattedMessage {...LANG_COMMON_OPTIONS} /></Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        loadPersons(searchFromBusiness, { search: search, hasRod: filterHasRod, notPaired: filterNotPaired });
                                    }}>
                                        {hasAbility('BUSINESS_LIST') && (
                                            <>
                                                <label htmlFor="exampleInputEmail1" className="form-label"><FormattedMessage {...LANG_COMMON_CLINIC} /></label>
                                                <BusinessSelect
                                                    onChange={(business_id) => {
                                                        setSearchFromBusiness(business_id);
                                                        loadPersons(business_id, { search: search, hasRod: filterHasRod, notPaired: filterNotPaired });
                                                    }}
                                                />

                                                <br />
                                            </>
                                        )}
                                        {type === "D" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    <FormattedMessage {...LANG_ROD_AVAILABILITY}/>
                                                </Form.Label>
                                                <CustomSelect
                                                    name="has_lab_rod"
                                                    options={[
                                                        {
                                                            label: <FormattedMessage {...LANG_COMMON_ALL} />, value: false
                                                        },
                                                        { label: <FormattedMessage {...LANG_COMMON_AVAILABLE} />, value: true },
                                                    ]}
                                                    onChange={(option: OptionType) => {
                                                        setFilterHasRod(option.value);
                                                        loadPersons(searchFromBusiness, { search: search, hasRod: option.value, notPaired: filterNotPaired });
                                                    }}
                                                    value={filterHasRod}
                                                    placeholder={`${<FormattedMessage {...LANG_COMMON_CHOOSE} />}...`}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />
                                            </Form.Group>
                                        )}
                                        {type === "R" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label><FormattedMessage
                                                    id="receptor.pairing_status"
                                                    defaultMessage="Situação do pareamento:"
                                                /></Form.Label>
                                                <CustomSelect
                                                    name="not_has_pairing"
                                                    options={[
                                                        {
                                                            label: <FormattedMessage {...LANG_COMMON_ALL} />, value: false
                                                        },
                                                        {
                                                            label: <FormattedMessage
                                                                id="receptor.pairing_status.not_paired"
                                                                defaultMessage="Sem pareamento"
                                                            />, value: true
                                                        }
                                                    ]}
                                                    onChange={(option: OptionType) => {
                                                        setFilterNotPaired(option.value);
                                                        loadPersons(searchFromBusiness, { search: search, hasRod: option.value, notPaired: option.value });
                                                    }}
                                                    value={filterNotPaired}
                                                    placeholder={`${<FormattedMessage {...LANG_COMMON_CHOOSE} />}...`}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />
                                            </Form.Group>
                                        )}
                                        <Form.Group className="mb-3">
                                            <Form.Label><FormattedMessage {...LANG_COMMON_FIND} /></Form.Label>
                                            <Form.Control
                                                placeholder={intl.formatMessage(LANG_COMMON_MEDICAL_RECORD_NUMBER)}
                                                onChange={(e) => setSearch(e.target.value)}
                                                value={search}
                                            />
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <div className="row">
                                                <div className="col-md-8 offset-2">
                                                    <Button variant="primary" size={'lg'}
                                                        type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100"><FormattedMessage {...LANG_COMMON_SEARCH} /></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PersonPage;
import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import PersonsSelect from "../../../components/PersonsSelect";
import { PersonType } from "../../../types/PersonType";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import CustomSelect from "../../../components/form/CustomSelect";
import { PersonModel } from "../../../models/PersonModel";
import { OptionType } from "../../../types/CommonType";
import ImageUploader from "../../../components/ImageUploader";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import { useConfig } from "../../../hooks/useConfig";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import moment from "moment";
import StateList from "../../../components/form/StateList";
import CityList from "../../../components/form/CityList";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_CITY, LANG_COMMON_CHOOSE, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_DISTRICT, LANG_PERSON_AGE, LANG_PERSON_BIRTH_DATE, LANG_PERSON_BLOOD_TYPE, LANG_PERSON_BMI, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_OTHER_INFO, LANG_PERSON_OTHER_INFO_DETAILS, LANG_PERSON_PICTURE, LANG_PERSON_PICTURE_KID, LANG_PERSON_RACE, LANG_PERSON_RH_FACTOR, LANG_PERSON_SKIN_COLOR, LANG_PERSON_WEIGHT, LANG_STATE } from "../../../lang";

interface Props {
    person: PersonType
    onSubmit: (values: PersonType) => void
    onUpdate?: (person: PersonType) => void
    onValidationError?: () => void
    hasSubmitted: boolean
}

const PersonForm: FC<Props> = ({ person, onSubmit, onUpdate = undefined, hasSubmitted, onValidationError = () => { } }) => {
    const intl = useIntl();
    const [imc, setImc] = useState("0.00");
    const options = useContext(OptionsContext);

    const calculateAge = (birth_date: string) => {
        const moment_birth_date = moment(birth_date, 'DD/MM/YYYY');
        if (moment_birth_date.isValid()) {
            return moment().diff(moment_birth_date, 'years');
        }
        return 0;
    }
    const [age, setAge] = useState((person.birth_date !== undefined) ? calculateAge(person.birth_date) : 0);

    const calculateBmi = (height: string | undefined, weight: string | undefined) => {
        if (weight !== undefined && height !== undefined)
            return (parseFloat(weight) / Math.pow(parseFloat(height), 2)).toFixed(2);

        return undefined
    }

    const updateImc = (height: string | undefined, weight: string | undefined) => {
        const bmi = calculateBmi(height, weight);
        if (bmi !== 'NaN' && bmi !== undefined) {
            setImc(bmi);
        }
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        medical_record_number: yup.string().required(),
        skin_color: yup.string().required().nullable(),
        eyes_color: yup.string().required().nullable(),
        hair_color: yup.string().required().nullable(),
        hair_type: yup.string().required().nullable(),
        height_number: yup.number().required().nullable(),
        weight: yup.number().required().nullable(),
        blood_type: yup.string().required().nullable(),
        rh_factor: yup.string().required().nullable(),
        race: yup.string().required().nullable(),
        birth_date: yup.string().required(),
        notes: yup.string().nullable(),
        state_id: yup.string().nullable(),
        city_id: yup.string().required(),
        district: yup.string().required()
    });

    const formik = useFormik({
        initialValues: { ...person, birth_date: (person.birth_date === null) ? "" : person.birth_date },
        validationSchema: schema,
        onSubmit: () => { },
        validateOnMount: false
    });

    useEffect(() => {
        updateImc(person.height_number, person.weight);
    }, [person]);

    useEffect(() => {
        if (onUpdate !== undefined)
            onUpdate(formik.values);
    }, [formik.values]);

    useEffect(() => {
        if (hasSubmitted) {
            for (let field in formik.values) {
                formik.setFieldTouched(field, true);
            }

            formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                    onSubmit(formik.values)
                } else {
                    onValidationError();
                }
            });
        }
    }, [hasSubmitted]);

    return (
        <>
            <Row>
                <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <ImageUploadProvider url={`/api/person/${person.id}/photo`} onChange={() => { }}>
                            <ImageUploadProviderContext.Consumer>
                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                    <ImageUploader
                                        photoLabel={`${intl.formatMessage(LANG_PERSON_PICTURE)}`}
                                        hasUploadError={hasUploadError}
                                        hasDownloadError={hasDownloadError}
                                        uploadErrorMessage={uploadErrorMessage}
                                        download={download}
                                        loading={loading}
                                        height={175}
                                        width={145}
                                        url={url}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        uploadUrl={`/api/person/${person.id}/photo`}
                                        removeUrl={""} />
                                )}
                            </ImageUploadProviderContext.Consumer>
                        </ImageUploadProvider>
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <ImageUploadProvider url={`/api/person/${person.id}/photo/child`} onChange={() => { }}>
                            <ImageUploadProviderContext.Consumer>
                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                    <ImageUploader
                                        photoLabel={`${intl.formatMessage(LANG_PERSON_PICTURE_KID)}`}
                                        hasUploadError={hasUploadError}
                                        uploadErrorMessage={uploadErrorMessage}
                                        hasDownloadError={hasDownloadError}
                                        download={download}
                                        loading={loading}
                                        height={175}
                                        width={145}
                                        url={url}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        uploadUrl={`/api/person/${person.id}/photo/child`}
                                        removeUrl={""} />
                                )}
                            </ImageUploadProviderContext.Consumer>
                        </ImageUploadProvider>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_COMMON_NAME)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder={`${intl.formatMessage(LANG_COMMON_NAME)}`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            isValid={formik.touched.name && !formik.errors.name}
                            isInvalid={!!formik.errors.name && formik.touched.name}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_COMMON_MEDICAL_RECORD_NUMBER)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="medical_record_number"
                            placeholder={`${intl.formatMessage(LANG_COMMON_MEDICAL_RECORD_NUMBER)}`}
                            value={formik.values.medical_record_number}
                            onChange={formik.handleChange}
                            isValid={formik.touched.medical_record_number && !formik.errors.medical_record_number}
                            isInvalid={!!formik.errors.medical_record_number && formik.touched.medical_record_number}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_BLOOD_TYPE)}</Form.Label>
                        <CustomSelect
                            name="blood_type"
                            options={PersonModel().getBloodTypes()}
                            onChange={(option: OptionType) => formik.setFieldValue("blood_type", option.value)}
                            value={formik.values.blood_type}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.blood_type}
                            isValid={formik.touched.blood_type && !formik.errors.blood_type}
                        />
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_RH_FACTOR)}</Form.Label>
                        <CustomSelect
                            name="rh_factor"
                            options={options.personOptions?.getRHFactorTypes()}
                            onChange={(option: OptionType) => formik.setFieldValue("rh_factor", option.value)}
                            value={formik.values.rh_factor}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.rh_factor}
                            isValid={formik.touched.rh_factor && !formik.errors.rh_factor}
                        />
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_RACE)}</Form.Label>
                        <CustomSelect
                            name="race"
                            options={options.personOptions?.getRaceTypes()}
                            onChange={(option: OptionType) => formik.setFieldValue("race", option.value)}
                            value={formik.values.race}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.race}
                            isValid={formik.touched.race && !formik.errors.race}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_HEIGHT)}</Form.Label>
                        <Form.Control
                            type="number"
                            step=".05"
                            name="height_number"
                            placeholder={`${intl.formatMessage(LANG_PERSON_HEIGHT)}`}
                            value={formik.values.height_number}
                            onChange={(e) => {
                                formik.handleChange(e);
                                updateImc(e.target.value, formik.values.weight);
                            }}
                            isValid={formik.touched.height_number && !formik.errors.height_number}
                            isInvalid={!!formik.errors.height_number && formik.touched.height_number}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_WEIGHT)}</Form.Label>
                        <Form.Control
                            type="number"
                            step=".5"
                            name="weight"
                            placeholder={`${intl.formatMessage(LANG_PERSON_WEIGHT)}`}
                            value={formik.values.weight}
                            onChange={(e) => {
                                formik.handleChange(e);
                                updateImc(formik.values.height_number, e.target.value);
                            }}
                            isValid={formik.touched.weight && !formik.errors.weight}
                            isInvalid={!!formik.errors.weight && formik.touched.weight}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_BMI)}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`${intl.formatMessage(LANG_PERSON_BMI)}`}
                            value={imc}
                            disabled={true}
                        />
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_BIRTH_DATE)}</Form.Label>
                        <InputMask
                            name={"birth_date"}
                            type="text"
                            mask="99/99/9999"
                            value={formik.values.birth_date}
                            onChange={(e) => {
                                if (e.target.value !== null) {
                                    formik.setFieldValue("birth_date", e.target.value);
                                    setAge(calculateAge(e.target.value));
                                }
                            }}
                            className={clsx("form-control", {
                                "is-invalid": formik.touched.birth_date && formik.errors.birth_date,
                                "is-valid": !formik.errors.birth_date && formik.touched.birth_date
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_AGE)}</Form.Label>
                        <Form.Control type="text" placeholder="" disabled={true} value={age} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_SKIN_COLOR)}</Form.Label>
                        <CustomSelect
                            name="skin_color"
                            options={options.personOptions?.getSkinColors()}
                            onChange={(option: OptionType) => formik.setFieldValue("skin_color", option.value)}
                            value={formik.values.skin_color}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.skin_color}
                            isValid={formik.touched.skin_color && !formik.errors.skin_color}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_COLOR)}</Form.Label>
                        <CustomSelect
                            name="hair_color"
                            options={options.personOptions?.getHairColors()}
                            onChange={(option: OptionType) => formik.setFieldValue("hair_color", option.value)}
                            value={formik.values.hair_color}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.hair_color}
                            isValid={formik.touched.hair_color && !formik.errors.hair_color}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_TYPE)}</Form.Label>
                        <CustomSelect
                            name="hair_type"
                            options={options.personOptions?.getHairTypes()}
                            onChange={(option: OptionType) => formik.setFieldValue("hair_type", option.value)}
                            value={formik.values.hair_type}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.hair_type}
                            isValid={formik.touched.hair_type && !formik.errors.hair_type}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_EYES_COLOR)}</Form.Label>
                        <CustomSelect
                            name="eyes_color"
                            options={options.personOptions?.getEyesColors()}
                            onChange={(option: OptionType) => formik.setFieldValue("eyes_color", option.value)}
                            value={formik.values.eyes_color}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.eyes_color}
                            isValid={formik.touched.eyes_color && !formik.errors.eyes_color}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <div className="col-lg-6 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_STATE)}</Form.Label>
                    <StateList
                        {...formik.getFieldProps('state_id')}
                        onChange={(state_id: string) => {
                            formik.setFieldValue('state_id', state_id);
                        }}
                        className={clsx(
                            'form-select form-select-solid form-select-lg form-control',
                            { 'is-invalid': formik.touched.state_id && formik.errors.state_id },
                            {
                                'is-valid': formik.touched.state_id && !formik.errors.state_id,
                            }
                        )}
                    />
                </div>
                <div className="col-lg-6 fv-row">
                    <Form.Label>{intl.formatMessage(LANG_CITY)}</Form.Label>
                    <CityList
                        state_id={formik.values.state_id}
                        {...formik.getFieldProps('city_id')}
                        onChange={(city_id: string) => {
                            formik.setFieldValue('city_id', city_id);
                        }}
                        className={clsx(
                            'form-select form-select-solid form-select-lg form-control',
                            { 'is-invalid': formik.touched.city_id && formik.errors.city_id },
                            {
                                'is-valid': formik.touched.city_id && !formik.errors.city_id,
                            }
                        )}
                    />
                </div>
                <div className="col-lg-6 fv-row">
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_DISTRICT)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="district"
                            placeholder={`${intl.formatMessage(LANG_DISTRICT)}`}
                            value={formik.values.district}
                            onChange={formik.handleChange}
                            isValid={formik.touched.district && !formik.errors.district}
                            isInvalid={!!formik.errors.district && formik.touched.district}
                        />
                    </Form.Group>
                </div>
            </Row>
            <Row>
                <Col lg={12}>
                    <Form.Label>
                        <FormattedMessage
                            {...LANG_PERSON_OTHER_INFO}
                        />
                        <small>
                            {" "}
                            <FormattedMessage
                                {...LANG_PERSON_OTHER_INFO_DETAILS}
                            />
                        </small></Form.Label>
                    <textarea name="notes" rows={5} className="form-control" onChange={formik.handleChange} value={formik.values.notes} />
                </Col>
            </Row>
        </>
    );
}

export default PersonForm;
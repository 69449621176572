import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import PersonForm from "./components/PersonForm";
import { PersonModel } from "../../models/PersonModel";
import { PersonFile, PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import RodTable from "./components/RodTable";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import InputMask from "react-input-mask";
import clsx from "clsx";
import moment from "moment";
import { useStateList } from "../../hooks/useStateList";
import TranslateTerms from "../../components/TranslateTerms";
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { LANG_CITY, LANG_COMMON_BACK, LANG_COMMON_DATA, LANG_COMMON_FILES, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_OTHER_DATA, LANG_COMMON_YES, LANG_PERSON_AGE, LANG_PERSON_BIRTH_DATE, LANG_PERSON_BLOOD_TYPE, LANG_PERSON_BMI, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HEIGHT, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_OTHER_INFO, LANG_PERSON_OTHER_INFO_DETAILS, LANG_PERSON_PICTURE, LANG_PERSON_PICTURE_KID, LANG_PERSON_RACE, LANG_PERSON_SCHOOLING, LANG_PERSON_SKIN_COLOR, LANG_PERSON_WEIGHT, LANG_STATE } from "../../lang";

const PersonViewPage = () => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [person, setPerson] = useState<PersonType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { getStateName, getStateShort } = useStateList();
    const [files, setFiles] = useState<PersonFile[] | undefined>([]);

    const calculateAge = (birth_date: string | undefined) => {
        if (birth_date === undefined) return "0";
        const moment_birth_date = moment(birth_date, 'DD/MM/YYYY');
        if (moment_birth_date.isValid()) {
            return moment().diff(moment_birth_date, 'years');
        }
        return 0;
    }

    const calculateBmi = (height: string | undefined, weight: string | undefined) => {
        if (weight !== undefined && height !== undefined)
            return (parseFloat(weight) / Math.pow(parseFloat(height), 2)).toFixed(2);

        return undefined
    }

    const loadPerson = () => {
        setLoading(true);
        if (id !== undefined) {
            PersonModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    formik.setFieldValue("schooling", resp.schooling);
                    formik.setFieldValue("has_children", resp.has_children);
                    formik.setFieldValue("hobbies", resp.hobbies);
                    setPerson(resp);
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const loadFiles = () => {
        setLoading(true);
        if (id !== undefined) {
            PersonModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const schema = yup.object().shape({
        schooling: yup.string().nullable(),
        has_children: yup.boolean().nullable(),
        hobbies: yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            schooling: "",
            has_children: undefined,
            hobbies: ""
        },
        validationSchema: schema,
        onSubmit: () => { },
        validateOnMount: false
    });

    useEffect(() => {
        loadPerson();
    }, [id]);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {person && (
                <div className="bg-pattern">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        <Row>
                            <Breadcrumb>
                                {person.type === "R" && (
                                    <>
                                        <Breadcrumb.Item><Link to={'/admin/receivers'}><TranslateTerms term="receptor" /></Link></Breadcrumb.Item>
                                    </>
                                )}
                                {person.type === "D" && (
                                    <>
                                        <Breadcrumb.Item><Link to={'/admin/donors'}><TranslateTerms term="donor" /></Link></Breadcrumb.Item>
                                    </>
                                )}
                                <Breadcrumb.Item active>{person.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{ offset: 0 }}>
                                <Card>
                                    <CardHeader className={'d-flex'}>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_DATA)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5 className="person-name">{person.name}</h5>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <ImageUploadProvider url={`/api/person/${person.id}/photo`} onChange={() => { }}>
                                                            <ImageUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download }) => (
                                                                    <ImageUploader
                                                                        photoLabel={`${intl.formatMessage(LANG_PERSON_PICTURE)}`}
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={hasDownloadError}
                                                                        download={download}
                                                                        loading={loading}
                                                                        edit={false}
                                                                        height={175}
                                                                        width={145}
                                                                        url={url}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={`/api/person/${person.id}/photo`}
                                                                        removeUrl={""} />
                                                                )}
                                                            </ImageUploadProviderContext.Consumer>
                                                        </ImageUploadProvider>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <ImageUploadProvider url={`/api/person/${person.id}/photo/child`} onChange={() => { }}>
                                                            <ImageUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download }) => (
                                                                    <ImageUploader
                                                                        photoLabel={`${intl.formatMessage(LANG_PERSON_PICTURE_KID)}`}
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={hasDownloadError}
                                                                        download={download}
                                                                        loading={loading}
                                                                        edit={false}
                                                                        height={175}
                                                                        width={145}
                                                                        url={url}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={`/api/person/${person.id}/photo/child`}
                                                                        removeUrl={""} />
                                                                )}
                                                            </ImageUploadProviderContext.Consumer>
                                                        </ImageUploadProvider>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_COMMON_NAME)}</Form.Label>
                                                        <div className="view-data">{person.name}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_COMMON_MEDICAL_RECORD_NUMBER)}</Form.Label>
                                                        <div className="view-data">{person.medical_record_number}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_BLOOD_TYPE)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.blood_type, PersonModel().getBloodTypes())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_RACE)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.race, options.personOptions?.getRaceTypes())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HEIGHT)}</Form.Label>
                                                        <div className="view-data">{person.height_number}m</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_WEIGHT)}</Form.Label>
                                                        <div className="view-data">{person.weight}kg</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_BMI)}</Form.Label>
                                                        <div className="view-data">{calculateBmi(person.height_number, person.weight)}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_BIRTH_DATE)}</Form.Label>
                                                        <div className="view-data">{person.birth_date}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={2}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_AGE)}</Form.Label>
                                                        <div className="view-data">{calculateAge(person.birth_date)} anos</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_SKIN_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.skin_color, options.personOptions?.getSkinColors())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.hair_color, options.personOptions?.getHairColors())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_TYPE)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.hair_type, options.personOptions?.getHairTypes())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_EYES_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(person.eyes_color, options.personOptions?.getEyesColors())?.label}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_STATE)}</Form.Label>
                                                        <div className="view-data">{getStateName(person.state_id)}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_CITY)}</Form.Label>
                                                        <div className="view-data">{person.city?.name}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label>
                                                        <FormattedMessage
                                                            {...LANG_PERSON_OTHER_INFO}
                                                        />
                                                        <small>
                                                            {" "}
                                                            <FormattedMessage
                                                                {...LANG_PERSON_OTHER_INFO_DETAILS}
                                                            />
                                                        </small></Form.Label>
                                                    <div className="view-data">{person.notes}</div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                {person.type === "D" && (
                                    <div className="my-4">
                                        {id !== undefined && (<RodTable personId={parseInt(id)} person={person} edit={false} />)}
                                    </div>
                                )}
                                <Card className="my-4">
                                    <CardHeader className={'d-flex'}>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_OTHER_DATA)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_SCHOOLING)}</Form.Label>
                                                    <div className="view-data">{PersonModel().getOption(person.schooling, options.personOptions?.getSchooling())?.label}</div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_HAS_CHILDREN)}</Form.Label>
                                                    <Row>
                                                        <Col lg={3}>
                                                            <div className="view-data">{(person.has_children) ? intl.formatMessage(LANG_COMMON_YES) : intl.formatMessage(LANG_COMMON_NO)}</div>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_OCCUPATION)}</Form.Label>
                                                    <div className="view-data">{person.occupation}</div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>{intl.formatMessage(LANG_PERSON_HOBBIES)}</Form.Label>
                                                    <div className="view-data">{person.hobbies}</div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={{ span: 3 }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_FILES)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {files?.map((personFile, index) => (
                                                    <>
                                                        <FileUploadProvider url={`/api/person/${id}/file/${personFile.file_id}`} onChange={loadFiles} key={personFile.file_id}>
                                                            <FileUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                                    <FileUploader
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={false}
                                                                        uploadErrorMessage={uploadErrorMessage}
                                                                        download={download}
                                                                        loading={loading}
                                                                        url={url}
                                                                        edit={false}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={``}
                                                                        removeUrl={`/api/person/${id}/file/${personFile.file_id}`}
                                                                        remove={true}
                                                                        name={personFile.file.name}
                                                                    />
                                                                )}
                                                            </FileUploadProviderContext.Consumer>
                                                        </FileUploadProvider>
                                                        <hr />
                                                    </>
                                                ))}
                                            </div>
                                            <div className="col-sm-12">
                                                <FileUploadProvider url={undefined} onChange={() => { }} onDone={loadFiles}>
                                                    <FileUploadProviderContext.Consumer>
                                                        {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                            <FileUploader
                                                                hasUploadError={hasUploadError}
                                                                hasDownloadError={false}
                                                                uploadErrorMessage={uploadErrorMessage}
                                                                download={download}
                                                                loading={loading}
                                                                url={url}
                                                                onChange={onChange}
                                                                onRemove={onRemove}
                                                                uploadUrl={`/api/person/${person.id}/file`}
                                                                removeUrl={""} />
                                                        )}
                                                    </FileUploadProviderContext.Consumer>
                                                </FileUploadProvider>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="my-4">
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-8 offset-2">
                                                {person.type === "R" && (
                                                    <Link to="/admin/receivers">
                                                        <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                    </Link>
                                                )}
                                                {person.type === "D" && (
                                                    <Link to="/admin/donors">
                                                        <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default PersonViewPage;
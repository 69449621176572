import React, {useContext, useState} from "react";
import './styles/auth.css';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../providers/AuthContextProvider";
import {useAPI} from "../../hooks/useAPI";
import {useAuth} from "../../hooks/useAuth";
import {Alert} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Google2faModal from "./components/Google2faModal";
import Google2faOTPModal from "./components/Google2faOTPModal";
import BackgroundImage from "./assets/bg-login.png";
import Logo from "./assets/logo.png";
import Error from "../../components/messages/Error";
import { useIntl } from "react-intl";
import { LANG_AUTH_CREATE_ACCOUNT, LANG_AUTH_DO_LOGIN, LANG_AUTH_FORGET_PASSWORD, LANG_COMMON_LOGIN, LANG_COMMON_PASSWORD, LANG_COMMON_WELCOME, LANG_ERROR } from "../../lang";

const Login = () => {
    const intl = useIntl();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {doLogin} = useAPI();
    const {setToken, setFeatures, setProfiles} = useAuth();
    const {setIsAuth, setUser, setTermsAgreed} = useContext(AuthContext);
    const [show2faModal, setShow2faModal] = useState(false);
    const [show2faOTPModal, setShow2faOTPModal] = useState(false);
    const [qrUrl, setQrUrl] = useState("");
    const [secret, setSecret] = useState("");

    const submitHandler = (e: any) => {
        e.preventDefault();
        setLoading(true);
        doLogin(email, password)
            .then((resp) => {
                setLoading(false);
                if(resp.data.success) {
                    setShow2faOTPModal(true);
                } else {
                    if(resp.data.google_2fa !== undefined) {
                        setQrUrl(resp.data.google_2fa_url);
                        setSecret(resp.data.google_2fa_secret);
                        setShow2faModal(true);
                    } else {
                        setShowError(true);
                        setError(resp.data.msg);
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                setShowError(true);
                setError(intl.formatMessage(LANG_ERROR));
            })
    }

    return (
        <>
            {show2faModal && (
                <Google2faModal
                    show={show2faModal}
                    qr_url={qrUrl}
                    secret={secret}
                    onConfirm={() => {
                        setShow2faModal(false);
                        setShow2faOTPModal(true);
                    }}
                    onHide={() => setShow2faModal(false)} />
            )}
            {show2faOTPModal && (
                <Google2faOTPModal
                    show={show2faOTPModal}
                    email={email}
                    password={password}
                    secret={secret}
                    onConfirm={(resp) => {
                        setToken(resp.data.token);
                        setFeatures(resp.data.features);
                        setProfiles(resp.data.profiles);
                        setUser(resp.data.user);
                        setTermsAgreed(resp.data.user.terms_agreed);
                        setIsAuth(true);
                        navigate('/admin/dashboard');
                    }}
                    onHide={() => setShow2faOTPModal(false)} />
            )}
            {showError && (<Error message={error} show={showError} onHide={() => setShowError(false)} />)}
            <div className={'auth-page'} style={{'backgroundImage': `url(${BackgroundImage})`}}>
                <main className="form-signin text-center">
                    <div className="logo-login">
                        <img src={Logo} alt="Logo Isis"/>
                    </div>
                    <div className="form-login">
                        <form onSubmit={submitHandler}>
                            <div className="bg-custom-gradient custom-label">{intl.formatMessage(LANG_COMMON_WELCOME)}</div>
                            <h1 className="h3 mb-3 fw-normal">{intl.formatMessage(LANG_AUTH_DO_LOGIN)}</h1>
                            <div className="row">
                                <div className="col-md-10 offset-2">
                                    <div className="mb-3 w-75" style={{"marginTop": "11px"}}>
                                        <input
                                            type="email"
                                            className="form-control" id="floatingInput"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="mb-3 w-75" style={{"marginTop": "37px"}}>
                                        <input
                                            type="password"
                                            className="form-control"
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            placeholder={intl.formatMessage(LANG_COMMON_PASSWORD)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn btn-lg bg-custom-gradient btn-custom"
                                type="submit"
                                disabled={loading}
                                style={{"marginTop": "6px"}}
                            >{loading ? <ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> : intl.formatMessage(LANG_COMMON_LOGIN)}</button>
                        </form>
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="#" className="create-account">{intl.formatMessage(LANG_AUTH_CREATE_ACCOUNT)}</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="#" className="forget-password">{intl.formatMessage(LANG_AUTH_FORGET_PASSWORD)}</Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Login;
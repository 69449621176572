import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import BgDashboard from "./images/bg-dashboard.png";
import "./styles/dashboard.css";
import {useAPI} from "../../hooks/useAPI";
import PairingsChart from "./components/PairingsChart";
import Loader from "../../components/messages/Loader";
import BusinessSelect from "../../components/form/BusinessSelect";
import {useAuth} from "../../hooks/useAuth";
import moment from "moment";
import CustomSelect from "../../components/form/CustomSelect";
import {PersonModel} from "../../models/PersonModel";
import {OptionType} from "../../types/CommonType";
import NumberFormat from "react-number-format";

const DashboardSubscriptionPage = () => {
    const {api} = useAPI();
    const [stats, setStats] = useState<undefined | any>(undefined);
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [searchFromBusiness, setSearchFromBusiness] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<string>("");
    const {hasAbility} = useAuth();

    const loadStats = (options: any) => {
        setLoading(true);
        api().get(`/api/stats/subscriptions?${new URLSearchParams({...options, cache: (new Date()).getTime(), dateFrom: fromDate, dateTo: toDate, status: status}).toString()}`)
            .then((resp) => {
                setStats(resp.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        loadStats({});
    }, []);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {!loading && stats !== undefined && (
                <div className="bg-pattern" style={{'paddingBottom': '2rem'}}>
                    <Container>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Assinaturas</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{offset: 0, span: 8}}>
                                <Card>
                                    <CardHeader className={'d-flex'} style={{"padding": "4px 1rem"}}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{"paddingTop": "3px"}}>
                                                    PAGAMENTOS
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <div className="card-body"  style={{'padding': '0'}}>
                                        <Table striped>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>CLÍNICA</th>
                                                <th>Plano</th>
                                                <th>Valor</th>
                                                <th>Parcelas</th>
                                                <th>Status</th>
                                                <th>Período</th>
                                                {/*<th>Nota Fiscal</th>*/}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <>
                                                {stats?.payments.length === 0 && (
                                                    <tr>
                                                        <td colSpan={6} className={"text-center"}>
                                                            Nenhum resultado encontrado.
                                                        </td>
                                                    </tr>
                                                )}
                                                {stats?.payments.map((item: any) => (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>{item.subscription.business.name}</td>
                                                        <td>{item.subscription.plan.name}</td>
                                                        <td><NumberFormat
                                                            value={parseFloat(item.subscription.payment_option?.total)}
                                                            displayType={'text'}
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={'R$'} /></td>
                                                        <td>{item.subscription.payment_option?.installments}</td>
                                                        <td>
                                                            <b>
                                                                {item.status === "PEN" && (
                                                                    <span className="text-warning">PENDENTE</span>
                                                                )}
                                                                {item.status === "PAI" && (
                                                                    <span className="text-success">PAGO</span>
                                                                )}
                                                                {item.status === "CAN" && (
                                                                    <span className="text-danger">CANCELADO</span>
                                                                )}
                                                            </b>
                                                        </td>
                                                        <td>{moment(item.start_at).format("DD/MM/YYYY")} - {moment(item.end_at).format("DD/MM/YYYY")}</td>
                                                        {/*<td>*/}
                                                        {/*    <a*/}
                                                        {/*        title="Nota fiscal"*/}
                                                        {/*        href=""*/}
                                                        {/*        onClick={(e) => {*/}
                                                        {/*            e.preventDefault();*/}
                                                        {/*            alert("Não disponível no momento. Por favor, solicite através do suporte.")*/}
                                                        {/*        }}>*/}
                                                        {/*        <i className="bi bi-file-pdf-fill icon" style={{"color": "#25325b", "fontSize": "18px"}}/>*/}
                                                        {/*    </a>*/}
                                                        {/*</td>*/}
                                                    </tr>
                                                ))}
                                            </>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={{span: 4}}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>OPÇÕES</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            loadStats({});
                                        }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>De:</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            onChange={(e) => setFromDate(e.target.value)}
                                                            value={fromDate}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Até:</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            disabled={fromDate === ""}
                                                            min={fromDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                            value={toDate}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group className="mb-3" controlId="name">
                                                        <Form.Label>Status</Form.Label>
                                                        <CustomSelect
                                                            name="status"
                                                            options={[
                                                                {label: "Pago", value: "PAI"},
                                                                {label: "Pendente", value: "PEN"},
                                                                {label: "Cancelado", value: "CAN"},
                                                            ]}
                                                            onChange={(option: OptionType) => {
                                                                if(option !== null) {
                                                                    setStatus(option.value);
                                                                } else {
                                                                    setStatus("");
                                                                }
                                                            }}
                                                            value={status}
                                                            placeholder={"Selecione..."}
                                                            isMulti={false}
                                                            isSearchable={false}
                                                            isClearable={true}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    {hasAbility('BUSINESS_LIST') && (
                                                        <>
                                                            <label htmlFor="exampleInputEmail1" className="form-label">Clínica:</label>
                                                            <BusinessSelect
                                                                businessId={searchFromBusiness}
                                                                onChange={(business_id) => {
                                                                    setSearchFromBusiness(business_id);
                                                                    loadStats({business_id: (business_id === undefined) ? "" : business_id});
                                                                }}
                                                            />

                                                            <br/>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2">
                                                <div className="row">
                                                    <div className="col-md-8 offset-2">
                                                        <Button variant="primary" size={'lg'}
                                                                type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">Buscar</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <div className="my-4">
                                    <Card>
                                        <CardHeader>
                                            <Card.Title>DADOS</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row dashboard-stats">
                                                <div className="col-md-6 stats-donors">
                                                    <h4>CLÍNICAS</h4>
                                                    <h6>{stats.total_business}</h6>
                                                </div>
                                                <div className="col-md-6 stats-receivers">
                                                    <h4>ASSINANTES</h4>
                                                    <h6>{stats.total_subscriptions}</h6>
                                                </div>
                                                <div className="col-md-6 stats-pairings">
                                                    <h4>ASSINANTES ATIVOS</h4>
                                                    <h6>{stats.total_active_subscriptions}</h6>
                                                </div>
                                                <div className="col-md-6 stats-born-alive">
                                                    <h4>ASSINANTES EM ATRASO</h4>
                                                    <h6>{stats.total_due_subscriptions}</h6>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default DashboardSubscriptionPage;
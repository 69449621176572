import React, {FC, useEffect, useReducer, useState} from "react";
import {RodSelectItem, RodType} from "../../../types/RodType";
import CustomSelect from "../../../components/form/CustomSelect";
import {OptionType} from "../../../types/CommonType";
import {RodSelectListReducer} from "../../../reducers/PersonReducer";
import {RodModel} from "../../../models/RodModel";
import {PersonModel} from "../../../models/PersonModel";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_NEW, LANG_PERSON_RODS, LANG_PERSON_ROD_FRESH, LANG_PERSON_ROD_IS_USED } from "../../../lang";

interface Props {
    donor?: number
    onSelect: (rodList: string) => void
}

const RodsSelectList:FC<Props> = ({donor, onSelect}) => {
    const intl = useIntl();
    const [rodList, setRodList] = useState<undefined | Array<RodType>>(undefined);
    const [selectedRods, setSelectedRods] = useState<string>("");

    useEffect(() => {
        if(donor !== undefined) {
            setRodList(undefined);
            RodModel().list(donor).then((rodList) => {
                if(typeof rodList !== "boolean") {
                    let filteredRodList: undefined | Array<RodType> = rodList.filter((item) => {
                        return !item.used
                    });

                    // Add option to collect ovules later
                    filteredRodList.push({
                        id: -1,
                        person_id: donor,
                        code: intl.formatMessage(LANG_COMMON_NEW),
                        type: "F",
                        available: true
                    });

                    setRodList(filteredRodList);
                }
            });
        }
    }, [donor]);

    return (
        <>
            {rodList !== undefined && (
                <>
                    <label className="form-label mt-2">{intl.formatMessage(LANG_PERSON_RODS)}:</label>
                    <CustomSelect
                        key={`${donor}-rod`}
                        name="rod"
                        options={rodList.map((item) => {
                            return item.type === "C" ? 
                            {label: `${item.code} - ${item.freezing_date} (${item.quantity}) ${!item.available ? ` - ${intl.formatMessage(LANG_PERSON_ROD_IS_USED)}` : ''}`, value: item.id, isOptionDisabled: !item.available} : 
                            {label: `${item.code} - ${intl.formatMessage(LANG_PERSON_ROD_FRESH)}${!item.available ? ` - ${intl.formatMessage(LANG_PERSON_ROD_IS_USED)}` : ''}`, value: item.id, isOptionDisabled: !item.available};
                        })}
                        onChange={(options: Array<OptionType>) => {
                            const selected = options.map((item) => {
                                return item.value;
                            }).join(",");
                            setSelectedRods(selected);
                            onSelect(selected);
                        }}
                        value={selectedRods}
                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                        isMulti={true}
                        isSearchable={false}
                        isClearable={false}
                    />
                </>
            )}
        </>
    );
}

export default RodsSelectList;
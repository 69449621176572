import React, {FC, useEffect, useReducer, useState} from "react";
import {Button, Card, Col, Container, Row, Table, Breadcrumb, Form} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import PersonsSelect from "../../components/PersonsSelect";
import {Link, useNavigate} from "react-router-dom";
import Confirm from "../../components/messages/Confirm";
import ClipLoader from "react-spinners/ClipLoader";
import {UserType} from "../../types/UserType";
import BusinessSelect from "../../components/form/BusinessSelect";
import {useAuth} from "../../hooks/useAuth";
import {UserListReducer} from "../../reducers/UserReducer";
import {UserModel} from "../../models/UserModel";
import {toast} from "react-toastify";
import {PersonListReducer} from "../../reducers/PersonReducer";
import {PersonModel} from "../../models/PersonModel";
import {PersonType} from "../../types/PersonType";
import CustomSelect from "../../components/form/CustomSelect";
import {OptionType} from "../../types/CommonType";
import PairingFilters from "../pairing/components/PairingFilters";
import {PairingModel} from "../../models/PairingModel";
import {BornAliveType, PairingType} from "../../types/PairingType";
import moment from "moment";
import download from "downloadjs";
import {useAPI} from "../../hooks/useAPI";
import Loader from "../../components/messages/Loader";
import AddBornAliveModal from "./components/AddBornAliveModal";
import "./styles/pairing-page.css";
import TranslateTerms from "../../components/TranslateTerms";
import Error from "../../components/messages/Error";
import CityListHavingPersons from "../../components/form/CityListHavingPersons";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_CITY, LANG_COMMON_ADD, LANG_COMMON_ALL, LANG_COMMON_BORN_ALIVE, LANG_COMMON_CLEAR, LANG_COMMON_CLINIC, LANG_COMMON_CONFIRM, LANG_COMMON_CONFIRMED, LANG_COMMON_DATE, LANG_COMMON_DELETE_CONFIRM, LANG_COMMON_DELETE_ERROR, LANG_COMMON_DELETE_SUCCESS, LANG_COMMON_FEMALE, LANG_COMMON_FROM_DATE, LANG_COMMON_LOADING, LANG_COMMON_MALE, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NEXT, LANG_COMMON_NO, LANG_COMMON_NO_CONFIRMED, LANG_COMMON_NO_RESULTS, LANG_COMMON_OPTIONS, LANG_COMMON_PAIRING, LANG_COMMON_PAIRINGS, LANG_COMMON_PREVIOUS, LANG_COMMON_SEARCH, LANG_COMMON_SIMILARITY, LANG_COMMON_TO_DATE, LANG_COMMON_YES, LANG_ERROR, LANG_PERSON_RODS } from "../../lang";

const PairingPage = () => {
    const intl = useIntl();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmPairing, setShowConfirmPairing] = useState(false);
    const [showConfirmBornAliveDelete, setShowConfirmBornAliveDelete] = useState(false);
    const [bornAliveToDelete, setBornAliveToDelete] = useState<undefined | BornAliveType>(undefined);
    const [showAddBornAliveModal, setShowAddBornAliveModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [pairingToDelete, setPairingToDelete] = useState<undefined | PairingType>(undefined);
    const [pairingToUpdate, setPairingToUpdate] = useState<undefined | PairingType>(undefined);
    const [bornsAliveCount, setBornsAliveCount] = useState<number>(-1);
    const [searchFromBusiness, setSearchFromBusiness] = useState<number | undefined>(undefined);
    const [search, setSearch] = useState("");
    const [pairings, setPairings] = useState<Array<PairingType> | undefined>(undefined);
    const [errorPdf, setErrorPdf] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLastPage, setIsLastPage] = useState(true);
    const {api} = useAPI();
    const defaultOptions = {
        fromDate: "",
        toDate: "",
        donor_city_id: "",
        receiver_city_id: "",
        confirmed: "",
        search: ""
    };
    const [options, setOptions] = useState(defaultOptions);
    

    const {hasAbility} = useAuth();
    const navigate = useNavigate();

    const loadPairings = (business_id: number | undefined = undefined, options: any | undefined = undefined, pageNumber: number) => {
        setLoading(true);
        PairingModel().list(business_id, options, pageNumber).then((pairings) => {
            if(typeof pairings !== "boolean") {
                setLoading(false);
                setPairings(pairings.data);
                setIsLastPage(pairings.last_page === pageNumber);
            }
        })
    }
    useEffect(() => {
        loadPairings(searchFromBusiness, options, pageNumber);
    }, []);    

    const downloadPdf = (id: number) => {
        setLoadingPdf(true);
        api().downloadPDF(`/api/pairing/${id}?print=Y`).then(response => {
            const content = response.headers['content-type'];
            download(response.data, "pareamento", content);
            setLoadingPdf(false);
        })
            .catch(error => {
                setLoadingPdf(false);
            });
    }

    const downloadPairingPdf = () => {
        setLoadingPdf(true);
        setErrorPdf(false);
        api().downloadPDF(`/api/pairing?${new URLSearchParams({
            "print": "Y",
            ...options
        }).toString()}`).then(response => {
            const content = response.headers['content-type'];
            download(response.data, "pareamento", content);
            setLoadingPdf(false);
        })
            .catch(error => {
                setLoadingPdf(false);
                setErrorPdf(true);
            });
    }

    const confirmPairing = (id: number) => {
        setLoadingPdf(true);
        api().put(`/api/pairing/${id}/confirm`, {}).then(resp => {
            setLoadingPdf(false);
            loadPairings(searchFromBusiness, options, pageNumber);
        }).catch(error => {
            setLoadingPdf(false);
        });
    }

    return (
        <>
            {loadingPdf && (<Loader fullscreen={true}/>)}
            {errorPdf && (<Error message={intl.formatMessage(LANG_ERROR)} show={errorPdf} onHide={() => setErrorPdf(false)} />)}
            <Confirm
                message={intl.formatMessage(LANG_COMMON_DELETE_CONFIRM)}
                show={showConfirm}
                onConfirm={async () => {
                    setShowConfirm(false);
                    const id = toast.loading(intl.formatMessage(LANG_COMMON_LOADING));
                    let result = false;
                    if(pairingToDelete !== undefined && pairingToDelete?.receiver !== undefined && pairingToDelete?.id !== undefined) {
                        result = await PairingModel().remove(pairingToDelete?.receiver, pairingToDelete?.id);
                    } else {
                        return;
                    }

                    toast.dismiss(id);
                    if(result) {
                        toast.success(intl.formatMessage(LANG_COMMON_DELETE_SUCCESS));
                        loadPairings(searchFromBusiness, options, pageNumber);
                    } else {
                        toast.error(`${intl.formatMessage(LANG_COMMON_DELETE_ERROR)}: ${pairingToDelete?.id}!`, {
                            hideProgressBar: true,
                            autoClose: false
                        });
                    }
                }}
                onHide={() => setShowConfirm(false)}
            />

            <Confirm
                message={intl.formatMessage(LANG_COMMON_DELETE_CONFIRM)}
                show={showConfirmBornAliveDelete}
                onConfirm={() => {
                    if(bornAliveToDelete !== undefined) {
                        setShowConfirmBornAliveDelete(false);
                        const id = toast.loading(intl.formatMessage(LANG_COMMON_LOADING));
                        PairingModel().removeBornAlive(bornAliveToDelete.pairing_id, bornAliveToDelete.id).then((success) => {
                            toast.dismiss(id);
                            if(success) {
                                toast.success(intl.formatMessage(LANG_COMMON_DELETE_SUCCESS));
                                loadPairings(searchFromBusiness, options, pageNumber);
                            } else {
                                toast.error(`${intl.formatMessage(LANG_COMMON_DELETE_ERROR)}!`, {
                                    hideProgressBar: true,
                                    autoClose: false
                                });
                            }
                        })
                    }
                }}
                onHide={() => {
                    setShowConfirmBornAliveDelete(false);
                }}
            />
            <Confirm
                message={intl.formatMessage({
                    id: 'pairing.confirm_message',
                    defaultMessage: 'Deseja realmente confirmar a realização desse pareamento?'
                })}
                show={showConfirmPairing}
                onConfirm={() => {
                    if(pairingToUpdate?.id !== undefined) {
                        setShowConfirmPairing(false);
                        confirmPairing(pairingToUpdate?.id);
                    }
                }}
                onHide={() => {
                    setShowConfirmPairing(false);
                }}
            />
            {showAddBornAliveModal && pairingToUpdate?.id !== undefined && (
                <AddBornAliveModal
                    show={showAddBornAliveModal}
                    onHide={() => setShowAddBornAliveModal(false)}
                    onSave={() => {
                        setShowAddBornAliveModal(false);
                        loadPairings(searchFromBusiness, options, pageNumber);
                    }}
                    pairingId={pairingToUpdate.id} />
            )}
            <div className="bg-pattern">
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item active>{intl.formatMessage(LANG_COMMON_PAIRINGS)}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{offset: 0}}>
                            <Card>
                                <CardHeader className={'d-flex'} style={{"padding": "4px 1rem"}}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{"paddingTop": "3px"}}>
                                                {intl.formatMessage(LANG_COMMON_PAIRINGS)} {" "}
                                                </div>
                                                <div className="text-end col-md-6">
                                                    <a
                                                        href="#"
                                                        className="btn-icon"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadPairingPdf();
                                                        }}
                                                    ><i className="bi bi-printer" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                <div className="card-body" style={{'padding': '0'}}>
                                    <Table striped>
                                        <thead>
                                        <tr>
                                            <th><TranslateTerms term="receptor"/></th>
                                            <th><TranslateTerms term="donor"/></th>
                                            <th>{intl.formatMessage(LANG_COMMON_SIMILARITY)}</th>
                                            <th>{intl.formatMessage(LANG_COMMON_DATE)}</th>
                                            <th>{intl.formatMessage(LANG_PERSON_RODS)}</th>
                                            <th>{intl.formatMessage(LANG_COMMON_BORN_ALIVE)}</th>
                                            <th>{intl.formatMessage(LANG_COMMON_CONFIRMED)}</th>
                                            <th style={{"width": "120px"}}>{intl.formatMessage(LANG_COMMON_OPTIONS)}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {loading && (
                                            <tr>
                                                <td colSpan={9} className={'text-center'}>
                                                    <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}{intl.formatMessage(LANG_COMMON_LOADING)}
                                                </td>
                                            </tr>
                                        )}
                                        {!loading && pairings?.length === 0 && (
                                            <tr>
                                                <td colSpan={9} className={"text-center"}>
                                                {intl.formatMessage(LANG_COMMON_NO_RESULTS)}
                                                </td>
                                            </tr>
                                        )}
                                        {!loading && (
                                            <>
                                                {pairings?.map((item: PairingType) => (
                                                    <tr key={item.id}>
                                                        <td>{item.receiver_person?.name} - {item.receiver_person?.city?.name} ({item.receiver_person?.city?.state.short_name})</td>
                                                        <td>{item.donor_person?.name} - {item.donor_person?.city?.name} ({item.donor_person?.city?.state.short_name})</td>
                                                        <td>{item.similarity}%</td>
                                                        <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                                                        <td style={{'width': '250px'}}>{item.rods?.map((pairingRod) => {
                                                            return `${pairingRod.rod?.code}`;
                                                        }).join(", ")}</td>
                                                        <td className="born-alive-list">
                                                            <ul>
                                                                {item.borns_alive?.map((bornAlive) => (
                                                                    <>
                                                                        <li>{bornAlive.gender === "F" ? intl.formatMessage(LANG_COMMON_FEMALE) : intl.formatMessage(LANG_COMMON_MALE)} - {bornAlive.birth_date}
                                                                            <a href="#" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setBornAliveToDelete(bornAlive);
                                                                                setShowConfirmBornAliveDelete(true);
                                                                            }}><i className="icon icon-delete">
                                                                                <svg version="1.1" id="Camada_1"
                                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                                     x="0px" y="0px"
                                                                                     viewBox="0 0 13.73 17.23">
                                                                                    <g>
                                                                                        <path className="st2" d="M11.74,3.1l-0.46,12.49c-0.01,0.21-0.2,0.4-0.41,0.4H2.87c-0.21,0-0.4-0.18-0.41-0.4L2,3.1
                                                                                    c-0.01-0.34-0.3-0.61-0.65-0.6c-0.34,0.01-0.61,0.3-0.6,0.65l0.46,12.49c0.03,0.88,0.77,1.6,1.66,1.6h7.99
                                                                                    c0.89,0,1.63-0.71,1.66-1.6l0.46-12.49C13,2.8,12.73,2.51,12.38,2.5C12.04,2.48,11.75,2.75,11.74,3.1L11.74,3.1z"/>
                                                                                        <path className="st2" d="M6.37,5.62v8.49c0,0.28,0.22,0.5,0.5,0.5s0.5-0.22,0.5-0.5V5.62c0-0.28-0.22-0.5-0.5-0.5S6.37,5.34,6.37,5.62
                                                                                    L6.37,5.62z"/>
                                                                                        <path className="st2" d="M3.62,5.63l0.25,8.49c0.01,0.28,0.24,0.49,0.51,0.48c0.28-0.01,0.49-0.24,0.48-0.51L4.62,5.6
                                                                                    C4.61,5.33,4.38,5.11,4.11,5.12C3.83,5.13,3.61,5.36,3.62,5.63L3.62,5.63z"/>
                                                                                        <path className="st2" d="M9.11,5.6l-0.25,8.49c-0.01,0.28,0.21,0.51,0.48,0.51c0.28,0.01,0.51-0.21,0.51-0.48l0.25-8.49
                                                                                    c0.01-0.28-0.21-0.51-0.48-0.51C9.35,5.11,9.12,5.33,9.11,5.6L9.11,5.6z"/>
                                                                                        <path className="st2" d="M0.62,3.37h12.49c0.34,0,0.62-0.28,0.62-0.62c0-0.34-0.28-0.62-0.62-0.62H0.62C0.28,2.12,0,2.4,0,2.75
                                                                                    C0,3.09,0.28,3.37,0.62,3.37z"/>
                                                                                        <path className="st2" d="M4.97,2.54l0.26-0.91c0.06-0.19,0.31-0.39,0.51-0.39h2.25c0.2,0,0.46,0.19,0.51,0.39l0.26,0.91l1.2-0.34
                                                                                    L9.7,1.29C9.5,0.56,8.75,0,7.99,0H5.74c-0.76,0-1.5,0.56-1.71,1.29L3.77,2.2L4.97,2.54z"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </i></a>
                                                                        </li>
                                                                    </>
                                                                ))}
                                                            </ul>
                                                            <a
                                                                href="#"
                                                                className=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAddBornAliveModal(true);
                                                                    setPairingToUpdate(item);
                                                                }}
                                                            >{intl.formatMessage(LANG_COMMON_ADD)}</a>
                                                        </td>
                                                        <td>{item.confirmed ? intl.formatMessage(LANG_COMMON_YES) : intl.formatMessage(LANG_COMMON_NO)}</td>
                                                        <td style={{"textAlign": "right"}}>
                                                            {/*<Link to={`/admin/pairing/${item.id}/edit`}>
                                                                <i className="bi-pencil-square"/>
                                                            </Link>
                                                            {" "}*/}
                                                            {!item.confirmed && (
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setPairingToUpdate(item);
                                                                    setShowConfirmPairing(true);
                                                                }}>
                                                                    <i className="bi bi-check-square icon" style={{"color": "#25325b"}}/>
                                                                </a>
                                                            )}
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                if(item.id !== undefined) {
                                                                    downloadPdf(item.id);
                                                                }
                                                            }}>
                                                                <i className="bi bi-printer icon" style={{"color": "#25325b"}}/>
                                                            </a>
                                                            <Link to={`/admin/pairing/${item.id}/view`}>
                                                                <i className="bi bi-search icon" style={{"color": "#25325b"}}/>
                                                            </Link>
                                                            <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowConfirm(true);
                                                                    setPairingToDelete(item);
                                                                }}><i className="icon icon-delete">
                                                                    <svg version="1.1" id="Camada_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px" y="0px"
                                                                            viewBox="0 0 13.73 17.23">
                                                                        <g>
                                                                            <path className="st2" d="M11.74,3.1l-0.46,12.49c-0.01,0.21-0.2,0.4-0.41,0.4H2.87c-0.21,0-0.4-0.18-0.41-0.4L2,3.1
                                                                                        c-0.01-0.34-0.3-0.61-0.65-0.6c-0.34,0.01-0.61,0.3-0.6,0.65l0.46,12.49c0.03,0.88,0.77,1.6,1.66,1.6h7.99
                                                                                        c0.89,0,1.63-0.71,1.66-1.6l0.46-12.49C13,2.8,12.73,2.51,12.38,2.5C12.04,2.48,11.75,2.75,11.74,3.1L11.74,3.1z"/>
                                                                            <path className="st2" d="M6.37,5.62v8.49c0,0.28,0.22,0.5,0.5,0.5s0.5-0.22,0.5-0.5V5.62c0-0.28-0.22-0.5-0.5-0.5S6.37,5.34,6.37,5.62
                                                                                        L6.37,5.62z"/>
                                                                            <path className="st2" d="M3.62,5.63l0.25,8.49c0.01,0.28,0.24,0.49,0.51,0.48c0.28-0.01,0.49-0.24,0.48-0.51L4.62,5.6
                                                                                        C4.61,5.33,4.38,5.11,4.11,5.12C3.83,5.13,3.61,5.36,3.62,5.63L3.62,5.63z"/>
                                                                            <path className="st2" d="M9.11,5.6l-0.25,8.49c-0.01,0.28,0.21,0.51,0.48,0.51c0.28,0.01,0.51-0.21,0.51-0.48l0.25-8.49
                                                                                        c0.01-0.28-0.21-0.51-0.48-0.51C9.35,5.11,9.12,5.33,9.11,5.6L9.11,5.6z"/>
                                                                            <path className="st2" d="M0.62,3.37h12.49c0.34,0,0.62-0.28,0.62-0.62c0-0.34-0.28-0.62-0.62-0.62H0.62C0.28,2.12,0,2.4,0,2.75
                                                                                        C0,3.09,0.28,3.37,0.62,3.37z"/>
                                                                            <path className="st2" d="M4.97,2.54l0.26-0.91c0.06-0.19,0.31-0.39,0.51-0.39h2.25c0.2,0,0.46,0.19,0.51,0.39l0.26,0.91l1.2-0.34
                                                                                        L9.7,1.29C9.5,0.56,8.75,0,7.99,0H5.74c-0.76,0-1.5,0.56-1.71,1.29L3.77,2.2L4.97,2.54z"/>
                                                                        </g>
                                                                    </svg>
                                                                </i></a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                                <Row className="mt-2 mb-4">
                                    <Col lg={{ span: 6 }}>
                                        <button
                                            type="button"
                                            className="btn-custom btn-custom-light btn-custom-small text-uppercase btn btn-outline-light btn-lg"
                                            disabled={pageNumber === 1}
                                            onClick={() => {
                                                loadPairings(searchFromBusiness, options, pageNumber - 1);
                                                setPageNumber(pageNumber - 1);
                                            }}
                                        >&laquo; <FormattedMessage {...LANG_COMMON_PREVIOUS} /></button>
                                    </Col>
                                    <Col lg={{ span: 6 }} className="d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn-custom btn-custom-light btn-custom-small text-uppercase btn btn-outline-light btn-lg"
                                            disabled={isLastPage}
                                            onClick={() => {
                                                loadPairings(searchFromBusiness, options, pageNumber + 1);
                                                setPageNumber(pageNumber + 1);
                                            }}
                                        ><FormattedMessage {...LANG_COMMON_NEXT} /> &raquo;</button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={{span: 3}}>
                            <Card>
                                <CardHeader>
                                    <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        loadPairings(searchFromBusiness, options, 1);
                                        setPageNumber(1);
                                    }}>
                                        {hasAbility('BUSINESS_LIST') && (
                                            <>
                                                <label htmlFor="exampleInputEmail1" className="form-label">{intl.formatMessage(LANG_COMMON_CLINIC)}:</label>
                                                <BusinessSelect
                                                    onChange={(business_id) => {
                                                        setSearchFromBusiness(business_id);
                                                    }}
                                                />

                                                <br/>
                                            </>
                                        )}
                                        <Form.Group className="mb-3">
                                            <Form.Label>{intl.formatMessage(LANG_COMMON_SEARCH)}</Form.Label>
                                            <Form.Control
                                                placeholder={intl.formatMessage(LANG_COMMON_MEDICAL_RECORD_NUMBER)}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    setOptions({...options, search: e.target.value});
                                                }}
                                                value={options.search}
                                            />
                                        </Form.Group>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{intl.formatMessage(LANG_COMMON_FROM_DATE)}</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={(e) => setOptions({...options, fromDate: e.target.value})}
                                                        value={options.fromDate}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{intl.formatMessage(LANG_COMMON_TO_DATE)}</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={(e) => setOptions({...options, toDate: e.target.value})}
                                                        value={options.toDate}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{intl.formatMessage(LANG_CITY)} - <TranslateTerms term="donor"/></Form.Label>
                                            <CityListHavingPersons 
                                                className="form-select form-select-solid form-select-lg form-control"
                                                defaultOption={intl.formatMessage(LANG_COMMON_ALL)}
                                                value={options.donor_city_id}
                                                onChange={(city_id: any) => {
                                                    setOptions({...options, donor_city_id: city_id});
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{intl.formatMessage(LANG_CITY)} - <TranslateTerms term="receptor"/></Form.Label>
                                            <CityListHavingPersons 
                                                className="form-select form-select-solid form-select-lg form-control"
                                                defaultOption={intl.formatMessage(LANG_COMMON_ALL)}
                                                value={options.receiver_city_id}
                                                onChange={(city_id: any) => {
                                                    setOptions({...options, receiver_city_id: city_id});
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{intl.formatMessage(LANG_COMMON_CONFIRM)}</Form.Label>
                                            <select
                                                className="form-select form-select-solid form-select-lg form-control"
                                                value={options.confirmed}
                                                onChange={(e: any) => {
                                                    setOptions({...options, confirmed: e.target.value});
                                                }}>
                                                    <option value="">{intl.formatMessage(LANG_COMMON_ALL)}</option>
                                                    <option value="Y">{intl.formatMessage(LANG_COMMON_CONFIRMED)}</option>
                                                    <option value="N">{intl.formatMessage(LANG_COMMON_NO_CONFIRMED)}</option>
                                            </select>
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Button 
                                                        variant="outline-light" 
                                                        size={'lg'} 
                                                        style={{"width": "100%"}} 
                                                        className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100"
                                                        onClick={() => {
                                                            setOptions(defaultOptions);
                                                            setPageNumber(1);
                                                            setTimeout(() => {
                                                                loadPairings(searchFromBusiness, defaultOptions, 1);
                                                            }, 200);
                                                        }}
                                                        >{intl.formatMessage(LANG_COMMON_CLEAR)}</Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button variant="primary" size={'lg'}
                                                            type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">{intl.formatMessage(LANG_COMMON_SEARCH)}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PairingPage;
import React from "react";
import {Route, Routes} from "react-router-dom";
import Login from "../modules/auth/Login";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={'/auth/login'} element={<Login />} />
        </Routes>
    );
}

export default PublicRoutes;
import React, { FC, useContext, useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { PersonType } from "../../../types/PersonType";
import { PersonModel } from "../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../types/CommonType";
import CustomSelect from "../../../components/form/CustomSelect";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_CHOOSE, LANG_COMMON_CONFIRM, LANG_COMMON_HEADER_NEW_PAIRING, LANG_PERSON_BLOOD_TYPE, LANG_PERSON_RACE, LANG_PERSON_RH_FACTOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (filters: any) => void
}
const PairingFilters: FC<Props> = ({ show, onHide, onSave }) => {
    const options = useContext(OptionsContext);
    const schema = yup.object().shape({
        blood_type: yup.string().required(),
        rh_factor: yup.string().required(),
        race: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const intl = useIntl();   

    const initialFilters = {
        blood_type: "",
        rh_factor: "",
        race: "",
    };

    const onSubmit = (values: any) => {
        setFormWasSubmitted(false);
        onSave(values);
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialFilters}
                validateOnChange={true}
                validateOnMount={true}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    setFieldValue,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                <FormattedMessage {...LANG_COMMON_HEADER_NEW_PAIRING} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_PERSON_BLOOD_TYPE} /></Form.Label>
                                <CustomSelect
                                    name="blood_type"
                                    options={PersonModel().getBloodTypes()}
                                    onChange={(options: Array<OptionType>) => {
                                        const selected = options.map((item) => {
                                            return item.value;
                                        }).join(",");
                                        setFieldValue("blood_type", selected)
                                    }}
                                    value={values.blood_type}
                                    placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                    isMulti={true}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_PERSON_RH_FACTOR} /></Form.Label>
                                <CustomSelect
                                    name="rh_facotr"
                                    options={options.personOptions?.getRHFactorTypes()}
                                    onChange={(options: Array<OptionType>) => {
                                        const selected = options.map((item) => {
                                            return item.value;
                                        }).join(",");
                                        setFieldValue("rh_factor", selected)
                                    }}
                                    value={values.rh_factor}
                                    placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                    isMulti={true}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_PERSON_RACE} /></Form.Label>
                                <CustomSelect
                                    name="race"
                                    options={options.personOptions?.getRaceTypes()}
                                    onChange={(options: Array<OptionType>) => {
                                        const selected = options.map((item) => {
                                            return item.value;
                                        }).join(",");
                                        setFieldValue("race", selected)
                                    }}
                                    value={values.race}
                                    placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                    isMulti={true}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={!isValid} className="btn-custom bg-custom-gradient">
                                <FormattedMessage {...LANG_COMMON_CONFIRM} />
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default PairingFilters;
export function useConfig() {
    // const ENV = "DEV";
    const ENV = "PROD";
    const configs: any = {
        // 'API_URL' : "http://localhost",
        // 'SITE_URL' : "http://localhost:3000",


        'API_URL' : "https://isispro.com.br",
        'SITE_URL' : "https://isispro.com.br",




        // @ts-ignore
        'PAGARME_APPID': (ENV === "DEV") ? "pk_test_MepQzLo3I0FKrywO" : "pk_oWzjJ4XSV8URRyaq",
        // @ts-ignore
        'GOOGLE_APIKEY': (ENV === "DEV") ? "AIzaSyDFP7YFXNB_pBCVI6hP-kslhcft9TS9aeI" : "AIzaSyDFP7YFXNB_pBCVI6hP-kslhcft9TS9aeI"
    };

    const getConfig = (key: string) => {
        for(let i in configs) {
            if(i === key){
                return configs[i];
            }
        }

        return false
    }

    return {
        getConfig
    };
}

import React, { FC, useState } from "react";
// @ts-ignore
import Cards from 'react-credit-cards-2';
import './styles/styles.scss';
import { Button, Form, Modal } from "react-bootstrap";
import clsx from "clsx";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { LANG_COMMON_BACK, LANG_COMMON_BUSINESS_ID, LANG_COMMON_NEXT, LANG_COMMON_PERSON_ID } from "../../lang";

interface Props {
    onValidate: (isValid: boolean, card: any) => void
    onBack: () => void
}
const SubscriptionCreditCard: FC<Props> = ({ onValidate, onBack }) => {
    const intl = useIntl();
    const [focus, setFocus] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleInputFocus = (e: any) => {
        setFocus(e.target.name);
    }

    const schema = yup.object().shape({
        cvc: yup.string().required(),
        expiry: yup.string().required(),
        name: yup.string().required(),
        number: yup.string().required(),
        document: yup.string().required(),
        document_type: yup.string().required(),
    });
    const formik = useFormik({
        initialValues: {
            cvc: "",
            expiry: "",
            name: "",
            number: "",
            document: "",
            document_type: intl.formatMessage(LANG_COMMON_PERSON_ID)
        },
        validationSchema: schema,
        onSubmit: (values) => { },
        validateOnMount: false
    });

    return (
        <>
            <Modal.Body>
                <div className="p-3">
                    <div className="row my-3">
                        <div className="col-lg-5">
                            <Cards
                                cvc={formik.values.cvc}
                                expiry={formik.values.expiry}
                                focused={focus}
                                name={formik.values.name}
                                number={formik.values.number}
                                locale={{
                                    "valid": intl.formatMessage({
                                        id: 'subscription.card.valid',
                                        defaultMessage: "valido até"
                                    })
                                }}
                                placeholders={{
                                    "name": intl.formatMessage({
                                        id: 'subscription.card.name',
                                        defaultMessage: "SEU NOME AQUI"
                                    })
                                }}
                            />
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage({
                                            id: 'subscription.card.number',
                                            defaultMessage: "Número do Cartão de Crédito"
                                        })}</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="number"
                                            onFocus={handleInputFocus}
                                            value={formik.values.number}
                                            onChange={formik.handleChange}
                                            isValid={formik.touched.number && !formik.errors.number}
                                            isInvalid={!!formik.errors.number && hasSubmitted}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage({
                                            id: 'subscription.card.owner',
                                            defaultMessage: "Nome do Proprietário"
                                        })}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            onFocus={handleInputFocus}
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            isValid={formik.touched.name && !formik.errors.name}
                                            isInvalid={!!formik.errors.name && hasSubmitted}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage({
                                            id: 'subscription.card.owner_doc',
                                            defaultMessage: "Documento do Proprietário"
                                        })}</Form.Label>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <select
                                                    name="document_type"
                                                    className={clsx("form-control form-select", {
                                                        "is-invalid": formik.touched.document && formik.errors.document,
                                                        "is-valid": !formik.errors.document && hasSubmitted
                                                    })}
                                                    onFocus={handleInputFocus}
                                                    value={formik.values.document_type}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="CPF">{intl.formatMessage(LANG_COMMON_PERSON_ID)}</option>
                                                    <option value="CNPJ">{intl.formatMessage(LANG_COMMON_BUSINESS_ID)}</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-9">
                                                {formik.values.document_type === "CNPJ" && (
                                                    <InputMask
                                                        name={"document"}
                                                        type="text"
                                                        mask="99.999.999/9999-99"
                                                        value={formik.values.document}
                                                        onChange={(e) => {
                                                            if (e.target.value !== null) {
                                                                formik.setFieldValue("document", e.target.value);
                                                            }
                                                        }}
                                                        className={clsx("form-control", {
                                                            "is-invalid": formik.touched.document && formik.errors.document,
                                                            "is-valid": !formik.errors.document && hasSubmitted
                                                        })}
                                                    />
                                                )}
                                                {formik.values.document_type === "CPF" && (
                                                    <InputMask
                                                        name={"document"}
                                                        type="text"
                                                        mask="999.999.999-99"
                                                        value={formik.values.document}
                                                        onChange={(e) => {
                                                            if (e.target.value !== null) {
                                                                formik.setFieldValue("document", e.target.value);
                                                            }
                                                        }}
                                                        className={clsx("form-control", {
                                                            "is-invalid": formik.touched.document && formik.errors.document,
                                                            "is-valid": !formik.errors.document && hasSubmitted
                                                        })}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-4">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage({
                                            id: 'subscription.card.expiry',
                                            defaultMessage: "Validade"
                                        })} (MM/AA)</Form.Label>
                                        <InputMask
                                            type="text"
                                            name="expiry"
                                            onFocus={handleInputFocus}
                                            placeholder="__/__"
                                            mask="99/99"
                                            value={formik.values.expiry}
                                            onChange={formik.handleChange}
                                            className={clsx("form-control", {
                                                "is-invalid": formik.touched.expiry && formik.errors.expiry,
                                                "is-valid": !formik.errors.expiry && hasSubmitted
                                            })}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-4">
                                    <Form.Group className="mb-3">
                                        <Form.Label>CVC</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="cvc"
                                            placeholder="CVC"
                                            onFocus={handleInputFocus}
                                            value={formik.values.cvc}
                                            onChange={formik.handleChange}
                                            isValid={formik.touched.cvc && !formik.errors.cvc}
                                            isInvalid={!!formik.errors.cvc && hasSubmitted}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onBack} className="btn-custom btn-custom-light">
                    {intl.formatMessage(LANG_COMMON_BACK)}
                </Button>
                <Button variant="primary" onClick={() => {
                    formik.validateForm(formik.values).then((errors) => {
                        onValidate(Object.keys(errors).length === 0, formik.values);
                        setHasSubmitted(true);
                    });
                }} className="btn-custom bg-custom-gradient">
                    {intl.formatMessage(LANG_COMMON_NEXT)}
                </Button>
            </Modal.Footer>
        </>
    );
}

export default SubscriptionCreditCard;
import React, {useEffect, useReducer, useState} from "react";
import {Button, Card, Col, Container, Row, Table, Breadcrumb, Form} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import {useAPI} from "../../hooks/useAPI";
import Loader from "../../components/messages/Loader";
import moment from "moment";
import NumberFormat from 'react-number-format';
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { useIntl } from "react-intl";
import { LANG_COMMON_ID, LANG_COMMON_MONTH, LANG_COMMON_NO_RESULTS, LANG_COMMON_STATUS, LANG_COMMON_SUBSCRIPTION, LANG_ERROR, LANG_SUBSCRIPTION_DURATION, LANG_SUBSCRIPTION_INVOICE, LANG_SUBSCRIPTION_PAYMENTS, LANG_SUBSCRIPTION_PLAN, LANG_SUBSCRIPTION_RECEIPT, LANG_SUBSCRIPTION_RECURRENCY_M, LANG_SUBSCRIPTION_RECURRENCY_Y, LANG_SUBSCRIPTION_STATUS_CANCELLED, LANG_SUBSCRIPTION_STATUS_PAID, LANG_SUBSCRIPTION_STATUS_PENDING } from "../../lang";

const SubscriptionPage = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const {api} = useAPI();
    const [subscription, setSubscription] = useState<any>(undefined);

    const loadSubscription = () => {
        setLoading(true);
        api().get("/api/subscription/active")
            .then((resp) => {
                setSubscription(resp.data);
                setLoading(false);
            })
            .catch((error) => {
                if(window.confirm(intl.formatMessage(LANG_ERROR))){
                    loadSubscription();
                }
            });
    }
    useEffect(() => {
        loadSubscription();
    }, []);

    const _formatCurrency = (amount: number) => {
        return "R$" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    };

    return (
        <>
            {loading && (
                <Loader fullscreen={true} />
            )}
            {!loading && (
                <div className="bg-pattern">
                    <Container>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>{intl.formatMessage(LANG_COMMON_SUBSCRIPTION)}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{offset: 0}}>
                                <Card>
                                    <CardHeader className={'d-flex'} style={{"padding": "4px 1rem"}}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{"paddingTop": "3px"}}>
                                                {intl.formatMessage(LANG_SUBSCRIPTION_PAYMENTS)}
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <div className="card-body"  style={{'padding': '0'}}>
                                        <Table striped>
                                            <thead>
                                            <tr>
                                                <th>{intl.formatMessage(LANG_COMMON_ID)}</th>
                                                <th>{intl.formatMessage(LANG_COMMON_STATUS)}</th>
                                                <th>{intl.formatMessage(LANG_SUBSCRIPTION_DURATION)}</th>
                                                <th>{intl.formatMessage(LANG_SUBSCRIPTION_RECEIPT)}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <>
                                                {subscription?.payments.length === 0 && (
                                                    <tr>
                                                        <td colSpan={4} className={"text-center"}>
                                                        {intl.formatMessage(LANG_COMMON_NO_RESULTS)}
                                                        </td>
                                                    </tr>
                                                )}
                                                {subscription?.payments.map((item: any) => (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <b>
                                                                {item.status === "PEN" && (
                                                                    <span className="text-warning">{intl.formatMessage(LANG_SUBSCRIPTION_STATUS_PENDING)}</span>
                                                                )}
                                                                {item.status === "PAI" && (
                                                                    <span className="text-success">{intl.formatMessage(LANG_SUBSCRIPTION_STATUS_PAID)}</span>
                                                                )}
                                                                {item.status === "CAN" && (
                                                                    <span className="text-danger">{intl.formatMessage(LANG_SUBSCRIPTION_STATUS_CANCELLED)}</span>
                                                                )}
                                                            </b>
                                                        </td>
                                                        <td>{moment(item.start_at).format("DD/MM/YYYY")} - {moment(item.end_at).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            <a
                                                                title={intl.formatMessage(LANG_SUBSCRIPTION_RECEIPT)}
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    alert("Não disponível no momento. Por favor, solicite através do suporte.")
                                                                }}>
                                                                <i className="bi bi-file-pdf-fill icon" style={{"color": "#25325b", "fontSize": "18px"}}/>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={{span: 3}}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_SUBSCRIPTION_PLAN)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div>
                                            <h4 style={{"color": "#4bb9c4"}}>{subscription?.plan.name}<span style={{"fontSize": "12px"}}>({(subscription?.payment_option.recurrency === "M") ? intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_M) : intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_Y)})</span></h4>
                                            <h6>{(subscription?.payment_option.recurrency === "M") ?
                                                `${intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_M)} - ${_formatCurrency(subscription?.payment_option.installment_price)}/${intl.formatMessage(LANG_COMMON_MONTH)}`:
                                                `${intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_Y)} - ${subscription?.payment_option.installments}x de ${_formatCurrency(subscription?.payment_option.installment_price)}`
                                            }</h6>
                                            <p>{subscription?.plan.description}</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {subscription.business.payment_file && (
                                    <Card className="mt-3">
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_SUBSCRIPTION_INVOICE)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                    <FileUploadProvider url={`/api/business/${subscription?.business_id}/payment-file`} onChange={() => {}}>
                                        <FileUploadProviderContext.Consumer>
                                            {({url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage}) => (
                                                <FileUploader
                                                    hasUploadError={hasUploadError}
                                                    hasDownloadError={false}
                                                    uploadErrorMessage={uploadErrorMessage}
                                                    download={download}
                                                    loading={loading}
                                                    edit={false}
                                                    url={url}
                                                    onChange={onChange}
                                                    onRemove={onRemove}
                                                    uploadUrl={`/api/business/${subscription?.business_id}/payment-file`}
                                                    removeUrl={""}/>
                                            )}
                                        </FileUploadProviderContext.Consumer>
                                    </FileUploadProvider>
                                    </Card.Body>
                                </Card>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default SubscriptionPage;
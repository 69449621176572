import {ProfileType} from "../types/UserType";

export function useAuth() {

    const PROFILE_MASTER = 1;
    const PROFILE_BUSINESS = 2;

    const isAuth = () => {
        if (window.localStorage.getItem("token")) {
            return true;
        }
        return false;
    };

    const setToken = (token: string) => {
        window.localStorage.setItem("token", token);
    };

    const getToken = () => {
        return window.localStorage.getItem("token") || "";
    };

    const setFeatures = (features: any) => {
        window.localStorage.setItem("features", JSON.stringify(features));
    };

    const getFeatures = () => {
        let features = window.localStorage.getItem("features");
        if (features) {
            return JSON.parse(features);
        }
        return false;
    };

    const hasAbility = (ability: string) => {
        let features = getFeatures();
        for(let i in features){
            if(features[i] === ability){
                return true;
            }
        }

        return false;
    }

    const setProfiles = (profiles: any) => {
        window.localStorage.setItem("profiles", JSON.stringify(profiles));
    };

    const getProfiles = () => {
        let profiles = window.localStorage.getItem("profiles");
        if (profiles) {
            return JSON.parse(profiles);
        }
        return false;
    };

    const getAllowedProfiles = (allowed: Array<number>) => {
        let profiles = getProfiles();
        let allowedProfiles: Array<ProfileType> = [];
        for(let i in profiles) {
            if(allowed.includes(profiles[i].id)){
                allowedProfiles.push(profiles[i]);
            }
        }

        return allowedProfiles;
    };

    const getProfile = (id: number) : ProfileType | undefined => {
        let profiles = getProfiles();
        for (let i in profiles) {
            if (profiles[i].id === id) {
                return profiles[i];
            }
        }

        return undefined;
    }

    const deleteToken = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("profiles");
        window.localStorage.removeItem("features");
    };

    return {
        isAuth,
        setToken,
        getToken,
        deleteToken,
        setFeatures,
        getFeatures,
        setProfiles,
        getProfiles,
        getAllowedProfiles,
        getProfile,
        hasAbility,
        PROFILE_MASTER,
        PROFILE_BUSINESS
    };
}

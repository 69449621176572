import React, {useState} from "react";
import {useStateList} from "../../hooks/useStateList";

const StateList = (props: any) => {
    const [state, setState] = useState(-1);
    const {getStateList} = useStateList();

    return (
        <select
            {...props}
            disabled={false}
            onChange={(e: any) => {
                setState(e.target.value);
                props.onChange(e.target.value);
            }}>
            <option value="">Estado</option>
            {getStateList().map((state: any) => {
                return (<option value={state.id} key={state.id}>{state.name} - {state.short_name}</option>);
            })}
        </select>
    );
};

export default StateList;
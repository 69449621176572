import React, {FC, useEffect, useReducer} from "react";
import {AuthReducer} from "../reducers/AuthReducer";
import {useAuth} from "../hooks/useAuth";
import {UserType} from "../types/UserType";
import {UserModel} from "../models/UserModel";
import {AuthType} from "../types/AuthType";
import {useNavigate} from "react-router-dom";
import LogRocket from "logrocket";

const DefaultAuthData: AuthType = {
    isAuth: false,
    setIsAuth: (isAuth: boolean) => {},
    termsAgreed: false,
    setTermsAgreed: (termsAgreed: boolean) => {},
    user: undefined,
    setUser: (user: UserType) => {},
    loading: false
};
const AuthContext = React.createContext(DefaultAuthData);
const AuthContextProvider: FC = ({children}) => {
    const {isAuth, deleteToken} = useAuth();
    const setIsAuth = (isAuth: boolean) => {
        dispatch({type: 'setIsAuth', isAuth: isAuth});
    }

    const setTermsAgreed = (termsAgreed: boolean | undefined) => {
        termsAgreed = (termsAgreed === undefined) ? false : termsAgreed;
        dispatch({type: 'setTermsAgreed', termsAgreed: termsAgreed});
    }

    const setUser = (user: UserType) => {
        try{
            LogRocket.identify(`${user.id}`, {
                name: user.name,
                email: user.email
              });
        } catch(e){}
        dispatch({type: 'setUser', user: user});
    }

    const loadUser = () => {
        dispatch({type: 'setLoading', loading: true});
        UserModel().get("self").then((loadedUser) => {
            dispatch({type: 'setLoading', loading: false});
            if(typeof loadedUser !== "boolean") {
                setUser(loadedUser);
                setTermsAgreed(loadedUser.terms_agreed);
            } else {
                if(window.confirm("Houve um erro! Deseja tentar novamente?")) {
                    window.location.reload();
                } else {
                    setIsAuth(false);
                    deleteToken();
                    window.location.reload();
                }
            }
        });
    }

    useEffect(() => {
        if(data.isAuth) {
            loadUser();
        }
    }, [])

    const [data, dispatch] = useReducer(AuthReducer, {
        ...DefaultAuthData,
        setIsAuth: setIsAuth,
        isAuth: isAuth(),
        setUser: setUser,
        setTermsAgreed: setTermsAgreed
    });
    return (<AuthContext.Provider value={data}>{children}</AuthContext.Provider>);
}

export {AuthContextProvider, AuthContext}
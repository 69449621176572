import {PairedPersonType, PairingResponse, PersonType} from "../types/PersonType";
import {useAPI} from "../hooks/useAPI";
import {OptionType, ResponseErrorType} from "../types/CommonType";
import {RodType} from "../types/RodType";

export function RodModel() {
    const {api} = useAPI();

    const create = async (personId: number, rod: RodType) => {
        return new Promise<boolean | RodType>((resolve) => {
            api()
                .post(`/api/person/${personId}/rod`, rod)
                .then((resp) => {
                    resolve(resp.data.rod);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (person_id: number, rod_id: number, rod: RodType) => {
        return new Promise<boolean | RodType>((resolve) => {
            api()
                .put(`/api/person/${person_id}/rod/${rod_id}`, rod)
                .then((resp) => {
                    resolve(resp.data.rod);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (person_id: number) => {
        return new Promise<boolean | Array<RodType>>((resolve) => {
            api()
                .get(`/api/person/${person_id}/rod`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (person_id: number, rod_id: number) => {
        return new Promise<RodType | boolean>((resolve) => {
            api()
                .get(`/api/person/${person_id}/rod/${rod_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (person_id: number, rod_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/person/${person_id}/rod/${rod_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove,
        list,
        get,
    };
 }
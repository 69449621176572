import React, {FC} from "react";
import {Button, Modal} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_AUTH_2FA_TITLE, LANG_COMMON_CANCEL, LANG_COMMON_CONFIRM, LANG_COMMON_NEXT } from "../../../lang";

interface Props {
    show: boolean
    qr_url: string
    secret: string
    onConfirm: () => void
    onHide: () => void
}
const Google2faModal: FC<Props> = ({show, onConfirm, onHide, secret, qr_url}) => {
    const intl = useIntl();
    return (
        <Modal
            show={show}
            onHide={onHide}
            className="modal-default"
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    {intl.formatMessage(LANG_AUTH_2FA_TITLE)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {intl.formatMessage(
                        {
                            defaultMessage: `Você precisa configurar a autenticação de dois fatores, use um aplicativo como o Authy ou Google Authenticator para escanear o código QR abaixo.
                            Alternativamente você pode utilizar a chave:`,
                            id: "auth.2fa_message"
                        }
                    )} {" "} <b>{secret}</b>
                </p>
                <img src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${qr_url}`} alt=""/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                    {intl.formatMessage(LANG_COMMON_CANCEL)}
                </Button>
                <Button variant="primary" onClick={onConfirm} className="btn-custom bg-custom-gradient">
                    {intl.formatMessage(LANG_COMMON_CONFIRM)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Google2faModal;
import {RodType} from "../types/RodType";

export function PersonReducer (state: any, action: any) {
    switch (action.type) {
        default:
            return state;
    }
}

export function PersonListReducer (state: any, action: any) {
    switch (action.type) {
        case "addPerson":
            state.persons.push(action.person);
            return {...state, persons: state.persons};
        case "updatePerson":
            for(let i in state.persons){
                if(state.persons[i].id === action.person.id) {
                    state.persons[i] = action.person;
                }
            }
            return {...state, persons: state.persons};
        case "removePerson":
            for(let i in state.persons){
                if(state.persons[i].id === action.person_id) {
                    state.persons.splice(i, 1);
                }
            }
            return {...state, persons: state.persons};
        case "setPersons":
            return {...state, persons: action.persons};
        default:
            return state;
    }
}

export function RodListReducer (state: any, action: any) {
    switch (action.type) {
        case "setRods":
            return {...state, rods: action.rods};
        default:
            return state;
    }
}

export function RodSelectListReducer (state: any, action: any) {
    switch (action.type) {
        case "addRodSelect":
            console.log(action.rod_select)
            state.rods_select.push(action.rod_select);
            return {...state, rods_select: state.rods_select};
        case "updateRodSelect":
            for(let i in state.rods_select){
                if(state.rods_select[i].index === action.rod_select.index) {
                    state.rods_select[i] = action.rod_select;
                }
            }
            return {...state, rods: state.rods};
        case "removeRodSelect":
            for(let i in state.rods_select){
                if(state.rods_select[i].index === action.index) {
                    state.rods_select.splice(i, 1);
                }
            }
            return {...state, rods: state.rods};
        case "setRodSelectList":
            return {...state, rods_select: action.rods_select};
        default:
            return state;
    }
}
import React, {FC, useState} from "react";
import {Form, Modal, Button, Alert, Row, Col} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {UserModel} from "../../../models/UserModel";
import {UserType} from "../../../types/UserType";
import ClipLoader from "react-spinners/ClipLoader";
import {useAuth} from "../../../hooks/useAuth";
import {AuthContext} from "../../../providers/AuthContextProvider";
import {RodType} from "../../../types/RodType";
import {RodModel} from "../../../models/RodModel";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_CODE, LANG_COMMON_NO, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_ERROR, LANG_PERSON_NEW_ROD, LANG_PERSON_ROD_FREEZING_DATE, LANG_PERSON_ROD_FRESH, LANG_PERSON_ROD_FROZEN, LANG_PERSON_ROD_OVULES_QTY, LANG_PERSON_ROD_TYPE, LANG_PERSON_ROD_USED, LANG_WARNING_FILL_ALL } from "../../../lang";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (rod: RodType, isUpdate: boolean) => void
    personId: number
    rod?: RodType
}
const RodAddModal: FC<Props> = ({show, onHide, onSave, personId, rod}) => {
    const intl = useIntl();
    const schema = yup.object().shape({
        code: yup.string().required(),
        freezing_date: yup.string().nullable(),
        collected: yup.boolean(),
        quantity: yup.number(),
        used: yup.boolean()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const initialRod: RodType = (rod !== undefined) ? rod : {
        id: -1,
        person_id: personId,
        code: "",
        freezing_date: "",
        collected: true,
        quantity: 0,
        used: false,
        type: "F"
    };

    const createRod = async (values: RodType) => {
        return RodModel().create(personId, values);
    }

    const updateRod = async (rod_id: number, values: RodType) => {
        return RodModel().update(personId, rod_id, values)
    }

    const onSubmit = async (values: RodType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        let createdRod: RodType | boolean;
        if(rod === undefined) {
            createdRod = await createRod(values);
        } else {
            createdRod = await updateRod(rod.id, values);
        }
        setLoading(false);

        // Saving result
        if(typeof createdRod !== "boolean") {
            onSave(createdRod, rod !== undefined);
        } else {
            setHasError(true);
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialRod}
            >
                {({
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      isValid,
                      errors}) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                            {intl.formatMessage(LANG_PERSON_NEW_ROD)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}><FormattedMessage {...LANG_ERROR} /></Alert>)}
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>{intl.formatMessage(LANG_COMMON_CODE)}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            placeholder={intl.formatMessage(LANG_COMMON_CODE)}
                                            value={values.code}
                                            onChange={handleChange}
                                            isValid={touched.code && !errors.code}
                                            isInvalid={!!errors.code && formWasSubmitted}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-8">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{intl.formatMessage(LANG_PERSON_ROD_TYPE)}</Form.Label>
                                        <Row>
                                            <Col lg={5}>
                                                <Form.Check
                                                    type="radio"
                                                    label={intl.formatMessage(LANG_PERSON_ROD_FRESH)}
                                                    onClick={(e) => {
                                                        setFieldValue("type", "F")
                                                    }}
                                                    checked={values.type === "F"}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Check
                                                    type="radio"
                                                    label={intl.formatMessage(LANG_PERSON_ROD_FROZEN)}
                                                    onClick={(e) => {
                                                        setFieldValue("type", "C")
                                                    }}
                                                    checked={values.type === "C"}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>{intl.formatMessage(LANG_PERSON_ROD_USED)}</Form.Label>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Check
                                                    type="radio"
                                                    label={intl.formatMessage(LANG_COMMON_YES)}
                                                    onClick={(e) => {
                                                        setFieldValue("used", true)
                                                    }}
                                                    checked={values.used}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Check
                                                    type="radio"
                                                    label={intl.formatMessage(LANG_COMMON_NO)}
                                                    onClick={(e) => {
                                                        setFieldValue("used", false)
                                                    }}
                                                    checked={!values.used}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </div>
                            </div>
                            {values.type === "C" && (
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>{intl.formatMessage(LANG_PERSON_ROD_OVULES_QTY)}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="quantity"
                                            placeholder={intl.formatMessage(LANG_PERSON_ROD_OVULES_QTY)}
                                            value={values.quantity}
                                            onChange={handleChange}
                                            isValid={touched.quantity && !errors.quantity}
                                            isInvalid={!!errors.quantity && formWasSubmitted}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{intl.formatMessage(LANG_PERSON_ROD_FREEZING_DATE)}</Form.Label>
                                        <InputMask
                                            name={"freezing_date"}
                                            type="text"
                                            mask="99/99/9999"
                                            value={values.freezing_date}
                                            onChange={(e) => {
                                                if(e.target.value !== null) {
                                                    setFieldValue("freezing_date", e.target.value);
                                                }
                                            }}
                                            className={clsx("form-control", {
                                                "is-invalid": touched.freezing_date && errors.freezing_date,
                                                "is-valid": !errors.freezing_date && formWasSubmitted
                                            })}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            )}

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                            {intl.formatMessage(LANG_COMMON_CANCEL)}
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /></> : intl.formatMessage(LANG_COMMON_SAVE)}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default RodAddModal;
export type OptionType = { label: string, value: any, isOptionDisabled?: boolean};

export type ResponseErrorType = {
    code: number
    message: string
    error: boolean
};

export function isResponseError(resp: any) {
    return resp.error !== undefined;
}

export type File = {
    id: number;
    name: string;
}
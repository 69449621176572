import React, {FC} from "react";
import { FormattedMessage } from "react-intl";
import ClipLoader from "react-spinners/ClipLoader";
import { LANG_COMMON_LOADING } from "../../lang";

interface Props {

}

const LoaderInside: FC<Props> = ({}) => {
    return (
        <div style={{"position": "relative", "width": "100%", "height": "100%"}}>
            <div style={{
                "fontSize": "1rem",
                "position": "absolute",
                "left": "45%",
                "top": "45%"
            }} className={"text-center"}>
                <ClipLoader size={"1rem"} /> <FormattedMessage {...LANG_COMMON_LOADING} />
            </div>
        </div>
    );
}

export default LoaderInside;
import React, {FC, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {useAPI} from "../../../hooks/useAPI";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_AUTH_2FA_OTP_ERROR, LANG_AUTH_2FA_TITLE, LANG_COMMON_CANCEL, LANG_COMMON_CODE, LANG_COMMON_CONFIRM, LANG_ERROR } from "../../../lang";

interface Props {
    show: boolean
    password: string
    email: string
    secret: string
    onConfirm: (resp: any) => void
    onHide: () => void
}
const Google2faOTPModal: FC<Props> = ({show, onConfirm, onHide, password, email, secret}) => {
    const intl = useIntl();
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const {doLogin} = useAPI();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        doLogin(email, password, {
            google2fa_secret: secret,
            otp: otp
        })
            .then((resp) => {
                setLoading(false);
                if(resp.data.otp_success) {
                    onConfirm(resp);
                } else {
                    setShowError(true);
                    setError(intl.formatMessage(LANG_AUTH_2FA_OTP_ERROR));
                }
            })
            .catch(() => {
                setLoading(false);
                setShowError(true);
                setError(intl.formatMessage(LANG_ERROR));
            })
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            className="modal-default"
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title id="example-modal-sizes-title-sm">
                        {intl.formatMessage(LANG_AUTH_2FA_TITLE)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showError && !loading && (<Alert variant={'danger'}>{error}</Alert>)}
                    <p><FormattedMessage 
                        id="auth.2fa_otp_message"
                        defaultMessage={'Informe abaixo o código que aparece no aplicativo configurado:'}
                        /></p>

                        <Form.Group>
                            <Form.Control
                                maxLength={6}
                                placeholder={intl.formatMessage(LANG_COMMON_CODE)}
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                        {intl.formatMessage(LANG_COMMON_CANCEL)}
                    </Button>
                    <Button variant="primary" type="submit" disabled={otp.length < 6 || loading} className="btn-custom bg-custom-gradient">
                        {loading ? <ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> : intl.formatMessage(LANG_COMMON_CONFIRM)}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default Google2faOTPModal;
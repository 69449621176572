import React, {createContext, FC, useEffect, useReducer} from "react";
import {useAPI} from "../hooks/useAPI";

// Reducer
function ImageUploadProviderReducer(state: any, action: any) {
    switch (action.type){
        case 'updateUrl':
            return {...state, url: action.url};
        case 'setLoading':
            return {...state, loading: action.loading};
        case 'setHasUploadError':
            return {...state, hasUploadError: action.hasUploadError};
        case 'setUploadErrorMessage':
            return {...state, uploadErrorMessage: action.uploadErrorMessage};
        case 'setHasDownloadError':
            return {...state, hasDownloadError: action.hasDownloadError};
        default:
            throw new Error();
    }
}

// Default data
interface ImageUploadProviderInterface {
    url: string | null | undefined
    onChange: (file: any, uploadUrl: string) => void
    onRemove: (removeUrl: string) => void
    loading: boolean
    hasUploadError: boolean
    uploadErrorMessage: string
    hasDownloadError: boolean
    upload: (file: any, uploadUrl: string) => void
    download: (url: string | null | undefined) => void
}
const defaultData = {
    url: null,
    onChange: (file: any, uploadUrl: string) => {},
    onRemove: (removeUrl: string) => {},
    loading: true,
    hasUploadError: false,
    uploadErrorMessage: "",
    hasDownloadError: false,
    upload: (file: any, uploadUrl: string) => {},
    download: (url: string | null | undefined) => {}
}

// Context
const ImageUploadProviderContext = createContext<ImageUploadProviderInterface>(defaultData);

interface Props {
    url: string | undefined
    onChange?: (url: string | null) => void
}
const ImageUploadProvider: FC<Props> = ({children, url, onChange=undefined}) => {
    const {api} = useAPI();
    const updateImage = (file: any, uploadUrl: string) => {
        dispatch({type: 'setLoading', loading: true});
        dispatch({type: 'setHasUploadError', hasUploadError: false});
        api().upload(uploadUrl, file).then((resp) => {
            if(resp.data.success === true) {
                download(url);
            } else {
                dispatch({type: 'setLoading', loading: false});
                dispatch({type: 'setHasUploadError', hasUploadError: true});
                dispatch({type: 'setUploadErrorMessage', uploadErrorMessage: resp.data.msg});
            }
        }, (err) => {
            dispatch({type: 'setLoading', loading: false});
            dispatch({type: 'setHasUploadError', hasUploadError: true});
            dispatch({type: 'setUploadErrorMessage', uploadErrorMessage: "Houve um erro desconhecido. Tente novamente, por favor!"});
        });
    }

    const download = (url: string | null | undefined) => {
        if(url !== null && url !== undefined) {
            dispatch({type: 'setLoading', loading: true});
            dispatch({type: 'setHasDownloadError', hasDownloadError: false});
            api().download(url).then((image) => {
                dispatch({type: 'setLoading', loading: false});
                if(typeof image === "number") {
                    if(image === 404) {
                        dispatch({type: 'updateUrl', url: null});
                    } else {
                        dispatch({type: 'setHasDownloadError', hasDownloadError: true});
                    }
                } else {
                    dispatch({type: 'updateUrl', url: image});
                }
            });
        } else {
            dispatch({type: 'updateUrl', url: url});
        }
    }

    const removeImage = (removeUrl: string) => {
        api().del(removeUrl).then((resp) => {
            dispatch({type: 'updateUrl', url: null});
            if(onChange !== undefined) {
                onChange(null);
            }
        }, (err) => {
            dispatch({type: 'setLoading', loading: false});
        });
    }

    useEffect(() => {
        download(url);
    }, [url]);

    const [imageData, dispatch] = useReducer(ImageUploadProviderReducer, {
        ...defaultData,
        url: url,
        onChange: updateImage,
        onRemove: removeImage,
        download: download,
        upload: updateImage
    });

    return (<ImageUploadProviderContext.Provider value={imageData}>{children}</ImageUploadProviderContext.Provider>);
}

export {ImageUploadProvider, ImageUploadProviderContext};
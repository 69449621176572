import React, { FC, useContext } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import 'moment/locale/pt-br';
import { useIntl } from "react-intl";
import { LANG_COMMON_PAIRINGS } from "../../../lang";
import { AuthContext } from "../../../providers/AuthContextProvider";
import { translateBusinessTypeTerms } from "../../../components/TranslateTerms";

interface Props {
    stats: any
}

const PairingsChart: FC<Props> = ({ stats }) => {
    const intl = useIntl();
    const authContext = useContext(AuthContext);
    const user = authContext.user;

    var height = 300;
    var labelColor = "#A1A5B7";
    var borderColor = "#E4E6EF";
    var baseColor = "#25325b";
    var lightColor = "#25325b";

    let pairingColor = "#2E93fA";
    let receiversColor = "#66DA26";
    let donorsColor = "#546E7A";

    var optionsApex: ApexOptions = {
        chart: {
            fontFamily: 'inherit',
            type: 'line',
            height: height,
            toolbar: {
                show: false
            }
        },
        plotOptions: {

        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            colors: [pairingColor, receiversColor, donorsColor],
            type: "solid"
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [pairingColor, receiversColor, donorsColor]
        },
        xaxis: {
            categories: stats.data.map((data: any) => {
                moment.locale('pt-br');
                return (stats.format === "Y-m") ? moment(data.date).format("MMM").toUpperCase() : moment(data.date).format("DD/MM");
            }),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            tickAmount: 6,
            labels: {
                rotate: 0,
                rotateAlways: true,
                style: {
                    colors: labelColor,
                    fontSize: '12px'
                }
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            tickAmount: 4,
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px'
                },
                formatter: function (val) {
                    return val.toString()
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px'
            },
            y: {
                formatter: function (val) {
                    return val.toString()
                }
            }
        },
        colors: [pairingColor, receiversColor, donorsColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            strokeColors: [pairingColor, receiversColor, donorsColor],
            strokeWidth: 3
        }
    };

    return (
        <>
            <ReactApexChart
                options={optionsApex}
                type="line"
                series={[
                    {
                        name: intl.formatMessage(LANG_COMMON_PAIRINGS),
                        data: stats.data.map((history: any) => {
                            return history.pairings;
                        })
                    },
                    {
                        name: intl.formatMessage(translateBusinessTypeTerms("receptors", user?.business?.type)),
                        data: stats.data.map((history: any) => {
                            return history.receivers;
                        })
                    },
                    {
                        name: intl.formatMessage(translateBusinessTypeTerms("donors", user?.business?.type)),
                        data: stats.data.map((history: any) => {
                            return history.donors;
                        })
                    }
                ]}
            />
        </>
    );
}

export default PairingsChart;
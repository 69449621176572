
const getLocale = () => {
    const locales = ['pt', 'en', 'es'];
  
    for(let i in locales) {
        if(window.location.pathname.split("/")[1].indexOf(locales[i]) >= 0) {
            return locales[i];
        }
    }
  
    return undefined;
}

function loadLocaleData(locale: string | undefined) { 
    switch (locale) {
        case 'en':
            return import('./compiled-lang/en/messages.json')
        default:
            return import('./compiled-lang/pt/messages.json')
    }
  }

export {getLocale, loadLocaleData};
import {UserType} from "../types/UserType";
import {useAPI} from "../hooks/useAPI";

export function UserModel() {
    const {api} = useAPI();

    const create = async (user: UserType) => {
        return new Promise<boolean | UserType>((resolve) => {
            api()
                .post("/api/user", user)
                .then((resp) => {
                    resolve(resp.data.user);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (user_id: number, user: UserType) => {
        return new Promise<boolean | UserType>((resolve) => {
            api()
                .put(`/api/user/${user_id}`, user)
                .then((resp) => {
                    resolve(resp.data.user);
                })
                .catch(() => resolve(false));
        });
    }

    const updateTermsAgreed = async (user_id: number, termsAgreed: boolean) => {
        return new Promise<boolean>((resolve) => {
            api()
                .put(`/api/user/${user_id}/terms`, {terms_agreed: termsAgreed})
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (business_id: number | undefined = undefined, search: string | undefined = "") => {
        return new Promise<boolean | Array<UserType>>((resolve) => {
            api()
                .get(`/api/user?business_id=${(business_id !== undefined) ? business_id : ""}&search=${search}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (user_id: number | string) => {
        return new Promise<UserType | boolean>((resolve) => {
            api()
                .get(`/api/user/${user_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (user_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/user/${user_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        updateTermsAgreed
    };
 }
import React, {FC, useState} from "react";
import {Button} from "react-bootstrap";
import {PlanType} from "../../types/PlanType";
import NumberFormat from "react-number-format";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_MONTH, LANG_SUBSCRIPTION_RECURRENCY_M, LANG_SUBSCRIPTION_RECURRENCY_Y } from "../../lang";

interface Props {
    onChoose: (plan: PlanType, paymentOptionId: string) => void
    plan: PlanType
}
const SubscriptionPlanCard: FC<Props> = ({ onChoose, plan}) => {
    const intl = useIntl();
    const [paymentOptionId, setPaymentOptionId] = useState<string>(plan.payment_options[0]?.id.toString());

    const _formatCurrency = (amount: number) => {
        return "R$" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    };

    return (
        <>
            <div className="plan-card">

                <div className={'p-3'}>
                    <h6>{plan.name}</h6>
                    <select
                        onChange={(e) => setPaymentOptionId(e.target.value)}
                        value={paymentOptionId}
                        className="form-control form-select">
                        {plan.payment_options.map((item) => (
                            <option value={item.id} key={item.id} selected={item.id.toString() === paymentOptionId}>
                                {(item.recurrency === "M") ?
                                    `${intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_M)} - ${_formatCurrency(item.installment_price)}/${intl.formatMessage(LANG_COMMON_MONTH)}`:
                                    `${intl.formatMessage(LANG_SUBSCRIPTION_RECURRENCY_Y)} - ${item.installments}x ${_formatCurrency(item.installment_price)} (${_formatCurrency(item.total / 12)}/${intl.formatMessage(LANG_COMMON_MONTH)})`
                                }
                            </option>
                        ))}
                    </select>
                    <hr/>
                    <p className="py-1 mb-2">{plan.description}</p>
                    <div className="d-grid gap-2">
                        <Button
                            variant="primary"
                            className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100"
                            onClick={() => onChoose(plan, paymentOptionId)}
                        >{intl.formatMessage(LANG_COMMON_CHOOSE)}</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPlanCard;
import {UserType} from "../types/UserType";
import {useAPI} from "../hooks/useAPI";
import {BusinessType} from "../types/BusinessType";

export function BusinessModel() {
    const {api} = useAPI();

    const create = async (business: BusinessType) => {
        return new Promise<boolean | BusinessType>((resolve) => {
            api()
                .post("/api/business", business)
                .then((resp) => {
                    resolve(resp.data.business);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (business_id: number, business: BusinessType) => {
        return new Promise<boolean | BusinessType>((resolve) => {
            api()
                .put(`/api/business/${business_id}`, business)
                .then((resp) => {
                    resolve(resp.data.business);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (business_id: number | string) => {
        return new Promise<BusinessType | boolean>((resolve) => {
            api()
                .get(`/api/business/${business_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (search: string | undefined = "") => {
        return new Promise<boolean | Array<BusinessType>>((resolve) => {
            api()
                .get(`/api/business?search=${search}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (business_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/business/${business_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const cancelSubscription = async (business_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/business/${business_id}/subscription`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const addTrialSubscription = async (business_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .put(`/api/business/${business_id}/subscription/trial`, {})
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        cancelSubscription,
        addTrialSubscription
    };
 }
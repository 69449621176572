export function useStateList() {
    const stateList = [
        {'id':1,'name': 'Acre','short_name':'AC'},
        {'id':2,'name': 'Alagoas','short_name':'AL'},
        {'id':3,'name': 'Amazonas','short_name':'AM'},
        {'id':4,'name': 'Amapá','short_name':'AP'},
        {'id':5,'name': 'Bahia','short_name':'BA'},
        {'id':6,'name': 'Ceará','short_name':'CE'},
        {'id':7,'name': 'Distrito Federal','short_name':'DF'},
        {'id':8,'name': 'Espírito Santo','short_name':'ES'},
        {'id':9,'name': 'Goias','short_name':'GO'},
        {'id':10,'name': 'Maranhão','short_name':'MA'},
        {'id':11,'name': 'Minas Gerais','short_name':'MG'},
        {'id':12,'name': 'Mato Grosso do Sul','short_name':'MS'},
        {'id':13,'name': 'Mato Grosso','short_name':'MT'},
        {'id':14,'name': 'Pará','short_name':'PA'},
        {'id':15,'name': 'Paraíba','short_name':'PB'},
        {'id':16,'name': 'Pernambuco','short_name':'PE'},
        {'id':17,'name': 'Piauí','short_name':'PI'},
        {'id':18,'name': 'Paraná','short_name':'PR'},
        {'id':19,'name': 'Rio de Janeiro','short_name':'RJ'},
        {'id':20,'name': 'Rio Grande do Norte','short_name':'RN'},
        {'id':21,'name': 'Rondônia','short_name':'RO'},
        {'id':22,'name': 'Roraima','short_name':'RR'},
        {'id':23,'name': 'Rio Grande do Sul','short_name':'RS'},
        {'id':24,'name': 'Santa Catarina','short_name':'SC'},
        {'id':25,'name': 'Sergipe','short_name':'SE'},
        {'id':26,'name': 'São Paulo','short_name':'SP'},
        {'id':27,'name': 'Tocantins','short_name':'TO'}
    ];

    const getStateList = () => {
        return stateList;
    };

    const getStateShort = (stateId: any) => {
        if(stateId === null || stateId === undefined) return "";
        return stateList.map((state: any) => {
            if(stateId === state.id){
                return state.short_name;
            }
        });
    }

    const getStateName = (stateId: any) => {
        if(stateId === null || stateId === undefined) return "";
        return stateList.map((state: any) => {
            if(stateId === state.id){
                return state.name;
            }
        });
    }

    return {
        getStateList,
        getStateShort,
        getStateName
    };
}
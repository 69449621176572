import React, {FC} from "react";
import { useIntl } from "react-intl";
import { OptionType } from "../types/CommonType";


interface PersonOptionsType {
    getRHFactorTypes: () => OptionType[],
    getRaceTypes: () => OptionType[],
    getSkinColors: () => OptionType[],
    getEyesColors: () => OptionType[],
    getHairColors: () => OptionType[],
    getHairTypes: () => OptionType[],
    getSchooling: () => OptionType[],
}

interface OptionsType {
    personOptions?: PersonOptionsType
}

const DefaultOptionsData: OptionsType = {};
const OptionsContext = React.createContext(DefaultOptionsData);
const OptionsContextProvider: FC = ({children}) => {
    const intl = useIntl();
    
    const Options: OptionsType = {
        personOptions: {
            getRHFactorTypes() {
                return [
                    {label: intl.formatMessage({id: 'person.rh_factor.positive', defaultMessage: "Positivo", description: "Fator RH"}), value: "P"},
                    {label: intl.formatMessage({id: 'person.rh_factor.negative', defaultMessage: "Negativo", description: "Fator RH"}), value: "N"},
                ];
            },
            getRaceTypes() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.race.white', defaultMessage: "Branco", description: "Etnia"}), value: "B"},
                    {label: intl.formatMessage({id: 'person.race.black', defaultMessage: "Negro", description: "Etnia"}), value: "N"},
                    {label: intl.formatMessage({id: 'person.race.brown', defaultMessage: "Pardo", description: "Etnia"}), value: "P"},
                    {label: intl.formatMessage({id: 'person.race.asian', defaultMessage: "Asiático", description: "Etnia"}), value: "A"},
                    {label: intl.formatMessage({id: 'person.race.indian', defaultMessage: "Indígena", description: "Etnia"}), value: "I"},
                ];
            },
        
            getSkinColors() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.skin_color.type1', defaultMessage: "Tipo I (Branca-pálida)"}), value: "1"},
                    {label: intl.formatMessage({id: 'person.skin_color.type2', defaultMessage: "Tipo II (Branca)"}), value: "2"},
                    {label: intl.formatMessage({id: 'person.skin_color.type3', defaultMessage: "Tipo III (Branca - Bronzeia)"}), value: "3"},
                    {label: intl.formatMessage({id: 'person.skin_color.type4', defaultMessage: "Tipo IV (Castanha-clara)"}), value: "4"},
                    {label: intl.formatMessage({id: 'person.skin_color.type5', defaultMessage: "Tipo V (Parda)"}), value: "5"},
                    {label: intl.formatMessage({id: 'person.skin_color.type6', defaultMessage: "Tipo VI (Negra)"}), value: "6"},
                ];
            },
            getEyesColors() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.eyes_color.blue', defaultMessage: "Azul", description: "Cor do olho"}), value: "A"},
                    {label: intl.formatMessage({id: 'person.eyes_color.blue_green', defaultMessage: "Azul-esverdeado", description: "Cor do olho"}), value: "AE"},
                    {label: intl.formatMessage({id: 'person.eyes_color.gray', defaultMessage: "Cinza", description: "Cor do olho"}), value: "CI"},
                    {label: intl.formatMessage({id: 'person.eyes_color.green', defaultMessage: "Verde", description: "Cor do olho"}), value: "V"},
                    {label: intl.formatMessage({id: 'person.eyes_color.brown_green', defaultMessage: "Castanho esverdeado", description: "Cor do olho"}), value: "CEV"},
                    {label: intl.formatMessage({id: 'person.eyes_color.honey', defaultMessage: "Mel", description: "Cor do olho"}), value: "MEL"},
                    {label: intl.formatMessage({id: 'person.eyes_color.brow_weak', defaultMessage: "Castanho claro", description: "Cor do olho"}), value: "CC"},
                    {label: intl.formatMessage({id: 'person.eyes_color.brown_strong', defaultMessage: "Castanho escuro", description: "Cor do olho"}), value: "CE"},
                ];
            },
            getHairColors() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.hair_color.blonde', defaultMessage: "Loiro", description: "Cor do cabelo"}), value: "LOI"},
                    {label: intl.formatMessage({id: 'person.hair_color.red', defaultMessage: "Ruivo", description: "Cor do cabelo"}), value: "RUI"},
                    {label: intl.formatMessage({id: 'person.hair_color.brown_weak', defaultMessage: "Castanho claro", description: "Cor do cabelo"}), value: "CAC"},
                    {label: intl.formatMessage({id: 'person.hair_color.brown_strong', defaultMessage: "Castanho escuro", description: "Cor do cabelo"}), value: "CAE"},
                    {label: intl.formatMessage({id: 'person.hair_color.black', defaultMessage: "Preto", description: "Cor do cabelo"}), value: "PRE"},
                ];
            },
            getHairTypes() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.hair_type.straight', defaultMessage: "Liso", description: "Tipo do cabelo"}), value: "LIS"},
                    {label: intl.formatMessage({id: 'person.hair_type.wavy', defaultMessage: "Ondulado", description: "Tipo do cabelo"}), value: "OND"},
                    {label: intl.formatMessage({id: 'person.hair_type.curly', defaultMessage: "Cacheado", description: "Tipo do cabelo"}), value: "CAC"},
                    {label: intl.formatMessage({id: 'person.hair_type.very_curly', defaultMessage: "Crespo", description: "Tipo do cabelo"}), value: "CRE"},
                ];
            },
            getSchooling() : Array<OptionType> {
                return [
                    {label: intl.formatMessage({id: 'person.schooling.graduated', defaultMessage: "Superior Completo", description: "Escolaridade"}), value: "1"},
                    {label: intl.formatMessage({id: 'person.schooling.not_graduated', defaultMessage: "Superior Incompleto", description: "Escolaridade"}), value: "2"},
                    {label: intl.formatMessage({id: 'person.schooling.complete_high_school', defaultMessage: "Ensino Médio Completo", description: "Escolaridade"}), value: "3"},
                    {label: intl.formatMessage({id: 'person.schooling.incomplete_high_school', defaultMessage: "Ensino Médio Incompleto", description: "Escolaridade"}), value: "4"},
                    {label: intl.formatMessage({id: 'person.schooling.complete_primary_education', defaultMessage: "Ensino Fundamental Completo", description: "Escolaridade"}), value: "5"},
                    {label: intl.formatMessage({id: 'person.schooling.incomplete_primary_education', defaultMessage: "Ensino Fundamental Incompleto", description: "Escolaridade"}), value: "6"},
                ];
            }
        }
    }


    return (<OptionsContext.Provider value={Options}>{children}</OptionsContext.Provider>);
}

export {OptionsContextProvider, OptionsContext}
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PersonModel } from "../../models/PersonModel";
import { PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import InputMask from "react-input-mask";
import clsx from "clsx";
import moment from "moment";
import { useStateList } from "../../hooks/useStateList";
import { PairingModel } from "../../models/PairingModel";
import { PairingType } from "../../types/PairingType";
import { useAPI } from "../../hooks/useAPI";
import download from "downloadjs";
import TranslateTerms, { translateBusinessTypeTerms } from "../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_CITY, LANG_COMMON_BACK, LANG_COMMON_BORN_ALIVE, LANG_COMMON_DATA, LANG_COMMON_FEMALE, LANG_COMMON_MALE, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_PAIRINGS, LANG_COMMON_SIMILARITY, LANG_COMMON_YES, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HEIGHT, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_RODS, LANG_PERSON_SCHOOLING, LANG_PERSON_SKIN_COLOR, LANG_PERSON_WEIGHT, LANG_STATE } from "../../lang";
import { AuthContext } from "../../providers/AuthContextProvider";
import { OptionsContext } from "../../providers/OptionsContextProvider";

const PairingViewPage = () => {
    const intl = useIntl();
    const auth = useContext(AuthContext);
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [saving, setSaving] = useState(false);
    const [pairing, setPairing] = useState<PairingType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { getStateName, getStateShort } = useStateList();
    const [showChildView, setShowChildView] = useState(false);
    const { api } = useAPI();

    const calculateAge = (birth_date: string | undefined) => {
        if (birth_date === undefined) return "0";
        const moment_birth_date = moment(birth_date, 'DD/MM/YYYY');
        if (moment_birth_date.isValid()) {
            return moment().diff(moment_birth_date, 'years');
        }
        return 0;
    }

    const calculateBmi = (height: string | undefined, weight: string | undefined) => {
        if (weight !== undefined && height !== undefined)
            return (parseFloat(weight) / Math.pow(parseFloat(height), 2)).toFixed(2);

        return undefined
    }

    const loadPairing = () => {
        setLoading(true);
        if (id !== undefined) {
            PairingModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setPairing(resp);
                }
                setLoading(false);
            });
        }

    }

    const downloadPdf = (id: number | string) => {
        setLoadingPdf(true);
        api().downloadPDF(`/api/pairing/${id}?print=Y`).then(response => {
            const content = response.headers['content-type'];
            download(response.data, "pareamento", content);
            setLoadingPdf(false);
        })
            .catch(error => {
                setLoadingPdf(false);
            });
    }

    const schema = yup.object().shape({
        schooling: yup.string().nullable(),
        has_children: yup.boolean().nullable(),
        hobbies: yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            schooling: "",
            has_children: undefined,
            hobbies: ""
        },
        validationSchema: schema,
        onSubmit: () => { },
        validateOnMount: false
    });

    useEffect(() => {
        loadPairing();
    }, [id]);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {loadingPdf && (<Loader fullscreen={true} />)}
            {pairing && (
                <div className="bg-pattern">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to={'/admin/pairing'}>{intl.formatMessage(LANG_COMMON_PAIRINGS)}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>N° {pairing.id}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{ offset: 0 }}>
                                <Card>
                                    <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                    {intl.formatMessage(LANG_COMMON_DATA)} {" "}
                                                </div>
                                                <div className="text-end col-md-6">
                                                    <a
                                                        href="#"
                                                        className="btn-icon"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (id !== undefined)
                                                                downloadPdf(id);
                                                        }}
                                                    ><i className="bi bi-printer" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5 className="person-name">
                                                        {pairing.receiver_person?.name} {"<>"} {pairing.donor_person?.name} - {moment(pairing.created_at).format('DD/MM/YYYY')}
                                                        <div>
                                                            <label className="form-label mt-3">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={showChildView}
                                                                    onChange={() => setShowChildView(!showChildView)} />
                                                                {" "}
                                                                <FormattedMessage
                                                                    id="pairing.show_kid_picture"
                                                                    defaultMessage={"Exibir foto de criança"}
                                                                />
                                                            </label>
                                                        </div>
                                                    </h5>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <ImageUploadProvider url={(!showChildView) ? `/api/person/${pairing.receiver}/photo` : `/api/person/${pairing.receiver}/photo/child`} onChange={() => { }}>
                                                            <ImageUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download }) => (
                                                                    <ImageUploader
                                                                        photoLabel={intl.formatMessage(translateBusinessTypeTerms('receptor', auth.user?.business?.type))}
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={hasDownloadError}
                                                                        download={download}
                                                                        loading={loading}
                                                                        edit={false}
                                                                        height={175}
                                                                        width={145}
                                                                        url={url}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={`/api/person/${pairing.receiver}/photo`}
                                                                        removeUrl={""} />
                                                                )}
                                                            </ImageUploadProviderContext.Consumer>
                                                        </ImageUploadProvider>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <ImageUploadProvider url={(!showChildView) ? `/api/person/${pairing.donor}/photo` : `/api/person/${pairing.donor}/photo/child`} onChange={() => { }}>
                                                            <ImageUploadProviderContext.Consumer>
                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download }) => (
                                                                    <ImageUploader
                                                                        photoLabel={intl.formatMessage(translateBusinessTypeTerms('donor', auth.user?.business?.type))}
                                                                        hasUploadError={hasUploadError}
                                                                        hasDownloadError={hasDownloadError}
                                                                        download={download}
                                                                        loading={loading}
                                                                        edit={false}
                                                                        height={175}
                                                                        width={145}
                                                                        url={url}
                                                                        onChange={onChange}
                                                                        onRemove={onRemove}
                                                                        uploadUrl={`/api/person/${pairing.donor}/photo`}
                                                                        removeUrl={""} />
                                                                )}
                                                            </ImageUploadProviderContext.Consumer>
                                                        </ImageUploadProvider>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><TranslateTerms term="receptor" /></Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.name} ({pairing.receiver_person?.medical_record_number})</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_CITY)} ({intl.formatMessage(LANG_STATE)})</Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.city?.name} ({pairing.receiver_person?.city?.state.short_name})</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_SKIN_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.receiver_person?.skin_color, options.personOptions?.getSkinColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_EYES_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.receiver_person?.eyes_color, options.personOptions?.getEyesColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.receiver_person?.hair_color, options.personOptions?.getHairColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_TYPE)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.receiver_person?.hair_type, options.personOptions?.getHairTypes())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_AGE)}</Form.Label>
                                                        <div className="view-data">{calculateAge(pairing.receiver_person?.birth_date)} anos</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HEIGHT)}</Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.height_number}m</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_WEIGHT)}</Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.weight}kg</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAS_CHILDREN)}</Form.Label>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div className="view-data">{(pairing.receiver_person?.has_children) ? intl.formatMessage(LANG_COMMON_YES) : intl.formatMessage(LANG_COMMON_NO)}</div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_SCHOOLING)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.receiver_person?.schooling, options.personOptions?.getSchooling())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_OCCUPATION)}</Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.occupation}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HOBBIES)}</Form.Label>
                                                        <div className="view-data">{pairing.receiver_person?.hobbies}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_COMMON_BORN_ALIVE)}</Form.Label>
                                                        <div className="view-data">
                                                            <div className="born-alive-list">
                                                                <ul>
                                                                    {pairing.borns_alive?.map((bornAlive) => (
                                                                        <>
                                                                            <li>{bornAlive.gender === "F" ? intl.formatMessage(LANG_COMMON_FEMALE) : intl.formatMessage(LANG_COMMON_MALE)} - {bornAlive.birth_date}</li>
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><TranslateTerms term="donor" /></Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.name} ({pairing.donor_person?.medical_record_number})</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_CITY)} ({intl.formatMessage(LANG_STATE)})</Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.city?.name} ({pairing.donor_person?.city?.state.short_name})</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_SKIN_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.donor_person?.skin_color, options.personOptions?.getSkinColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_EYES_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.donor_person?.eyes_color, options.personOptions?.getEyesColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_COLOR)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.donor_person?.hair_color, options.personOptions?.getHairColors())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAIR_TYPE)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.donor_person?.hair_type, options.personOptions?.getHairTypes())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_AGE)}</Form.Label>
                                                        <div className="view-data">{calculateAge(pairing.donor_person?.birth_date)} anos</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HEIGHT)}</Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.height_number}m</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_WEIGHT)}</Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.weight}kg</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAS_CHILDREN)}</Form.Label>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div className="view-data">{(pairing.donor_person?.has_children) ? intl.formatMessage(LANG_COMMON_YES) : intl.formatMessage(LANG_COMMON_NO)}</div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_SCHOOLING)}</Form.Label>
                                                        <div className="view-data">{PersonModel().getOption(pairing.donor_person?.schooling, options.personOptions?.getSchooling())?.label}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_OCCUPATION)}</Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.occupation}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_HOBBIES)}</Form.Label>
                                                        <div className="view-data">{pairing.donor_person?.hobbies}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{intl.formatMessage(LANG_PERSON_RODS)}</Form.Label>
                                                        <div className="view-data">{pairing.rods?.map((pairingRod) => {
                                                            return `${pairingRod.rod.code}`;
                                                        }).join(", ")}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>{intl.formatMessage(LANG_COMMON_SIMILARITY)}</Form.Label>
                                                        <div className="view-data">{pairing.similarity}%</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={{ span: 3 }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-8 offset-2">
                                                <Link to="/admin/pairing">
                                                    <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default PairingViewPage;
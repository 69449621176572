import React, { useContext, useEffect, useReducer, useState } from "react";
// @ts-ignore
import Sticky from 'react-stickynode';
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Image, Badge } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import PersonsSelect from "../../components/PersonsSelect";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PersonModel } from "../../models/PersonModel";
import PairedPersonCard from "./PairedPersonCard";
import Loader from "../../components/messages/Loader";
import { PairedPersonType, PairingResponse } from "../../types/PersonType";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import "./styles/pairing-add-page.css";
import { RodSelectItem, RodSelectList, RodType } from "../../types/RodType";
import { RodModel } from "../../models/RodModel";
import { RodSelectListReducer } from "../../reducers/PersonReducer";
import RodsSelectList from "./components/RodsSelectList";
import { PairingModel } from "../../models/PairingModel";
import { toast } from "react-toastify";
import { useAPI } from "../../hooks/useAPI";
import download from 'downloadjs';
import Error from "../../components/messages/Error";
import { useAuth } from "../../hooks/useAuth";
import TranslateTerms from "../../components/TranslateTerms";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_ALL, LANG_COMMON_AVAILABLE, LANG_COMMON_BACK, LANG_COMMON_CHOOSE, LANG_COMMON_DATA, LANG_COMMON_NEW, LANG_COMMON_PAIRING, LANG_COMMON_PAIRINGS, LANG_COMMON_SAVE, LANG_ERROR, LANG_PERSON_BLOOD_TYPE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_RH_FACTOR, LANG_PERSON_SKIN_COLOR, LANG_ROD_AVAILABILITY } from "../../lang";

const PairingAddPage = () => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const { id } = useParams();
    const location = useLocation();
    const [pairingResult, setPairingResult] = useState<PairingResponse | undefined>(undefined);
    const [showChildView, setShowChildView] = useState(false);
    const [loadingPairing, setLoadingPairing] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams(location.search);
    const [bloodType, setBloodType] = useState<string>(searchParams.get("blood_type") || "");
    const [availableOnly, setAvailableOnly] = useState<boolean>(true);
    const [rhFactor, setRhFactor] = useState<string>(searchParams.get("rh_factor") || "");
    const [race, setRace] = useState<string>(searchParams.get("race") || "");
    const [skinColor, setSkinColor] = useState<string>(searchParams.get("skin_color") || "");
    const [hairColor, setHairColor] = useState<string>(searchParams.get("hair_color") || "");
    const [hairType, setHairType] = useState<string>(searchParams.get("hair_type") || "");
    const [eyesColor, setEyesColor] = useState<string>(searchParams.get("eyes_color") || "");
    const [height, setHeight] = useState<string>(searchParams.get("height") || "");
    const [filters, setFilters] = useState({
        skin_color: skinColor,
        hair_color: hairColor,
        hair_type: hairType,
        eyes_color: eyesColor,
        height,
        available_only: availableOnly
    });

    const [donor, setDonor] = useState<number | undefined>(undefined);
    const [receiver, setReceiver] = useState<number | undefined>(undefined);

    const [highestFaceSimilarityPerson, setHighestFaceSimilarityPerson] = useState<PairedPersonType | undefined>(undefined);
    const [rodList, setRodList] = useState<string>("");
    const [saving, setSaving] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [errorPdf, setErrorPdf] = useState(false);
    const [selectedPersonIds, setSelectedPersonIds] = useState<number[]>([]);

    const navigate = useNavigate();
    const { hasAbility } = useAuth();
    const { api } = useAPI();

    const loadPairing = (
        bloodType: string | null,
        rhFactor: string | null,
        race: string | null,
        filters: any = {}
    ) => {
        if (id !== undefined) {
            setSearchParams({
                rh_factor: (rhFactor !== null) ? rhFactor : "",
                blood_type: (bloodType !== null) ? bloodType : "",
                race: (race !== null) ? race : "",
                ...filters
            }, { replace: true });

            setLoadingPairing(true);
            PersonModel().pairing(id, {
                blood_type: bloodType,
                rh_factor: rhFactor,
                race: race,
                ...filters
            }).then((resp) => {
                if (typeof resp !== "boolean") {
                    setSelectedPersonIds(resp.candidates.map((person) => person.id));
                    setHighestFaceSimilarityPerson(getHighestFaceSimilarityPerson(resp.candidates))
                    setPairingResult(resp);
                    if (resp.reference.type === 'R') {
                        setReceiver(resp.reference.id);
                    } else {
                        setDonor(resp.reference.id);
                    }
                }

                setLoadingPairing(false);
                setLoadingPage(false);
            })
        }
    }

    const savePairing = () => {
        if (id !== undefined) {
            setSaving(true);
            PairingModel().create((receiver !== undefined) ? receiver : -1, {
                'donor': (donor !== undefined) ? donor : -1,
                'rod_list': rodList,
                'blood_type': bloodType,
                'rh_factor': rhFactor,
                'race': race
            }).then((resp) => {
                setSaving(false);
                toast.success("Pareamento salvo com sucesso!");
                navigate("/admin/pairing");
            });
        }
    }

    const downloadPdf = () => {
        if (id !== undefined) {
            setLoadingPdf(true);
            setErrorPdf(false);
            api().downloadPDF(PersonModel().getPairingUrl(id, {
                'donor': (donor !== undefined) ? donor : -1,
                'rod_list': rodList,
                'blood_type': bloodType,
                'rh_factor': rhFactor,
                'race': race,
                'print': 'Y',
                'selected': selectedPersonIds.join(","),
                ...filters
            })).then(response => {
                const content = response.headers['content-type'];
                download(response.data, "pareamento", content);
                setLoadingPdf(false);
            })
                .catch(error => {
                    setLoadingPdf(false);
                    setErrorPdf(true);
                });
        }
    }

    const getHighestFaceSimilarityPerson = (persons: Array<PairedPersonType>) => {
        let highestSimilarity = 0;
        let person = undefined;
        for (let i in persons) {
            if (parseFloat(persons[i].face_similarity) > highestSimilarity) {
                highestSimilarity = parseFloat(persons[i].face_similarity);
                person = persons[i];
            }
        }

        return person;
    }

    useEffect(() => {
        loadPairing(bloodType, rhFactor, race, filters);
    }, []);

    return (
        <>
            {loadingPage && (<Loader fullscreen={true} />)}
            {(saving || loadingPdf) && (<Loader fullscreen={true} />)}
            {errorPdf && (<Error message={intl.formatMessage(LANG_ERROR)} show={errorPdf} onHide={() => setErrorPdf(false)} />)}
            {!loadingPage && (
                <div className="bg-pattern pairing-add-page">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active><Link to={'/admin/pairing'}>{intl.formatMessage(LANG_COMMON_PAIRINGS)}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>{intl.formatMessage(LANG_COMMON_NEW)}{" "}{intl.formatMessage(LANG_COMMON_PAIRING)}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <Card>
                                    <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                    {intl.formatMessage(LANG_COMMON_DATA)} {" "}
                                                </div>
                                                <div className="text-end col-md-6">
                                                    <a
                                                        href="#"
                                                        className="btn-icon"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadPdf();
                                                        }}
                                                    ><i className="bi bi-printer" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={{ span: 3 }}>
                                                <ImageUploadProvider url={(!showChildView) ? `/api/person/${pairingResult?.reference.id}/photo` : `/api/person/${pairingResult?.reference.id}/photo/child`} onChange={() => { }}>
                                                    <ImageUploadProviderContext.Consumer>
                                                        {({ url, onChange, onRemove, loading, hasDownloadError, hasUploadError, download }) => (
                                                            <ImageUploader
                                                                hasUploadError={hasUploadError}
                                                                hasDownloadError={hasDownloadError}
                                                                download={download}
                                                                loading={loading}
                                                                height={304}
                                                                width={304}
                                                                url={url}
                                                                onChange={onChange}
                                                                onRemove={onRemove}
                                                                uploadUrl={`/api/person/${pairingResult?.reference.id}/photo`}
                                                                edit={false}
                                                                removeUrl={""}
                                                                photoLabel={pairingResult?.reference.name}
                                                                photoSubLabel=""
                                                            />
                                                        )}
                                                    </ImageUploadProviderContext.Consumer>
                                                </ImageUploadProvider>
                                                <div className="px-3">
                                                    <label className="form-label mt-3">
                                                        <input
                                                            type="checkbox"
                                                            checked={showChildView}
                                                            onChange={() => setShowChildView(!showChildView)} />
                                                        {" "}
                                                        <FormattedMessage
                                                            id="pairing.show_kid_picture"
                                                            defaultMessage={"Exibir foto de criança"}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="p-3">
                                                    <hr style={{ "marginTop": 0 }} />
                                                    <label className="form-label"><FormattedMessage {...LANG_ROD_AVAILABILITY} />:</label>
                                                    <CustomSelect
                                                        name="available_only"
                                                        options={[
                                                            { "label": <FormattedMessage {...LANG_COMMON_AVAILABLE} />, "value": true },
                                                            { "label": <FormattedMessage {...LANG_COMMON_ALL} />, "value": false }
                                                        ]}
                                                        onChange={(option: OptionType) => {
                                                            setAvailableOnly(option.value);
                                                            setFilters({
                                                                ...filters,
                                                                available_only: option.value
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                available_only: option.value
                                                            });
                                                        }}
                                                        value={availableOnly}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={false}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />
                                                    <label className="form-label"><FormattedMessage {...LANG_PERSON_BLOOD_TYPE} />:</label>
                                                    <CustomSelect
                                                        name="blood_type"
                                                        options={PersonModel().getBloodTypes()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setBloodType(selected);
                                                            loadPairing(selected, rhFactor, race, filters);
                                                        }}
                                                        value={bloodType}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />
                                                    <label className="form-label"><FormattedMessage {...LANG_PERSON_RH_FACTOR} />:</label>
                                                    <CustomSelect
                                                        name="rh_factor"
                                                        options={options.personOptions?.getRHFactorTypes()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setRhFactor(selected);
                                                            loadPairing(bloodType, selected, race, filters);
                                                        }}
                                                        value={rhFactor}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_RACE} />:</label>
                                                    <CustomSelect
                                                        name="blood_type"
                                                        options={options.personOptions?.getRaceTypes()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setRace(selected);
                                                            loadPairing(bloodType, rhFactor, selected, filters);
                                                        }}
                                                        value={race}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_SKIN_COLOR} />:</label>
                                                    <CustomSelect
                                                        name="skin_color"
                                                        options={options.personOptions?.getSkinColors()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setSkinColor(selected);
                                                            setFilters({
                                                                ...filters,
                                                                skin_color: selected
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                skin_color: selected
                                                            });
                                                        }}
                                                        value={skinColor}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_HAIR_COLOR} />:</label>
                                                    <CustomSelect
                                                        name="hair_color"
                                                        options={options.personOptions?.getHairColors()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setHairColor(selected);
                                                            setFilters({
                                                                ...filters,
                                                                hair_color: selected
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                hair_color: selected
                                                            });
                                                        }}
                                                        value={hairColor}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_HAIR_TYPE} />:</label>
                                                    <CustomSelect
                                                        name="hair_type"
                                                        options={options.personOptions?.getHairTypes()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setHairType(selected);
                                                            setFilters({
                                                                ...filters,
                                                                hair_type: selected
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                hair_type: selected
                                                            });
                                                        }}
                                                        value={hairType}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_EYES_COLOR} />:</label>
                                                    <CustomSelect
                                                        name="hair_type"
                                                        options={options.personOptions?.getEyesColors()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setEyesColor(selected);
                                                            setFilters({
                                                                ...filters,
                                                                eyes_color: selected
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                eyes_color: selected
                                                            });
                                                        }}
                                                        value={eyesColor}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    <label className="form-label mt-2"><FormattedMessage {...LANG_PERSON_HEIGHT} />:</label>
                                                    <CustomSelect
                                                        name="hair_type"
                                                        options={PersonModel().getHeightTypes()}
                                                        onChange={(options: Array<OptionType>) => {
                                                            const selected = options.map((item) => {
                                                                return item.value;
                                                            }).join(",");
                                                            setHeight(selected);
                                                            setFilters({
                                                                ...filters,
                                                                height: selected
                                                            });
                                                            loadPairing(bloodType, rhFactor, race, {
                                                                ...filters,
                                                                height: selected
                                                            });
                                                        }}
                                                        value={height}
                                                        placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                        isMulti={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />

                                                    {pairingResult !== undefined && pairingResult.reference.type === 'R' && (
                                                        <div key={donor}>
                                                            <hr />
                                                            <label className="form-label"><TranslateTerms term="donor" />:</label>
                                                            <CustomSelect
                                                                key={donor}
                                                                name="donor"
                                                                options={pairingResult?.candidates.map((item) => {
                                                                    return { label: item.name, value: item.donor };
                                                                })}
                                                                onChange={(option: OptionType) => {
                                                                    setDonor(option.value);
                                                                    setRodList("");
                                                                }}
                                                                value={donor}
                                                                placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                                isMulti={false}
                                                                isSearchable={false}
                                                                isClearable={false}
                                                            />
                                                            <RodsSelectList donor={donor} key={donor} onSelect={(rodList) => {
                                                                setRodList(rodList);
                                                            }} />
                                                        </div>
                                                    )}
                                                    {pairingResult !== undefined && pairingResult.reference.type === 'D' && (
                                                        <div key={donor}>
                                                            <hr />
                                                            <label className="form-label"><TranslateTerms term="donor" />:</label>
                                                            <CustomSelect
                                                                key={donor}
                                                                name="donor"
                                                                options={[
                                                                    { label: pairingResult.reference.name, value: pairingResult.reference.id }
                                                                ]}
                                                                onChange={(option: OptionType) => {
                                                                    setDonor(option.value);
                                                                    setRodList("");
                                                                }}
                                                                value={donor}
                                                                placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                                isMulti={false}
                                                                isSearchable={false}
                                                                isClearable={false}
                                                            />
                                                            <RodsSelectList donor={donor} key={donor} onSelect={(rodList) => {
                                                                setRodList(rodList);
                                                            }} />
                                                        </div>
                                                    )}
                                                    {pairingResult !== undefined && pairingResult.reference.type === 'D' && (
                                                        <div key={receiver}>
                                                            <hr />
                                                            <label className="form-label"><TranslateTerms term="receptor" />:</label>
                                                            <CustomSelect
                                                                key={receiver}
                                                                name="receiver"
                                                                options={pairingResult?.candidates.map((item) => {
                                                                    return { label: item.name, value: item.receiver };
                                                                })}
                                                                onChange={(option: OptionType) => {
                                                                    setReceiver(option.value);
                                                                }}
                                                                value={receiver}
                                                                placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}...`}
                                                                isMulti={false}
                                                                isSearchable={false}
                                                                isClearable={false}
                                                            />
                                                        </div>
                                                    )}

                                                    <br />
                                                    <form action="" onSubmit={(e) => {
                                                        e.preventDefault();
                                                        savePairing();
                                                    }}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <Button variant="outline-light" size={'lg'} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Button
                                                                    type="submit"
                                                                    disabled={donor === undefined || rodList === "" || receiver === undefined}
                                                                    variant="primary"
                                                                    className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100"
                                                                >{intl.formatMessage(LANG_COMMON_SAVE)}</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Col>
                                            <Col lg={9}>
                                                <Row>
                                                    {loadingPairing && (
                                                        <Col lg={12}>
                                                            <Loader fullscreen={true} />
                                                        </Col>
                                                    )}
                                                    {!loadingPairing && pairingResult?.candidates.map((item) => (
                                                        <Col lg={4} className={'mb-4'} key={item.donor}>
                                                            <PairedPersonCard
                                                                stared={
                                                                    (pairingResult.reference.type === "R") ?
                                                                        highestFaceSimilarityPerson?.donor === item.donor :
                                                                        highestFaceSimilarityPerson?.receiver === item.receiver
                                                                }
                                                                type={pairingResult.reference.type}
                                                                paired={item}
                                                                showChildPhoto={showChildView}
                                                                onSelectReport={(selected) => {
                                                                    if (!selected) {
                                                                        setSelectedPersonIds(selectedPersonIds.filter((id) => id !== item.id));
                                                                    } else {
                                                                        setSelectedPersonIds([...selectedPersonIds, item.id]);
                                                                    }
                                                                }}
                                                                onSelect={(paired) => {
                                                                    if (pairingResult.reference.type === "R") {
                                                                        setDonor(paired.donor);
                                                                        setRodList("");
                                                                    } else {
                                                                        setReceiver(paired.receiver);
                                                                    }
                                                                }} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default PairingAddPage;
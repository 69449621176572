import React, {FC, useEffect, useState} from "react";
import {OptionType} from "../../types/CommonType";
import {BusinessModel} from "../../models/BusinessModel";
import {BusinessType} from "../../types/BusinessType";
import Async from "react-select/async";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CHOOSE } from "../../lang";

interface Props {
    onChange: (business_id: number | undefined) => void
    isValid?: boolean
    isTouched?: boolean
    businessId?: string | number
}
const BusinessSelect: FC<Props> = ({onChange, isTouched=undefined, isValid=undefined, businessId=undefined}) => {
    const [defaultValue, setDefaultValue] = useState<OptionType | null>(null);

    const loadBusiness = async (inputValue: string) => {
        return new Promise<Array<BusinessType>>((resolve) => {
            BusinessModel().list(inputValue).then((businessList) => {
                if(typeof businessList !== "boolean") {
                    resolve(businessList);
                    updateDefaultValue(businessList);
                } else {
                    resolve([]);
                }
            });
        });
    }

    const updateDefaultValue = (businessList: Array<BusinessType>) => {
        let item: BusinessType;
        let i: any;
        for(i in businessList) {
            item = businessList[i];
            if(item.id.toString() === businessId?.toString()) {
                setDefaultValue({label: item.name, value: item.id});
            }
        }
    }

    return (
        <Async
            value={defaultValue}
            className={clsx("react-select-container", {
                "is-invalid": isTouched && !isValid,
                "is-valid": isTouched && isValid
            })}
            classNamePrefix={'react-select'}
            placeholder={`${<FormattedMessage {...LANG_COMMON_CHOOSE}/>}...`}
            isSearchable={true}
            defaultOptions={true}
            isClearable={true}
            onChange={(selectedOption: OptionType | null) => {
                setDefaultValue(selectedOption);
                onChange((selectedOption !== null) ? selectedOption.value : undefined);
            }}
            loadOptions={(inputValue, callback) => {
                loadBusiness(inputValue).then((businessList) => {
                    callback(businessList.map((item: BusinessType) => {
                        return {label: item.name, value: item.id};
                    }));
                });
            }}
        />
    );
}

export default BusinessSelect;
import React, {FC, useEffect, useState} from "react";
import {useAPI} from "../../hooks/useAPI";
import {PlanType} from "../../types/PlanType";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import Loader from "../messages/Loader";
import LoaderInside from "../messages/LoaderInside";
import {Button, Modal} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { FormattedMessage } from "react-intl";

interface Props {
    onChoose: (plan: PlanType, paymentOptionId: string) => void
}
const SubscriptionPlans: FC<Props> = ({ onChoose}) => {
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [plans, setPlans] = useState<undefined | Array<PlanType>>(undefined);
    const {api} = useAPI();

    const loadPlans = () => {
        setLoading(true);
        setHasError(false);
        api().get("/api/subscription/active").then((resp) => {
            if(resp.data.plan !== undefined && resp.data.plan?.id !== 99999) {
                onChoose(resp.data.plan, resp.data.payment_option_id);
            } else {
                api().get("/api/plan")
                    .then((resp) => {
                        setLoading(false);
                        setPlans(resp.data);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setHasError(true);
                    });
            }
        }).catch((error) => {
            setLoading(false);
            setHasError(true);
        })
    };

    useEffect(loadPlans, []);

    return (
        <>
            <Modal.Body>
                {!loading && !hasError && (
                    <div>
                        <div className="mx-3">
                            <h5 className="my-3"><FormattedMessage id="subscription.choose_plan" defaultMessage="ESCOLHA SEU PLANO" /></h5>
                        </div>
                        <div className="row">
                            {plans?.filter((value) => {
                                return value.show_publicly;
                            }).map((plan) => (
                                <div className="col-md-3">
                                    <SubscriptionPlanCard onChoose={onChoose} plan={plan} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {loading && (
                    <div style={{"height":"300px", "width": "100%"}}>
                        <LoaderInside />
                    </div>
                )}
            </Modal.Body>
        </>
    );
}

export default SubscriptionPlans;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LogRocket from 'logrocket';
import { getLocale, loadLocaleData } from './util';

if(process.env.NODE_ENV !== 'development') {
  LogRocket.init('isis/isis');
}

async function bootstrap(locale: string | undefined) {
  const messages = await loadLocaleData(locale);

  ReactDOM.render(
    <React.StrictMode>
      <App locale={locale} messages={messages} />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

bootstrap(getLocale());
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row, Table, Breadcrumb, Form} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";
import * as yup from "yup";
import {useFormik} from "formik";
import {BusinessType} from "../../types/BusinessType";
import {BusinessModel} from "../../models/BusinessModel";
import clsx from "clsx";
import InputMask from "react-input-mask";
import AddressForm from "../../components/form/AddressForm";

const BusinessEditPage = () => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [business, setBusiness] = useState<BusinessType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const {id} = useParams();
    const loadBusiness = () => {
        setLoading(true);
        if(id !== undefined) {
            BusinessModel().get(id).then((resp) => {
                if(typeof resp !== "boolean") {
                    setBusiness(resp);
                    formik.setValues(resp);
                }
                setLoading(false);
            });
        }

    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        entity_name: yup.string().required(),
        entity_doc: yup.string().required(),
        type: yup.string(),
        id: yup.number().nullable(),
        status: yup.string().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            entity_name: "",
            entity_doc: "",
            type: "0",
            id: -1,
            status: "A"
        },
        validationSchema: schema,
        onSubmit: (values) => {},
        validateOnMount: false
    });

    useEffect(() => {
        loadBusiness()
    }, []);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {business !== undefined && (
                <div className="bg-pattern">
                        <Container style={{'paddingBottom': '200px'}}>
                            <Row>
                                <Breadcrumb>
                                    <Breadcrumb.Item><Link to={'/admin/businesses'}>Clínicas</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>{business.name}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                            <Row>
                                <Col lg={{offset: 0}}>
                                    <Card>
                                        <CardHeader className={'d-flex'}>
                                            <Card.Title>Dados da Clínica</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className="person-name">{business.name}</h5>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Razão Social</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="entity_name"
                                                                placeholder="Razão Social"
                                                                value={formik.values.entity_name}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.entity_name && !formik.errors.entity_name}
                                                                isInvalid={!!formik.errors.entity_name && hasSubmitted}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Nome Fantasia</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="name"
                                                                placeholder="Nome"
                                                                value={formik.values.name}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.name && !formik.errors.name}
                                                                isInvalid={!!formik.errors.name && hasSubmitted}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>CNPJ</Form.Label>
                                                            <InputMask
                                                                name={"entity_doc"}
                                                                type="text"
                                                                mask="99.999.999/9999-99"
                                                                value={formik.values.entity_doc}
                                                                onChange={(e) => {
                                                                    if(e.target.value !== null) {
                                                                        formik.setFieldValue("entity_doc", e.target.value);
                                                                    }
                                                                }}
                                                                className={clsx("form-control", {
                                                                    "is-invalid": formik.touched.entity_doc && formik.errors.entity_doc,
                                                                    "is-valid": !formik.errors.entity_doc && hasSubmitted
                                                                })}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Label>Tipo</Form.Label>
                                                        <select
                                                            name="type"
                                                            value={formik.values.type}
                                                            onChange={formik.handleChange}
                                                            className={clsx(
                                                                'form-select form-select-solid form-select-lg form-control',
                                                                {'is-invalid': formik.touched.type && formik.errors.type},
                                                                {
                                                                    'is-valid': formik.touched.type && !formik.errors.type,
                                                                }
                                                            )}
                                                            >
                                                            <option value="O">Banco de Óvulos</option>
                                                            <option value="S">Banco de Sêmen</option>
                                                            <option value="A">Banco de Óvulos e Sêmen</option>
                                                        </select>
                                                    </Col>
                                                </Row>
                                        </Card.Body>
                                    </Card>

                                    <Row className={"my-4"}>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="card-title h4">Endereço</div>
                                            </div>
                                            <div className="card-body">
                                                <AddressForm
                                                    address={business.address}
                                                    hasSubmitted={hasSubmitted}
                                                    onSubmit={(data) => {
                                                        formik.validateForm(formik.values).then((errors) => {
                                                            if(Object.keys(errors).length === 0) {
                                                                setSaving(true);
                                                                if(business !== undefined) {
                                                                    BusinessModel().update(business?.id, {...formik.values, address: data}).then((resp) => {
                                                                        if(typeof resp !== "boolean") {
                                                                            toast.success("Registro atualizado com sucesso!");
                                                                            navigate("/admin/businesses");
                                                                        } else {
                                                                            toast.error("Houve um erro, tente novamente!");
                                                                            setHasSubmitted(false);
                                                                            setSaving(false);
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    onValidate={(data) => {}} />
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col lg={{span: 3}}>
                                    <Card>
                                        <CardHeader>
                                            <Card.Title>OPÇÕES</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Link to="/admin/businesses">
                                                        <Button variant="outline-light" size={'lg'} style={{"width": "100%"}} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">Descartar</Button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button  className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100" variant="primary" size={'lg'} onClick={() => {
                                                        setHasSubmitted(true);
                                                        setTimeout(() => {
                                                            setHasSubmitted(false);
                                                        }, 500);
                                                    }} disabled={saving}>{saving ? <ClipLoader color={'#ffffff'} loading={saving} size={'12px'} /> : 'Salvar'}</Button>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                </div>
            )}
        </>
    );
}

export default BusinessEditPage;
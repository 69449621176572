import React, {FC, useEffect, useState} from "react";
import Header from "./layout/Header";
import {Link, LinkProps, Navigate, useLocation, useMatch, useNavigate, useResolvedPath} from "react-router-dom";
import clsx from "clsx";
import {useAuth} from "../hooks/useAuth";
import {useConfig} from "../hooks/useConfig";
import TermsModal from "./TermsModal";
import Error from "./messages/Error";
import SubscriptionModal from "./Subscription/SubscriptionModal";
import PrivateRoutes from "../routes/PrivateRoutes";
import ClipLoader from "react-spinners/ClipLoader";
import PublicRoutes from "../routes/PublicRoutes";
import {AuthContext} from "../providers/AuthContextProvider";
import TranslateTerms from "./TranslateTerms";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CLINIC, LANG_COMMON_CLINICS, LANG_COMMON_DASHBOARD, LANG_COMMON_INSTRUCTIONS, LANG_COMMON_PAIRINGS, LANG_COMMON_SUBSCRIPTION, LANG_COMMON_USERS, LANG_COMMON_USE_TERMS } from "../lang";

const LayoutInit = () => {
    useEffect(() => {}, [
        
    ]);
}

function CustomLink({ children, to, ...props }: LinkProps) {
    let location = useLocation();
    let match = (location.pathname.indexOf(to.toString()) > -1)

    return (
        <Link
            className={clsx("nav-link", {
                'active': match
            })}
            to={to}
            {...props}
        >
            {children}
        </Link>
    );
}

const Layout: FC = ({children}) => {
    const {deleteToken, hasAbility} = useAuth();
    const [showTerms, setShowTerms] = useState(false);

    return (
        <>
            <AuthContext.Consumer>
                {({termsAgreed, setTermsAgreed, user, setIsAuth}) => (
                    <>
                        {user !== undefined && (
                            <TermsModal
                                user={user}
                                show={showTerms}
                                readOnly={true}
                                onConfirm={() => {
                                    setTermsAgreed(true);
                                    setShowTerms(false);
                                }}
                                onHide={() => {
                                    setShowTerms(false);
                                }}
                            />
                        )}
                        <Header />
                        <div className={'d-grid canvas'} style={{'gridTemplateColumns': '183px 1fr', 'height': '100%'}}>
                            <div className="aside">
                                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    <li><CustomLink to={'/admin/dashboard'}>
                                        <div className="icon-aside icon-svg">
                                            <i className="bi bi-house-fill"></i>
                                        </div><FormattedMessage {...LANG_COMMON_DASHBOARD}/></CustomLink></li>
                                    {hasAbility("SUBSCRIPTION_LIST") && (<li><CustomLink to={'/admin/subscriptions'}><div className="icon-aside">
                                        <i className="bi bi-credit-card"></i>
                                    </div><FormattedMessage {...LANG_COMMON_SUBSCRIPTION}/>s</CustomLink></li>)}
                                    <li><CustomLink to={'/admin/donors'}><div className="icon-aside">
                                        <i className="bi bi-person-hearts"></i>
                                    </div><TranslateTerms term="donors"/></CustomLink></li>
                                    <li><CustomLink to={'/admin/receivers'}><div className="icon-aside">
                                        <i className="bi bi-people-fill"></i>
                                    </div><TranslateTerms term="receptors"/></CustomLink></li>
                                    <li><CustomLink to={'/admin/pairing'}>
                                        <div className="icon-aside icon-svg icon-pairing">
                                            <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg"
                                                x="0px" y="0px"
                                                viewBox="0 0 20.19 19.64">
                                                <g>
                                                    <path className="st0" d="M17.13,5.35L15.08,7.4l-0.03,0.04c-0.03,0.03-0.04,0.04-0.06,0.08c-0.34,0.48-0.05,1.29,0.58,1.4
                                                            c0.28,0.05,0.57-0.05,0.78-0.25l3.58-3.58c0.17-0.18,0.27-0.43,0.26-0.68c-0.02-0.22-0.11-0.42-0.26-0.58l-3.58-3.58
                                                            c-0.03-0.03-0.04-0.04-0.07-0.07c-0.39-0.31-1.04-0.21-1.32,0.22c-0.21,0.34-0.16,0.81,0.13,1.11l2.05,2.05H0.89
                                                            C0.55,3.6,0.23,3.76,0.08,4.07c-0.18,0.36-0.07,0.84,0.25,1.09c0.16,0.13,0.36,0.17,0.56,0.2L17.13,5.35L17.13,5.35z M3.05,16.09
                                                            h16.24c0.33-0.04,0.66-0.2,0.81-0.51c0.08-0.16,0.07-0.21,0.09-0.39c-0.01-0.07-0.02-0.13-0.02-0.2c-0.04-0.13-0.09-0.25-0.17-0.36
                                                            c-0.13-0.16-0.31-0.27-0.5-0.31c-0.06-0.02-0.13-0.02-0.2-0.02H3.05l2.05-2.05c0,0,0.27-0.35,0.26-0.68c0-0.04,0-0.06-0.01-0.1
                                                            c-0.08-0.49-0.64-0.85-1.13-0.71c-0.1,0.03-0.19,0.07-0.27,0.13c-0.04,0.03-0.08,0.06-0.12,0.1l-3.58,3.58
                                                            c-0.03,0.03-0.04,0.04-0.07,0.08c-0.25,0.31-0.25,0.8,0,1.12c0.03,0.03,0.04,0.04,0.07,0.08l3.58,3.58
                                                            c0.03,0.03,0.04,0.04,0.08,0.07c0.47,0.38,1.33,0.12,1.44-0.55c0.05-0.28-0.05-0.57-0.25-0.78L3.05,16.09L3.05,16.09z"/>
                                                </g>
                                            </svg>
                                        </div><FormattedMessage {...LANG_COMMON_PAIRINGS}/></CustomLink></li>
                                    {hasAbility("BUSINESS_LIST") && (<li><CustomLink to={'/admin/businesses'}><div className="icon-aside">
                                        <i className="bi bi-bank2"></i>
                                    </div><FormattedMessage {...LANG_COMMON_CLINICS}/></CustomLink></li>)}
                                    <li><CustomLink to={'/admin/users'}><div className="icon-aside">
                                        <i className="bi bi-person-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_USERS}/></CustomLink></li>
                                    {hasAbility("SUBSCRIPTION_VIEW") && (<li><CustomLink to={'/admin/subscription'}><div className="icon-aside">
                                        <i className="bi bi-credit-card"></i>
                                    </div><FormattedMessage {...LANG_COMMON_SUBSCRIPTION}/></CustomLink></li>)}
                                    <li><CustomLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTerms(true);
                                        }}
                                        to={"/admin/terms"}><div className="icon-aside">
                                        <i className="bi bi-file-text-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_USE_TERMS}/></CustomLink></li>
                                    <li><CustomLink target={"_blank"} to={`/assets/orientacoes.pdf`}><div className="icon-aside">
                                        <i className="bi bi-info-circle-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_INSTRUCTIONS}/></CustomLink></li>

                                </ul>
                            </div>
                            {children}
                        </div>
                    </>
                )}
            </AuthContext.Consumer>
        </>
    );
}
export default Layout
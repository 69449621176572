import React, {FC, useState} from 'react';
import {BrowserRouter, Navigate, useLocation, useNavigate} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import Layout from "../components/Layout";
import PrivateRoutes from "./PrivateRoutes";
import {AuthContext} from "../providers/AuthContextProvider";
import ClipLoader from "react-spinners/ClipLoader";
import TermsModal from "../components/TermsModal";
import {useAuth} from "../hooks/useAuth";
import Error from "../components/messages/Error";
import SubscriptionModal from "../components/Subscription/SubscriptionModal";
import { useIntl } from 'react-intl';
import { LANG_SUBSCRIPTION_EXPIRED } from '../lang';

const AuthWrapper = () => {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const {deleteToken} = useAuth();
    const [showSubscriptionError, setShowSubscriptionError] = useState(true);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const logout = (setIsAuth: any) => {
        deleteToken();
        setIsAuth(false);
        navigate("/auth/login");
    };

    return (
        <AuthContext.Consumer>
            {({isAuth, loading, termsAgreed, setTermsAgreed, user, setIsAuth}) => (
                <>
                    {isAuth ?
                        <>
                            {!loading && (
                                <Layout>
                                    {!termsAgreed && user !== undefined && (
                                        <TermsModal
                                            user={user}
                                            show={!termsAgreed}
                                            onConfirm={() => setTermsAgreed(true)}
                                            onHide={() => {
                                                logout(setIsAuth);
                                            }}
                                        />)}
                                    {termsAgreed && user?.has_active_subscription === false && (
                                        <>
                                            <Error
                                                show={showSubscriptionError}
                                                onHide={() => {
                                                    setShowSubscriptionError(false);
                                                    setShowSubscriptionModal(true);
                                                }}
                                                message={intl.formatMessage(LANG_SUBSCRIPTION_EXPIRED)} />
                                            {user.business_id !== undefined && (
                                                <SubscriptionModal
                                                    businessId={user?.business_id}
                                                    show={showSubscriptionModal}
                                                    onConfirm={() => window.location.reload()}
                                                    onHide={() => {
                                                        logout(setIsAuth);
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                    <PrivateRoutes />
                                </Layout>
                            )}
                            {loading && (
                                <>
                                    <div style={{
                                        "fontSize": "1rem",
                                        "position": "fixed",
                                        "top": "45%",
                                        "width": "100%"
                                    }} className={"text-center"}>
                                        <ClipLoader size={"1rem"} /> Carregando...
                                    </div>
                                </>
                            )}
                        </>
                        :
                        <>
                            {location.pathname !== '/auth/login' && (<Navigate to={'/auth/login'} />)}
                        </>
                    }

                    {!isAuth ?
                        <PublicRoutes /> : null
                    }
                </>
            )}
        </AuthContext.Consumer>
    );
}

interface Props {
    basepath: string
}
const RoutesWrapper: FC<Props> = ({basepath}) => {
    return (
        <BrowserRouter basename={basepath}>
            <AuthWrapper />
        </BrowserRouter>
    )
}

export default RoutesWrapper;